import * as moment from 'moment';

import { Reading } from '@rem/reading';

import { API_ROOT_URL } from './env';
import { Salutation } from './customer';
import { Http } from './http';
import { IamService } from './iam';

// See: https://git.eon-cds.de/regional-energy-market/rem-middleware-java/blob/develop/src/main/java/com/eon/dist/rem/csc/model/enums/ContractStatusEnum.java
export type ContractStatus = "IN_DELIVERY" | "NEW" | "WAITING" | "TERMINATED" | "EXPIRED" | "CANCELLATION_PREVIOUS";

// See: https://git.eon-cds.de/regional-energy-market/rem-middleware-java/blob/develop/src/main/java/com/eon/dist/rem/csc/model/enums/PeriodUnitEnum.java
export type ContractCancellationPeriodUnit = "DAYS" | "WEEKS" | "MONTHS" | "YEARS";

// See: https://git.eon-cds.de/regional-energy-market/rem-middleware-java/blob/develop/src/main/java/com/eon/dist/rem/csc/model/enums/PaymentMethodEnum.java
export type PaymentMethod = "DIRECT_DEBIT" | "BANK_TRANSFER";

export type ContractHeader = {
	id: string;
	description: string;
	status: ContractStatus;
};

export type ContractBillingAddress = {
	city: string;
	contact: {
		email: string;
		firstname: string;
		lastname: string;
		phone: string;
		salutation: Salutation;
		companyName: string;
	};
	houseNumber: string;
	street: string;
	zipcode: string;
	sepaAccepted?: boolean;
};

export type ContractPayment = {
	bankAccount: {
		bankname?: string;
		bic?: string;
		iban: string;
		owner: string;
	};
	paymentMethod: PaymentMethod;
};

export type Contract = {
	id: string;
	billingAddress: ContractBillingAddress;
	cancellationPeriod: number;
	cancellationPeriodUnit: ContractCancellationPeriodUnit;
	deposit: {
		grossAmountPerMonth: number;
	};
	tariff: {
		activeFrom: moment.Moment;
		activeUntil: moment.Moment;
		basePrice: number;
		product: string;
		productType: string;
		workingPrice: number;
	};
	customerNumber: string;
	deliveryAddress: {
		city: string;
		contact: {
			email: string;
			firstname: string;
			lastname: string;
			phone: string;
			salutation: string;
			title: string;
		};
		houseNumber: string;
		street: string;
		zipcode: string;
	};
	description: string;
	device: {
		deviceNumber: string;
		registers: {
			description: string;
			obis: string;
		}[];
		tariffCount: string;
		type: string;
	};
	invoices: {
		endDate: moment.Moment;
		id: string;
		invoiceNumber: string;
		isPaid: boolean;
		startDate: moment.Moment;
		value: number;
	}[];
	payment: ContractPayment;
	readings: Reading[];
	startDate: string;
	status: ContractStatus;
	usage: number;
};

export class ContractApi {
	static async getAll(): Promise<ContractHeader[]> {
		let res = await fetch(`${API_ROOT_URL}/api/csc/contracts`, {
			headers: {
				Accept: "application/vnd.api+json",
				Authorization: `Bearer ${IamService.getToken()}`
			}
		});
		if (res.status === Http.UNAUTHORIZED) {
			IamService.logout();
			return [];
		}
		if (res.status !== Http.OKAY) {
			// TODO(Mark): Provide more specific error handling
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}
		let resBody: ResBodyGetAll = await res.json();
		return resBody.data.map(contractHeader => ({
			id: contractHeader.id,
			status: contractHeader.attributes.status,
			description: contractHeader.attributes.description
		}));
	}

	static async get(id: string): Promise<Contract> {
		let res = await fetch(`${API_ROOT_URL}/api/csc/contracts/${id}`, {
			headers: {
				Accept: "application/vnd.api+json",
				Authorization: `Bearer ${IamService.getToken()}`
			}
		});
		if (res.status === Http.UNAUTHORIZED) {
			IamService.logout();
			return {} as Contract;
		}
		if (res.status !== Http.OKAY) {
			// TODO(Mark): Provide more specific error handling
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}
		let resBody: ResBodyGet = await res.json();
		const result = {
			id: id,
			...resBody.data.attributes
		};
		result.tariff.activeFrom = moment(result.tariff.activeFrom);
		result.invoices = result.invoices.map(invoice => {
			invoice.endDate = moment(invoice.endDate);
			invoice.startDate = moment(invoice.startDate);
			return invoice;
		});

		// TODO: Remove moment, fix type casts
		// sorting will be done in MW
		console.log("readings: ", result.readings);
		result.readings = result.readings.map(reading => {
			reading.readAt = (moment(reading.readAt) as any) as string;
			return reading;
		});

		return result;
	}

	static async patchBillingAddress(id: string, data: Partial<ContractBillingAddress>): Promise<Contract> {
		return this.patch(id, data, "billingaddress");
	}

	static async patchPayment(
		id: string,
		data: Partial<ContractPayment>,
		sepaAccepted: boolean = false
	): Promise<Contract> {
		return this.patch(id, data, "payment", { sepaAccepted });
	}

	private static async patch(
		id: string,
		data: Partial<ContractPayment | ContractBillingAddress>,
		path: string,
		args?: any
	): Promise<Contract> {
		let url = `${API_ROOT_URL}/api/csc/contracts/${id}/${path}`;

		const attributes =
			path === "payment"
				? {
						payment: data
				  }
				: {
						billingAddress: data
				  };

		if (path === "payment") {
			url = `${url}?sepaAccepted=${args.sepaAccepted}`;
		}

		let reqBody: ReqBody = {
			data: {
				id: id,
				type: "contract",
				attributes: attributes
			}
		};

		let res = await fetch(url, {
			method: "PATCH",
			headers: {
				Accept: "application/vnd.api+json",
				Authorization: `Bearer ${IamService.getToken()}`,
				"Content-Type": "application/vnd.api+json"
			},
			body: JSON.stringify(reqBody)
		});
		let resBody: ResBodyGet = await res.json();

		if (res.status === Http.UNAUTHORIZED) {
			IamService.logout();
			return {} as Contract;
		}
		if (res.status !== Http.OKAY) {
			// TODO(Mark): Provide more specific error handling
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}
		// TODO(Mark): The response needs to be put back into the fields in the UI
		// so customers can see whether stuff was actually saved without having to
		// refresh the UI

		return {
			id: resBody.data.id,
			...resBody.data.attributes
		};
	}

	static async getInvoice(contractId: string, invoiceId: string): Promise<Blob> {
		let res = await fetch(`${API_ROOT_URL}/api/csc/invoices/${invoiceId}?contractId=${contractId}`, {
			headers: {
				Authorization: `Bearer ${IamService.getToken()}`
			}
		});
		if (res.status === Http.UNAUTHORIZED) {
			IamService.logout();
			return;
		}
		if (res.status !== Http.OKAY) {
			// TODO(Mark): Provide more specific error handling
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}

		//const blob = new Blob([res.blob()], { type: 'text/csv' });
		return res.blob();
	}
}

type ResBodyGetAll = {
	data: {
		id: string;
		type: "contractheader";
		attributes: {
			status: ContractStatus;
			description: string;
		};
	}[];
};

type ResBodyGet = {
	data: {
		id: string;
		type: "contract";
		attributes: Contract;
	};
};

type ReqBody = {
	data: {
		id: string;
		type: "contract";
		attributes: any;
	};
};
