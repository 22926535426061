import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: "tpl-ova",
    templateUrl: "./ova-template.component.html",
    styleUrls: ["./ova-template.component.scss"],
})

export class OvaTemplateComponent implements OnInit, OnDestroy {

    @Input() public globalData: any; // Keep as any for --AOT
    @Input() public editorialData: any; // Keep as any for --AOT
    @Input() public whitelabel;
    public showTemplate = false;
    public fragment: string;
    private routeFragment_: Subscription;

    constructor(
        private route: ActivatedRoute,
        ) {
    }

    ngOnInit(): void {
        this.showTemplate = true;
        this.routeFragment_ = this.route.fragment.subscribe(fragment => {
            this.fragment = fragment;
        });
    }

    ngOnDestroy(): void {
        this.routeFragment_.unsubscribe();
    }
}
