import {API_ROOT_URL} from "./env";
import {Http} from "@rem/http";
import {ApiErrorFactory} from "@rem/error";

export type Region = {
    id: string,
    zipcodes: string[],
    latitude: string,
    longitude: string
    name: string,
}

export class RegionsApi {
    static async find(zipcode: string): Promise<Region> {
        let res = await fetch(`${API_ROOT_URL}/api/ova/regions?zipcode=${zipcode}`, {
            method: "get",
        });
        let resBody: ResBodyFind = await res.json();

        if (res.status !== Http.OKAY) {
            if (resBody.errors && resBody.errors.length) {
                const error = {code: "UNKNOWN"};
                throw ApiErrorFactory.createError(error);
            }
            throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
        }

        if (resBody.data.length === 0) {
            return null;
        }

        return {
            id: resBody.data[0].id,
            ...resBody.data[0].attributes,
        };
    }

    static async get(region: string): Promise<Region> {
        let res = await fetch(`${API_ROOT_URL}/api/ova/regions/${region}`, {
            method: "get",
        });
        let resBody: ResBodyGet = await res.json();

        if (res.status !== Http.OKAY) {
            if (resBody.errors && resBody.errors.length) {
                const error = {code: "UNKNOWN"};
                throw ApiErrorFactory.createError(error);
            }
            throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
        }
        return {
            id: resBody.data.id,
            ...resBody.data.attributes,
        };
    }
}

type ResBodyFind = {
    errors?: any[],
    data: {
        id: string;
        type: "region";
        attributes: Region;
    }[];
};

type ResBodyGet = {
    errors?: any[],
    data: {
        id: string;
        type: "region";
        attributes: Region;
    };
};
