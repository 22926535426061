var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RegExpConstants } from "@p2p/constants/constants";
import { CustomerApi } from "@rem/customer";
import { IamApi, IamService } from "@rem/iam";
import { Region } from "@rem/region";
var CustomerDataComponent = /** @class */ (function () {
    function CustomerDataComponent() {
        this.isLoaded = false;
        this.customer = {};
        this.editMode = false;
        this.editModeEmail = false;
        this.formSuccess = false;
        this.formError = false;
        this.emailChangeInProgress = false;
        this.copyToClipboardStatus = "default";
        this.errorSubHeadlineDefault = "Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut";
        this.errorSubHeadline = this.errorSubHeadlineDefault;
        this.salutationList = {
            UNKNOWN: "---",
            MISS: "Frau",
            MISTER: "Herr"
        };
        this.customerDataForm = new FormGroup({
            rem_title: new FormControl({ value: "", disabled: true }),
            phone: new FormControl({ value: "", disabled: true }, [
                Validators.required,
                Validators.pattern(RegExpConstants.PHONE_NUMBER_REGEXP)
            ])
        });
        this.emailForm = new FormGroup({
            email: new FormControl({ value: "", disabled: true }, [
                Validators.required,
                Validators.pattern(RegExpConstants.EMAIL_OVA_REGEXP)
            ])
        });
        this.passwordDataForm = new FormGroup({
            oldPassword: new FormControl({ value: "", disabled: false }, []),
            password: new FormControl({ value: "", disabled: false }, [Validators.pattern(RegExpConstants.PASSWORD_REGEXP)]),
            passwordRepeat: new FormControl({ value: "", disabled: false }, [])
        });
        this.adDataForm = new FormGroup({
            mailAds: new FormControl({ value: "", disabled: false }),
            emailAds: new FormControl({ value: "", disabled: false }),
            phoneAds: new FormControl({ value: "", disabled: false })
        });
    }
    CustomerDataComponent.prototype.resetErrorSubHeadline = function () {
        this.errorSubHeadline = this.errorSubHeadlineDefault;
    };
    CustomerDataComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, CustomerApi.get()];
                    case 1:
                        _a.customer = _b.sent();
                        this.customer.personalRecommendationCode = this.customer.personalRecommendationCode.toUpperCase();
                        this.customer.dateOfBirth = (this.customer.dateOfBirth || "").replace(/(\d+)-(\d+)-(\d+)/g, "$3.$2.$1");
                        this.customerDataForm.setValue({
                            rem_title: this.customer.title || "",
                            phone: this.customer.phone || ""
                        });
                        this.resetEmailForm();
                        this.adDataForm.setValue({
                            mailAds: this.customer.adsPost || false,
                            emailAds: this.customer.adsMail || false,
                            phoneAds: this.customer.adsPhone || false
                        });
                        this.isLoaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerDataComponent.prototype.setEditMode = function (mode) {
        if (mode === void 0) { mode = true; }
        for (var control in this.customerDataForm.controls) {
            if (mode) {
                this.customerDataForm.get(control).enable();
            }
            else {
                this.customerDataForm.get(control).disable();
            }
        }
        this.editMode = mode;
    };
    CustomerDataComponent.prototype.toggleEmailEditMode = function (enable) {
        if (enable === void 0) { enable = true; }
        for (var control in this.emailForm.controls) {
            if (enable) {
                this.emailForm.get(control).enable();
            }
            else {
                this.emailForm.get(control).disable();
            }
        }
        this.editModeEmail = enable;
    };
    // Customer data
    CustomerDataComponent.prototype.editCustomerData = function () {
        this.setEditMode(true);
    };
    // Emailadress
    CustomerDataComponent.prototype.editEmail = function () {
        this.toggleEmailEditMode(true);
    };
    CustomerDataComponent.prototype.saveCustomerData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var controls, res, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        controls = this.customerDataForm;
                        return [4 /*yield*/, CustomerApi.patch(this.customer.id, {
                                phone: controls.get("phone").value,
                                title: controls.get("rem_title").value
                            })];
                    case 1:
                        res = _a.sent();
                        this.formSuccess = true;
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        this.formSuccess = false;
                        this.formError = true;
                        return [3 /*break*/, 4];
                    case 3:
                        this.setEditMode(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CustomerDataComponent.prototype.emailSaveButtonEnabled = function () {
        if (this.emailChangeInProgress) {
            return false;
        }
        if (this.customer.emailPrivate == this.emailForm.controls.email.value) {
            return false;
        }
        if (!this.emailForm.controls.email.valid) {
            return false;
        }
        return true;
    };
    CustomerDataComponent.prototype.saveEmail = function () {
        return __awaiter(this, void 0, void 0, function () {
            var controls, e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.emailChangeInProgress = true;
                        controls = this.emailForm;
                        return [4 /*yield*/, IamApi.changeUserId(controls.get("email").value)];
                    case 1:
                        _a.sent();
                        this.customer.emailPrivate = controls.get("email").value;
                        this.formSuccess = true;
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        error = e_1;
                        this.formSuccess = false;
                        this.formError = true;
                        this.errorSubHeadline = error.message;
                        this.resetEmailForm();
                        return [3 /*break*/, 4];
                    case 3:
                        this.emailChangeInProgress = false;
                        this.toggleEmailEditMode(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CustomerDataComponent.prototype.resetEmailForm = function () {
        this.emailForm.setValue({
            email: this.customer.emailPrivate
        });
    };
    CustomerDataComponent.prototype.cancelCustomerData = function () {
        this.customerDataForm.setValue({
            rem_title: this.customer.title || "",
            phone: this.customer.phone || ""
        });
        this.customerDataForm.disable();
        this.setEditMode(false);
    };
    CustomerDataComponent.prototype.cancelEmail = function () {
        this.emailForm.setValue({
            email: this.customer.emailPrivate || ""
        });
        this.emailForm.disable();
        this.toggleEmailEditMode(false);
    };
    CustomerDataComponent.prototype.validateCustomerData = function () {
        var _this = this;
        var controls = Object.keys(this.customerDataForm.controls).map(function (controlName) {
            return _this.customerDataForm.get(controlName);
        });
        var isAnyDirty = controls.some(function (control) { return control.dirty; });
        var isAnyInvalid = controls.some(function (control) { return control.invalid; });
        return isAnyDirty && !isAnyInvalid;
    };
    // Password
    CustomerDataComponent.prototype.validatePassword = function () {
        var _this = this;
        var controls = Object.keys(this.passwordDataForm.controls).map(function (controlName) {
            return _this.passwordDataForm.get(controlName);
        });
        var isAnyDirty = controls.some(function (control) { return control.dirty; });
        var isAnyInvalid = controls.some(function (control) { return control.invalid; });
        var passwordsAreEqual = this.passwordDataForm.get("password").value === this.passwordDataForm.get("passwordRepeat").value;
        var passwordsHaveLength = this.passwordDataForm.get("password").value.length > 0 &&
            this.passwordDataForm.get("passwordRepeat").value.length > 0 &&
            this.passwordDataForm.get("oldPassword").value.length > 0;
        return isAnyDirty && !isAnyInvalid && passwordsAreEqual && passwordsHaveLength;
    };
    CustomerDataComponent.prototype.savePassword = function () {
        return __awaiter(this, void 0, void 0, function () {
            var controls, token, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        controls = this.passwordDataForm;
                        return [4 /*yield*/, IamApi.changePassword({
                                oldPassword: controls.get("oldPassword").value,
                                newPassword: controls.get("password").value
                            })];
                    case 1:
                        token = (_a.sent()).token;
                        IamService.login(token);
                        this.formSuccess = true;
                        return [3 /*break*/, 4];
                    case 2:
                        error_2 = _a.sent();
                        this.formSuccess = false;
                        this.formError = true;
                        return [3 /*break*/, 4];
                    case 3:
                        this.setEditMode(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Ads
    CustomerDataComponent.prototype.saveAds = function () {
        return __awaiter(this, void 0, void 0, function () {
            var controls, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        controls = this.adDataForm;
                        return [4 /*yield*/, CustomerApi.patch(this.customer.id, {
                                adsPhone: controls.get("phoneAds").value,
                                adsMail: controls.get("emailAds").value,
                                adsPost: controls.get("mailAds").value
                            })];
                    case 1:
                        _a.sent();
                        this.formSuccess = true;
                        return [3 /*break*/, 4];
                    case 2:
                        error_3 = _a.sent();
                        this.formSuccess = false;
                        this.formError = true;
                        return [3 /*break*/, 4];
                    case 3:
                        this.setEditMode(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CustomerDataComponent.prototype.copyPersonalRecommendationCodeToClipboard = function () {
        return __awaiter(this, void 0, void 0, function () {
            var input, err_1, reset;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, 5, 6]);
                        if (!("clipboard" in navigator && "writeText" in navigator.clipboard)) return [3 /*break*/, 2];
                        return [4 /*yield*/, navigator.clipboard.writeText(this.customer.personalRecommendationCode)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        input = document.getElementById("personalRecommendationCode");
                        input.select();
                        input.setSelectionRange(0, 10);
                        document.execCommand("copy");
                        _a.label = 3;
                    case 3:
                        this.copyToClipboardStatus = "success";
                        return [3 /*break*/, 6];
                    case 4:
                        err_1 = _a.sent();
                        this.copyToClipboardStatus = "error";
                        return [3 /*break*/, 6];
                    case 5:
                        reset = function () {
                            _this.copyToClipboardStatus = "default";
                            _this.copyToClipboardResetTimeout = undefined;
                        };
                        if (this.copyToClipboardResetTimeout === undefined) {
                            this.copyToClipboardResetTimeout = setTimeout(reset, 10000);
                        }
                        else {
                            clearTimeout(this.copyToClipboardResetTimeout);
                            this.copyToClipboardResetTimeout = setTimeout(reset, 10000);
                        }
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CustomerDataComponent.prototype.recommendationLink = function () {
        return location.protocol + "//" + location.host + "/" + Region.getCurrent() + "?code=" + this.customer.personalRecommendationCode;
    };
    CustomerDataComponent.prototype.mailRecommendationLink = function () {
        // TODO: Let's update these texts
        var subject = "Strom aus deiner Region";
        var body = "Hallo! Kennst du schon unseren Regionalen Strommarkt? Hier bekommst du \u00D6kostrom aus unserer Region zu fairen Preisen. Ich bin auch bereits dabei. Klick einfach auf den Link und mach mit! " + this.recommendationLink();
        return "mailto:?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);
    };
    CustomerDataComponent.prototype.whatsAppRecommendationLink = function () {
        // TODO: Let's update these texts
        var text = "Hallo! Kennst du schon unseren Regionalen Strommarkt? Hier bekommst du \u00D6kostrom aus unserer Region zu fairen Preisen. Ich bin auch bereits dabei. Klick einfach auf den Link und mach mit! " + this.recommendationLink();
        return "whatsapp://send?text=" + encodeURIComponent(text);
    };
    return CustomerDataComponent;
}());
export { CustomerDataComponent };
