<header>
	<h1>Servus!</h1>
	<p>Schritt für Schritt zu Ihrem regionalen Ökostrom.</p>

	<!-- prettier-ignore -->
	<mol-progress-bar
	[stepLabels]="[
			'Persönliche Angaben',
			'Zahlungsinformationen & Wechsel',
			'Eingaben prüfen'
	]"
	[currentStep]="this.currentStep"
	></mol-progress-bar>
</header>

<div class="group" dir="ltr">
	<form (focusout)="this.onFormFocusOut($event)" (change)="this.onFormChange($event)" class="ova">
		<h2>Ihr Online-Vertragsabschluss<span [hidden]="this.currentStep !== 3"> im Überblick</span></h2>
		<p [hidden]="this.currentStep !== 3">Bitte überprüfen Sie nun noch einmal alle Eingaben.</p>
		<fieldset id="step-1" [hidden]="this.currentStep !== 1">
			<section id="customer-data">
				<h3>Ihre Daten</h3>

				<div class="fieldgroup">
					<div class="formfield required">
						<label for="salutation">Anrede</label>
						<select id="salutation" name="salutation" required data-required-message="Bitte wählen Sie Ihre Anrede aus.">
							<option value="">Bitte auswählen …</option>
							<option value="MISS">Frau</option>
							<option value="MISTER">Herr</option>
							<option value="FAMILY">Familie</option>
						</select>
						<div class="validation-message"></div>
					</div>

					<div class="formfield">
						<label for="title">Titel eingeben oder auswählen …</label>
						<input id="title" name="title" list="titleList" class="datalist" placeholder="Titel (optional) …" />
						<datalist id="titleList">
							<option value="Dr."></option>
							<option value="Prof."></option>
							<option value="Prof. Dr."></option>
							<option value="Dr. Dr."></option>
						</datalist>
					</div>
				</div>

				<div class="fieldgroup">
					<div class="formfield required flyinglabel">
						<input
							type="text"
							id="firstname"
							name="firstname"
							required
							data-required-message="Bitte geben Sie Ihren Vornamen an."
							placeholder="Vorname"
						/>
						<div class="validation-message"></div>
						<label for="firstname">Vorname</label>
					</div>

					<div class="formfield required flyinglabel">
						<input
							type="text"
							id="lastname"
							name="lastname"
							required
							data-required-message="Bitte geben Sie Ihren Nachnamen an."
							placeholder="Nachname"
						/>
						<div class="validation-message"></div>
						<label for="lastname">Nachname</label>
					</div>
				</div>

				<div class="formfield flyinglabel">
					<input type="text"
						id="companyName"
						name="companyName"
						placeholder="Firma"
					/>
					<label for="companyName">Firma</label>
				</div>

				<div class="fieldgroup">
					<div class="formfield required flyinglabel">
						<!--
							The date pattern includes both English and German date notation, e.g. "20.03.1993" or "1993-03-20",
							since Safari does not support date inputs and instead renders a text input. In Safari, users should
							enter the date in German notation. In other browsers, users can select the date via a datepicker.
							These usually save the date in English notation. So the validation patter needs to allow both patterns.
						-->
						<input
							id="dateOfBirth"
							name="dateOfBirth"
							type="date"
							placeholder="TT.MM.JJJJ"
							required
							data-required-message="Bitte geben Sie Ihr Geburtsdatum an."
							pattern="^\d{1,2}\.\d{1,2}\.\d{4}|\d{4}-\d{2}-\d{2}$"
							data-pattern-message='Bitte geben Sie Ihr Geburtsdatum in dem folgenden Format an: "TT.MM.JJJJ" (T = Tag, M = Monat, J = Jahr) Zum Beispiel: "20.03.1985".'
							data-custom-message="Sie müssen älter als 18 Jahre alt sein, um regionalen Ökostrom bestellen zu können."
						/>
						<div class="validation-message"></div>
						<label for="dateOfBirth">Geburtsdatum</label>
					</div>

					<div class="formfield required flyinglabel">
						<!--
							The used email regex ensures that customers enter a proper domain name (e.g. "gmail.com"
							rather than just "gmail") to prevent errors. Note that "gmail" is a valid hostname,
							similar to "localhost", so RFC-like email syntax validation is useless in this case.
						-->
						<input
							id="email"
							name="email"
							type="email"
							placeholder="E-Mail-Adresse"
							required
							data-required-message="Bitte geben Sie Ihre E-Mail-Adresse an."
							pattern="^.+@[^.]+\..*?[^.]{2,}$"
							data-pattern-message="Bitte geben Sie eine gültige E-Mail-Adresse an."
						/>
						<div class="validation-message"></div>
						<label for="email">E-Mail-Adresse</label>
					</div>
				</div>

				<div class="formfield required flyinglabel">
					<input
						id="phone"
						name="phone"
						type="tel"
						placeholder="Telefonnummer mit Vorwahl"
						required
						data-required-message="Bitte geben Sie Ihre Telefonnummer an."
						pattern="^[+0-9-_.\()/\s]{6,}$"
						data-pattern-message="Bitte geben Sie eine gültige Telefonnummer an."
					/>
					<div class="validation-message"></div>
					<label for="phone">Telefonnummer mit Vorwahl</label>
				</div>
			</section>

			<section id="customer-delivery">
				<h3>Wohin soll Ihr regionaler Ökostrom geliefert werden?</h3>

				<div class="fieldgroup">
					<div class="formfield required">
						<label for="zipcode">Postleitzahl</label>
						<select
							id="zipcode"
							name="zipcode"
							required
							data-required-message="Bitte wählen Sie Ihre Postleitzahl aus."
							(change)="this.onZipcodeChange()"
						>
							<option value="">Bitte auswählen …</option>
							<option *ngFor="let zipcode of this.region?.zipcodes" value="{{ zipcode }}">{{ zipcode }}</option>
						</select>
						<div class="validation-message"></div>
					</div>

					<div class="formfield required">
						<select
							id="city"
							name="city"
							disabled
							(change)="this.onCityChange()"
							required
							data-required-message="Bitte wählen Sie Ihren Wohnort aus."
						>
							<option *ngIf="this.cities?.length > 1" value="">Bitte auswählen …</option>
							<option *ngFor="let city of this.cities" value="{{ city?.name }}">{{ city?.name }}</option>
						</select>
						<div class="validation-message"></div>
						<label for="city">Ort</label>
					</div>
				</div>

				<div class="fieldgroup">
					<div id="streetContainer" class="formfield required flyinglabel">
						<select id="street" name="street" disabled required data-required-message="Bitte wählen Sie Ihre Straße aus.">
							<option *ngIf="this.city?.streets.length > 1" value="">Bitte auswählen …</option>
							<option *ngFor="let street of this.city?.streets" value="{{ street }}">{{ street }}</option>
						</select>
						<div class="validation-message"></div>
						<label for="street">Straße</label>
					</div>

					<div id="streetFallbackContainer" class="formfield required flyinglabel">
						<input type="text" id="streetFallback" name="streetFallback" required data-required-message="Bitte geben Sie Ihre Straße an.">
						<div class="validation-message"></div>
						<label for="streetFallback">Straße</label>
					</div>

					<div class="formfield required flyinglabel">
						<input
							type="text"
							id="houseNumber"
							name="houseNumber"
							required
							data-required-message="Bitte geben Sie Ihre Hausnummer an."
						/>
						<div class="validation-message"></div>
						<label for="houseNumber">Hausnummer</label>
					</div>
				</div>
			</section>

			<section id="customer-billing">
				<h3>Soll die Rechnungsadresse von der Lieferadresse abweichen?</h3>
				<div class="formfield check" style="margin-top: 1em">
					<input
						id="alternativeBillingAddress"
						type="checkbox"
						name="alternativeBillingAddress"
						(change)="this.onAlternativeBillingAddressChange()"
					/><label for="alternativeBillingAddress"
						>Ja, ich möchte eine abweichende Rechnungsadresse angeben. Die Adresse soll lauten …</label
					>
				</div>
				<div id="alternativeBillingAddressContainer">
					<div class="formfield required">
						<label for="alternativeSalutation">Anrede</label>
						<select
							id="alternativeSalutation"
							name="alternativeSalutation"
							required
							data-required-message="Bitte wählen Sie eine Anrede aus."
						>
							<option value="">Bitte auswählen …</option>
							<option value="MISS">Frau</option>
							<option value="MISTER">Herr</option>
							<option value="FAMILY">Familie</option>
						</select>
						<div class="validation-message"></div>
					</div>

					<div class="fieldgroup">
						<div class="formfield required flyinglabel">
							<input
								type="text"
								id="alternativeFirstname"
								name="alternativeFirstname"
								placeholder="Vorname"
								required
								data-required-message="Bitte geben Sie den Vornamen des Rechnungsempfängers an."
							/>
							<div class="validation-message"></div>
							<label for="alternativeFirstname">Vorname</label>
						</div>

						<div class="formfield required flyinglabel">
							<input
								type="text"
								id="alternativeLastname"
								name="alternativeLastname"
								placeholder="Nachname"
								required
								data-required-message="Bitte geben Sie den Nachnamen des Rechnungsempfängers an."
							/>
							<div class="validation-message"></div>
							<label for="alternativeLastname">Nachname</label>
						</div>
					</div>

					<div class="formfield flyinglabel">
						<input type="text"
							id="alternativeCompanyName"
							name="alternativeCompanyName"
							placeholder="Firma (optional)"
						/>
						<label for="alternativeCompanyName">Firma (optional)</label>
					</div>

					<div class="fieldgroup">
						<div class="formfield required flyinglabel">
							<!--
									This input cannot become a select box because then customers would not be able
									to input alternative billing addresses from other parts of Germany anymore.
							-->
							<input
								type="text"
								id="alternativeZipcode"
								name="alternativeZipcode"
								list="zipcodeList"
								placeholder="Postleitzahl"
								(input)="this.onAlternativeZipcodeInput()"
								required
								data-required-message="Bitte geben Sie die Postleitzahl des Rechnungsempfängers an."
								pattern="^\d{5}$"
								data-pattern-message="Bitte geben Sie eine gültige Postleitzahl an."
							/>
							<div class="validation-message"></div>
							<datalist id="zipcodeList">
								<option *ngFor="let zipcode of this.region?.zipcodes" value="{{ zipcode }}">{{ zipcode }}</option>
							</datalist>
							<label for="alternativeZipcode">Postleitzahl</label>
						</div>

						<div class="formfield required">
							<select
								id="alternativeCity"
								name="alternativeCity"
								disabled
								(change)="this.onAlternativeCityChange()"
								required
								data-required-message="Bitte wählen Sie den Ort Ihrer Rechnungsadresse aus."
							>
								<option *ngIf="this.alternativeCities?.length > 1" value="">Bitte auswählen …</option>
								<option *ngFor="let city of this.alternativeCities" value="{{ city?.name }}">{{ city?.name }}</option>
							</select>
							<div class="validation-message"></div>
							<label for="city">Ort</label>
						</div>
					</div>

					<div class="fieldgroup">
						<div id="alternativeStreetContainer" class="formfield required flyinglabel">
							<select
								id="alternativeStreet"
								name="alternativeStreet"
								disabled
								required
								data-required-message="Bitte wählen Sie die Straße Ihrer Rechnungsadresse aus."
							>
								<option *ngIf="this.alternativeCity?.streets.length > 1" value="">Bitte auswählen …</option>
								<option *ngFor="let street of this.alternativeCity?.streets" value="{{ street }}">{{ street }}</option>
							</select>
							<div class="validation-message"></div>
							<label for="street">Straße</label>
						</div>

						<div id="alternativeStreetFallbackContainer" class="formfield required flyinglabel">
							<input type="text" id="alternativeStreetFallback" name="alternativeStreetFallback" required data-required-message="Bitte geben Sie die Straße Ihrer Rechnungsadresse an.">
							<div class="validation-message"></div>
							<label for="alternativeStreetFallback">Straße</label>
						</div>

						<div class="formfield required flyinglabel">
							<label for="alternativeHouseNumber">Hausnummer</label>
							<input
								type="text"
								id="alternativeHouseNumber"
								name="alternativeHouseNumber"
								placeholder="Hausnummer"
								required
								data-required-message="Bitte geben Sie die Hausnummer des Rechnungsempfängers an."
							/>
							<div class="validation-message"></div>
						</div>
					</div>
				</div>
			</section>

			<section id="customer-relocation">
				<h3>Wohnen Sie bereits unter der angegebenen Adresse?</h3>
				<div class="formfield required options">
					<input
						class="hiddenradiobutton"
						id="NO_RELOCATION"
						type="radio"
						name="relocation"
						(change)="this.onRelocationChange()"
						checked
					/>
					<label for="NO_RELOCATION" class="button">
						Ich wohne hier schon länger als vier Wochen.
					</label>
					<input
						class="hiddenradiobutton"
						id="RECENTLY_RELOCATED"
						type="radio"
						name="relocation"
						(change)="this.onRelocationChange()"
					/>
					<label for="RECENTLY_RELOCATED" class="button">
						Ich bin innerhalb der letzten vier Wochen hier eingezogen und zwar am …
					</label>
					<input
						class="hiddenradiobutton"
						id="WILL_RELOCATE"
						type="radio"
						name="relocation"
						(change)="this.onRelocationChange()"
					/>
					<label for="WILL_RELOCATE" class="button">
						Ich werde hier noch einziehen, und zwar zum …
					</label>
				</div>

				<div id="relocationDateContainer" class="formfield required flyinglabel">
					<input
						id="relocationDate"
						name="relocationDate"
						type="date"
						placeholder="TT.MM.JJJJ"
						required
						data-required-message="Bitte geben Sie Ihr Umzugsdatum an."
						pattern="^\d{1,2}\.\d{1,2}\.\d{4}|\d{4}-\d{2}-\d{2}$"
						data-pattern-message='Bitte geben Sie Ihr Umzugsdatum in dem folgenden Format an: "TT.MM.JJJJ" (T = Tag, M = Monat, J = Jahr) Zum Beispiel: "20.03.2020".'
						data-custom-message-will-relocate="Bitte stellen Sie sicher, dass Ihr Umzugsdatum in der Zukunft liegt."
						data-custom-message-recently-relocated="Bitte stellen Sie sicher, dass Ihr Einzugsdatum innerhalb der letzten 4 Wochen liegt."
					/>
					<div class="validation-message"></div>
					<label for="relocationDate">Umzugsdatum</label>
				</div>
			</section>

			<section id="tell-a-friend">
				<h3>Wurden Sie von Freunden oder Bekannten geworben?</h3>
				<p><span class="tellafriend_logo"></span>Falls Sie einen Werbecode im Rahmen der Aktion »Freunde werben« erhalten haben, können Sie diesen hier eintragen.</p>
				<div class="formfield flyinglabel">
					<input
						type="text"
						id="recruitFriends"
						name="recruitFriends"
						placeholder="Werbecode"
						data-custom-message="Der eingegebene Werbecode ist nicht gültig."
						oninput="let p=this.selectionStart; this.value=this.value.toUpperCase(); this.setSelectionRange(p, p);"
						[value]="this.params.code || ''"
					>
					<div class="validation-message"></div>
					<label for="recruitFriends">Werbecode</label>
				</div>
			</section>

			<div *ngIf="this.currentStep === 1" class="formactions">
				<a class="button step disabled">Vorheriger Schritt</a>
				<a id="gotostep2" class="button step preferred" [routerLink]="this.pathname" fragment="step-2" (click)="this.goToStep2()" preserveQueryParams
					>Fortfahren</a
				>
			</div>

			<div *ngIf="this.currentStep === 1 && this.validationMessages.length > 0" class="validation-summary">
				<p>
					Bitte überprüfen Sie Ihre Eingaben. Die folgenden Angaben müssen korrigiert werden, um fortfahren zu können:
				</p>
				<ul>
					<li *ngFor="let message of this.validationMessages">• {{ message }}</li>
				</ul>
			</div>
		</fieldset>

		<fieldset id="step-2" [hidden]="this.currentStep !== 2">
			<section id="customer-payment">
				<h3>Ihre Zahlungsinformationen</h3>

				<div class="formfield required">
					<label for="paymentType">Zahlungsart</label>
					<select
						id="paymentType"
						name="paymentType"
						(change)="this.onPaymentChange()"
						required
						data-required-message="Bitte wählen Sie eine Zahlungsart aus."
					>
						<option value="">Bitte auswählen …</option>
						<option value="DIRECT_DEBIT">SEPA-Lastschriftverfahren</option>
						<option value="BANK_TRANSFER">Überweisung</option>
					</select>
					<div class="validation-message"></div>
				</div>

				<div id="directDebitContainer">
					<div class="formfield required flyinglabel">
						<input
							type="text"
							id="accountOwner"
							name="accountOwner"
							placeholder="Kontoinhaber"
							required
							data-required-message="Bitte geben Sie den Namen des Kontoinhabers an."
						/>
						<div class="validation-message"></div>
						<label for="accountOwner">Kontoinhaber</label>
					</div>

					<div class="formfield required flyinglabel">
						<!-- Shortest possible IBAN is 15, see: https://en.wikipedia.org/wiki/International_Bank_Account_Number#IBAN_formats_by_country -->
						<input
							type="text"
							id="iban"
							name="iban"
							required
							placeholder="IBAN"
							data-required-message="Bitte geben Sie Ihre Internationale Bankkontonummer (IBAN) an."
							pattern="^[A-Za-z]{2}[a-zA-Z0-9 ]{13,}$"
							data-pattern-message="Bitte geben Sie eine gültige IBAN an. Diese darf nur aus den Buchstaben A-Z, den Zahlen 0-9 und Leerzeichen bestehen."
							data-custom-message="Die von Ihnen angegebene IBAN ist ungültig. Bitte überprüfen Sie Ihre Eingabe."
						/>
						<div class="validation-message"></div>
						<label for="iban">IBAN</label>
					</div>

					<div class="formfield required check">
						<input
							id="directDebitAuthority"
							name="directDebitAuthority"
							type="checkbox"
							required
							data-required-message="Bitte erteilen Sie der Bayernwerk Regio Energie GmbH eine Einzugsermächtigung, um Zahlungen von Ihrem Konto mittels Lastschrift einziehen zu können."
						/>
						<!-- prettier-ignore -->
						<label for="directDebitAuthority"
							>Einzugsermächtigung: Ich ermächtige die Bayernwerk Regio Energie GmbH (Gläubiger-Id: DE71 ZZZ0 0001 5072 35), Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der Bayernwerk Regio Energie GmbH auf mein Konto gezogenen Lastschriften einzulösen. Die Referenznummer für Ihr SEPA-Mandat teilen wir Ihnen separat mit.</label
						>
						<div class="validation-message"></div>
					</div>
					<p class="infobox">
						Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten
						Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.
					</p>
				</div>

				<div id="bankTransferContainer">
					<p class="infobox">
						Sie haben sich für Überweisungen entschieden. Über Ihre monatlich zu zahlenden Abschlagsbeträge informieren wir
						Sie in Ihrer Vertragsbestätigung.
					</p>
				</div>
			</section>

			<section id="provider-former">
				<div id="cancellationContainer">
					<h3>Haben Sie Ihren bisherigen Stromanbieter bereits gekündigt?</h3>

					<div class="formfield required options">
						<input
							id="CANCELLATION_DESIRED"
							type="radio"
							name="cancellation"
							(change)="this.onCancellationChange()"
							checked
						/>
						<label for="CANCELLATION_DESIRED" class="button">
							Noch nicht. Ich möchte, dass Sie für mich kündigen.
						</label>
						<input id="ALREADY_CANCELLED" type="radio" name="cancellation" (change)="this.onCancellationChange()" />
						<label for="ALREADY_CANCELLED" class="button">
							Ja, ich habe bereits gekündigt und zwar zum …
						</label>
					</div>
					<div id="cancellationDisclaimer" class="infobox">
						<p>Wir kümmern uns gerne um die Kündigung bei Ihrem bisherigen Stromlieferanten.</p>
						<p>In folgenden Fällen können wir die Kündigung für Sie leider nicht übernehmen:</p>
						<ul>
							<li>Sie möchten von Ihrem Sonderkündigungsrecht aufgrund einer Preisanpassung Gebrauch machen.</li>
							<li>Ihre Kündigungsfrist bei Ihrem bisherigen Stromanbieter (Vorlieferant) endet in weniger als 2 Wochen.</li>
						</ul>
					</div>
					<div id="cancellationDateContainer" class="formfield required flyinglabel">
						<input
							id="cancellationDate"
							name="cancellationDate"
							type="date"
							placeholder="TT.MM.JJJJ"
							required
							data-required-message="Bitte geben Sie Ihr Kündigungsdatum an."
							pattern="^\d{1,2}\.\d{1,2}\.\d{4}|\d{4}-\d{2}-\d{2}$"
							data-pattern-message='Bitte geben Sie Ihr Kündigungsdatum in dem folgenden Format an: "TT.MM.JJJJ" (T = Tag, M = Monat, J = Jahr) Zum Beispiel: "20.03.2020".'
						/>
						<div class="validation-message"></div>
						<label for="cancellationDate">Kündigungsdatum</label>
					</div>
				</div>

				<h3>Welcher ist Ihr bisheriger Stromanbieter?</h3>
				<div class="formfield required">
					<label for="provider">Bisheriger Stromanbieter</label>
					<select
						id="provider"
						name="provider"
						required
						data-required-message="Bitte wählen Sie Ihren derzeitigen Stromanbieter aus."
					>
						<option value="">Bisherigen Stromanbieter auswählen …</option>
						<option *ngFor="let provider of this.providers" value="{{ provider?.name }}">{{ provider?.name }}</option>
					</select>
					<div class="validation-message"></div>
				</div>

				<div class="formfield required flyinglabel">
					<!-- TODO: Pattern validation - not sure how device numbers can look like. -->
					<input
						type="text"
						id="deviceNumber"
						name="deviceNumber"
						required
						data-required-message="Bitte geben Sie Ihre Zählernummer an."
						placeholder="Zählernummer"
					/>
					<div class="validation-message"></div>
					<label for="deviceNumber">Zählernummer</label>
				</div>
			</section>

			<section id="deliveryDateSection">
				<div class="formfield required options">
					<h3>Ab wann dürfen wir Sie beliefern?</h3>

					<input id="DELIVERY_ASAP" type="radio" name="delivery" (change)="this.onDeliveryChange()" checked />
					<label for="DELIVERY_ASAP" class="button">
						Ich möchte so früh wie möglich beliefert werden.
					</label>

					<input id="DELIVERY_AT_DATE" type="radio" name="delivery" (change)="this.onDeliveryChange()" />
					<label for="DELIVERY_AT_DATE" class="button">
						Ich möchte am folgenden Termin beliefert werden …
					</label>
				</div>
				<div id="desiredDateContainer" class="formfield required flyinglabel">
					<input
						id="desiredDate"
						name="desiredDate"
						type="date"
						required
						placeholder="TT.MM.JJJJ"
						data-required-message="Bitte geben Sie Ihr gewünschtes Lieferdatum an."
						pattern="^\d{1,2}\.\d{1,2}\.\d{4}|\d{4}-\d{2}-\d{2}$"
						data-pattern-message="Bitte geben Sie Ihr gewünschtes Lieferdatum in dem folgenden Format an: »TT.MM.JJJJ« (T = Tag, M = Monat, J = Jahr) Zum Beispiel: »20.03.2020«."
					/>
					<div class="validation-message"></div>
					<label for="desiredDate">Lieferdatum</label>
				</div>
			</section>

			<section id="referrals">
				<h3>Wie sind Sie auf uns gekommen?</h3>
				<div class="formfield check">
					<ul>
						<li>
							<input id="referralInternet" name="referral" type="checkbox" /><label for="referralInternet">Internet</label>
						</li>
						<li>
							<input id="referralNewspaper" name="referral" type="checkbox" /><label for="referralNewspaper">Zeitung</label>
						</li>
						<li>
							<input id="referralBillboards" name="referral" type="checkbox" /><label for="referralBillboards">Plakate</label>
						</li>
						<li>
							<input id="referralFriends" name="referral" type="checkbox" /><label for="referralFriends"
								>Freunde oder Bekannte</label
							>
						</li>
						<li>
							<input id="referralOthers" name="referral" type="checkbox" /><label for="referralOthers">Sonstiges:</label><br />
							<input
								type="text"
								id="referralOthersText"
								name="referralOthersText"
								[disabled]="!this.inputs.referralOthers.checked"
							/>
						</li>
					</ul>
				</div>
			</section>

			<div *ngIf="this.currentStep === 2" class="formactions">
				<a class="button step" [routerLink]="this.pathname" fragment="step-1" (click)="this.goToStep(1, true)" preserveQueryParams
					>Vorheriger Schritt</a
				>
				<a class="button step preferred" [routerLink]="this.pathname" fragment="step-3" (click)="this.goToStep(3)" preserveQueryParams
					>Fortfahren</a
				>
			</div>

			<div *ngIf="this.currentStep === 2 && this.validationMessages.length > 0" class="validation-summary">
				<p>
					Bitte überprüfen Sie Ihre Eingaben. Die folgenden Angaben müssen korrigiert werden, um fortfahren zu können:
				</p>
				<ul>
					<li *ngFor="let message of this.validationMessages">• {{ message }}</li>
				</ul>
			</div>
		</fieldset>

		<fieldset id="step-3" [hidden]="this.currentStep !== 3">
			<section id="terms">
				<h3>Preis- und Lieferbedingungen</h3>
				<div class="formfield check">
					<input
						id="termsAndConditions"
						type="checkbox"
						name="termsAndConditions"
						required
						data-required-message="Bitte stimmen Sie den Preis- und Lieferbedingungen zu."
					/>
					<!-- prettier-ignore -->
					<label for="termsAndConditions">
						Ich habe die <a [href]="this.preisUndLieferbedingungen()">Preis- und Lieferbedingungen</a> für mein Produkt und die <a href="./assets/editorial/{{ this.currentRegion }}/media/downloads/Allgemeine-Stromlieferbedingungen-AGB.pdf">Allgemeinen Stromlieferbedingungen (AGB)</a> sowie die <a href="./assets/editorial/{{ this.currentRegion }}/media/downloads/Datenschutzhinweise.pdf">Datenschutzhinweise</a> zur Kenntnis genommen und bin mit deren Geltung als Vertragsbestandteil einverstanden. Ich habe auch die <a href="./assets/editorial/{{ this.currentRegion }}/media/downloads/Widerrufsbelehrung.pdf">Widerrufsbelehrung</a> zur Kenntnis genommen. Ich beauftrage die Bayernwerk Regio Energie GmbH mit der Belieferung von Strom an die angegebene Verbrauchsstelle und erteile Bayernwerk Regio Energie GmbH Vollmacht, meinen derzeit bestehenden Stromliefervertrag soweit erforderlich zu kündigen sowie die erforderlichen Verträge mit dem örtlich zuständigen Netzbetreiber abzuschließen.
					</label>
					<div class="validation-message"></div>
				</div>
			</section>

			<section id="customerrelations">
				<h3>Werbeeinwilligung</h3>

				<p>
					Wie dürfen wir Sie – auch über das Vertragsende hinaus – über unsere Produkte informieren? (Mehrfachauswahl
					möglich)
				</p>

				<div class="formfield check">
					<ul>
						<li>
							<input id="adsMail" type="checkbox" name="adsMail" /><label for="adsMail"
								>Ja, ich wünsche Informationen per E-Mail.</label
							>
						</li>
						<li>
							<input id="adsPhone" type="checkbox" name="adsPhone" /><label for="adsPhone"
								>Ja, ich wünsche Informationen telefonisch.</label
							>
						</li>
						<li>
							<input id="adsPost" type="checkbox" name="adsPost" /><label for="adsPost"
								>Ja, ich wünsche Informationen per Post.</label
							>
						</li>
					</ul>
				</div>

				<p class="infobox">
					Wenn ich obige Informationen nicht mehr wünsche, kann ich der Nutzung meiner Daten zu Werbezwecken jederzeit
					widersprechen: Per Post, per E-Mail oder telefonisch.
				</p>
			</section>

			<div *ngIf="this.currentStep === 3" class="formactions">
				<a class="button step" [routerLink]="this.pathname" fragment="step-2" (click)="this.goToStep(2, true)" preserveQueryParams
					>Vorheriger Schritt</a
				>
				<input *ngIf="!this.isWaiting" class="button preferred" type="submit" value="Kostenpflichtig bestellen" (click)="this.onOrderSubmit()" />
				<button *ngIf="this.isWaiting" class="button preferred waiting">Kostenpflichtig bestellen</button>
			</div>

			<div *ngIf="this.currentStep === 3 && this.validationMessages.length > 0" class="validation-summary">
				<p>
					Bitte überprüfen Sie Ihre Eingaben. Die folgenden Angaben müssen korrigiert werden, um fortfahren zu können:
				</p>
				<ul>
					<li *ngFor="let message of this.validationMessages">• {{ message }}</li>
				</ul>
			</div>
		</fieldset>
	</form>
	<aside>
		<mol-contract-view></mol-contract-view>
	</aside>
</div>

<mol-overlay
	*ngIf="this.unhandledError !== null"
	type="error"
	[headline]="this.unhandledError.title"
	[subHeadline]="this.unhandledError.description"
	(onCallToAction)="this.unhandledError = null"
></mol-overlay>
