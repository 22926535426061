import { Subscription } from 'rxjs';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_de_DE from '@amcharts/amcharts4/lang/de_DE';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Component, Input, NgZone, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Consumptions, ConsumptionsApi } from '@rem/consumptions';
import { Contract, ContractApi, ContractHeader } from '@rem/contract';

am4core.useTheme(am4themes_animated);

@Component({
	selector: "org-contract-data-meter-data-history",
	templateUrl: "./contract-data-meter-data-history.component.html",
	styleUrls: ["./contract-data-meter-data-history.component.scss"]
})
export class ContractDataMeterDataHistoryComponent implements OnChanges, OnDestroy {
	@Input() public whitelabel: string;
	@Input() public errorMessages: any;
	@Input() public globalData: any;
	@Input() contractId: string;
	contract: Contract;

	public validationError: { subHeadline: string } = null;

	// If true a spinner will be visible.
	public isLoaded: boolean = false;

	public contracts: ContractHeader[] = [];
	public consumptions: Consumptions;

	public section: string;
	public subsection: string;

	private routeQuery_: Subscription;
	private chart: am4charts.XYChart;

	constructor(public route: ActivatedRoute, private router: Router, private zone: NgZone) {}

	// This triggers contract switching …
	async ngOnChanges(_changes: SimpleChanges): Promise<void> {
		await this.loadContract();
	}

	// As there is only one thing that can change, we'll trigger updateing
	// the graph along with switching the contract.
	async loadContract(): Promise<void> {
		this.isLoaded = false;
		// TODO richtiges Fehlerhandling, idealerweise zusammen mit renderChart (dort werden die Verbräuche geladen)
		// TODO Optimierung: kein ständiges Laden des Vertrages / der Verbräuche
		try {
			this.contract = await ContractApi.get(this.contractId);
			this.isLoaded = true;
			await this.renderChart();
		} catch (err) {
			console.error(err);
			this.isLoaded = true;
			this.validationError = {
				subHeadline: "Bei der Erstellung trat ein Fehler auf. Bitte versuchen Sie es später erneut."
			};
		}
	}

	async renderChart(): Promise<void> {
		// reset old chart data from other contracts, if any
		if (this.chart) {
			this.chart.data = [];
		}
		let result = await ConsumptionsApi.get(this.contractId);
		switch (result) {
			case "CONSUMPTION_CONTRACT_ENDED_BEFORE_THIS_YEAR":
				this.validationError = {
					subHeadline: "Ihr Vertrag wurde in diesem Jahr nicht mehr beliefert, daher liegen keine Verbrauchswerte vor."
				};
				return;
			case "CONSUMPTION_NO_VALID_CONTRACTSTATUS":
				this.validationError = {
					subHeadline:
						"Ihr Vertrag befindet sich in einem Status, in dem uns noch keine Verbrauchsinformationen vorliegen. Bitte haben Sie noch etwas Geduld."
				};
				return;
			case "ERROR":
				this.validationError = {
					subHeadline: "Bei der Erstellung trat ein Fehler auf. Bitte versuchen Sie es später erneut."
				};
				return;
			default:
				this.validationError = null;
				this.consumptions = result;
		}

		// Convention with middle-ware: Receive 12 values.
		const chartData: any = [];
		const _p: any = Object.values(this.consumptions.previousYearConsumption.valuesMap);
		for (let m = 0; m < 12; m += 1) {
			chartData.push({
				date: new Date(this.consumptions.currentYearConsumption.year, m, 15),
				currentYearConsumption:
					Math.round(this.consumptions.currentYearConsumption.averageDailyValuesPerGranularity[m] * 100) / 100,
				previousYearConsumption: Math.round(_p[m] * 100) / 100
			});
		}

		setTimeout(() => {
			this.zone.runOutsideAngular(() => {
				const chart: am4charts.XYChart3D = am4core.create("barchart", am4charts.XYChart3D);
				chart.language.locale = am4lang_de_DE;
				chart.paddingRight = 20;
				chart.background.fill = am4core.color("#f1f1f1");

				chart.data = chartData;

				const dateAxis: am4charts.DateAxis<am4charts.AxisRenderer> = chart.xAxes.push(new am4charts.DateAxis());
				dateAxis.renderer.grid.template.location = 0;
				dateAxis.baseInterval = {
					timeUnit: "month",
					count: 1
				};
				dateAxis.groupData = true;
				dateAxis.renderer.minGridDistance = 40;

				const valueAxis: am4charts.ValueAxis<am4charts.AxisRenderer> = chart.yAxes.push(new am4charts.ValueAxis());
				valueAxis.tooltip.disabled = true;
				valueAxis.renderer.minWidth = 35;

				const currentYearConsumptions: am4charts.ColumnSeries3D = chart.series.push(new am4charts.ColumnSeries3D());
				currentYearConsumptions.dataFields.dateX = "date";
				currentYearConsumptions.dataFields.valueY = "currentYearConsumption";
				currentYearConsumptions.name = "Monatsverbrauch";
				currentYearConsumptions.tooltipText = "{name}:\n[bold]{valueY}kWh[/]";

				const previousYearConsumptions: am4charts.LineSeries = chart.series.push(new am4charts.LineSeries());
				previousYearConsumptions.bullets.push(new am4charts.CircleBullet());
				previousYearConsumptions.name = "Vorjahresverbrauch";
				previousYearConsumptions.stroke = am4core.color("#b700d3");
				previousYearConsumptions.strokeWidth = 5;
				previousYearConsumptions.tensionX = 0.8;
				previousYearConsumptions.dataFields.dateX = "date";
				previousYearConsumptions.dataFields.valueY = "previousYearConsumption";

				this.consumptions.consideredReadingDates.forEach(readingDate => {
					const event: am4charts.DateAxisDataItem = dateAxis.axisRanges.create();
					event.date = new Date(readingDate);
					event.grid.disabled = true;
					event.bullet = new am4core.Triangle();
					event.bullet.width = 15;
					event.bullet.height = 11;
					event.bullet.fill = am4core.color("#ae2626");
					event.bullet.horizontalCenter = "middle";
				});

				chart.legend = new am4charts.Legend();
				chart.cursor = new am4charts.XYCursor();

				const scrollbarX: am4charts.XYChartScrollbar = new am4charts.XYChartScrollbar();
				scrollbarX.series.push(currentYearConsumptions);
				chart.scrollbarX = scrollbarX;
				chart.scrollbarY = new am4core.Scrollbar();

				this.chart = chart;
			});
		}, 0);
	}

	ngOnDestroy(): void {
		if (this.routeQuery_) this.routeQuery_.unsubscribe();
		this.zone.runOutsideAngular(() => {
			if (this.chart) {
				this.chart.dispose();
			}
		});
	}

	changeTab(subsection: string): void {
		this.router.navigate(["/" + this.whitelabel, "kundenportal"], {
			queryParams: {
				section: this.section,
				subsection: subsection
			}
		});
	}

	setContract(contractId: string) {
		this.contractId = contractId;
	}
}
