import { Component } from "@angular/core";
import { Region } from "@rem/region";
import { Router, NavigationEnd, NavigationError } from "@angular/router";

@Component({
	selector: "app-component",
	templateUrl: "./app.component.html"
})
export class AppComponent {
	constructor(private router: Router) {
		setTheme();
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd || event instanceof NavigationError) {
				setTheme();
			}
		});
	}
}

function setTheme() {
	let region = Region.getCurrent();
	document.body.className = region === undefined ? "theme-default" : `theme-${region}`;
}
