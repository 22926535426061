/**
 * @name: API Service
 * @desc: Service to fetch the api data.
 */
import { Injectable } from "@angular/core";

@Injectable()
export class CurrencyService {
	format(n: number): string {
		if (n === undefined) return "";
		return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(n);
	}

	dotToComma(n: number): string {
		if (n === undefined) return "";
		return n.toString().replace(".", ",");
	}

	roundTwoDecPlaces(n: number): string {
		// (Math.round(0.27486*100*100)/100).toFixed(2).replace(".",",") => '27,49'
		return (Math.round(n * 100) / 100).toFixed(2).replace(".", ",");
	}
}
