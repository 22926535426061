import {Component, Input} from "@angular/core";
import {Router} from "@angular/router";

@Component({
    selector: "org-ova-success",
    templateUrl: "./ova-success.component.html",
    styleUrls: ["./ova-success.component.scss"],
})

export class OvaSuccessComponent {
    @Input() public globalData: any;
    @Input() public editorialData: any;
    @Input() public whiteLabel: string;
    @Input() public noconfirmation: string;
    constructor(
        private router: Router,
    ) {
    }

}
