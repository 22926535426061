import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: "tpl-client-portal",
    templateUrl: "./client-portal-template.component.html",
    styleUrls: ["./client-portal-template.component.scss"],
})
export class ClientPortalTemplateComponent implements OnInit, OnDestroy {

    @Input() public globalData: any; // Keep as any for --AOT
    @Input() public editorialData: any;
    @Input() public whitelabel: string;
    @ViewChild("contractInvoiceTab", {static: false}) public contractInvoiceTab: ElementRef;
    public showTemplateContract = false;
    public allContractData = [];
    public contractData: any;
    public contractType = 0;
    public section: string;

    private routeQuery_: Subscription;
    public contractStatus: string;
    private contractIsPending = false;
    public pendingInformation = false;
    public account: any;

    constructor(
        public route: ActivatedRoute,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.routeQuery_ = this.route.queryParams.subscribe(queryParams => {
            this.section = (queryParams || {}).section || "meine-kundendaten";
        });
    }

    changeStatus(): void {
        if (this.allContractData[this.contractType].contractStatus !== "in_delivery") {
            this.contractIsPending = true;
        }
        this.contractData = this.allContractData[this.contractType];
    }

    ngOnDestroy(): void {
        this.routeQuery_.unsubscribe();
    }

    contractPending(event: Event, section: string): any {
        if (!this.contractIsPending) {
            if (section === this.section) {
                this.router.navigate(["/" + this.whitelabel, "kundenportal"]);
            } else {
                this.router.navigate(["/" + this.whitelabel, "kundenportal"], {queryParams:{section: section}});
            }
        } else {
            // event.preventDefault();
            this.pendingInformation = true;
            if (section === this.section) {
                this.router.navigate(["/" + this.whitelabel, "kundenportal"]);
            } else {
                this.router.navigate(["/" + this.whitelabel, "kundenportal"], {queryParams:{section: section}});
            }
        }
    }

    changeTab(section: string): void {
        if (section === this.section) {
            this.router.navigate(["/" + this.whitelabel, "kundenportal"]);
        } else {
            this.router.navigate(["/" + this.whitelabel, "kundenportal"], {queryParams:{section: section}});
        }
    }
}
