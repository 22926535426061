import {Component, Input} from "@angular/core";

@Component({
    selector: "ato-image",
    templateUrl: "./image.component.html",
    styleUrls: ["./image.component.scss"],
})

export class ImageComponent {
    @Input() public image: any;
    @Input() public shadow = false;
}

