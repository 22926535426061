/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./meter-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../contract-data-meter-data-history/contract-data-meter-data-history.component.ngfactory";
import * as i5 from "../contract-data-meter-data-history/contract-data-meter-data-history.component";
import * as i6 from "@angular/router";
import * as i7 from "../contract-data-meter-data/contract-data-meter-data.component.ngfactory";
import * as i8 from "../contract-data-meter-data/contract-data-meter-data.component";
import * as i9 from "../../../atoms/loading-spinner/loading-spinner.component.ngfactory";
import * as i10 from "../../../atoms/loading-spinner/loading-spinner.component";
import * as i11 from "./meter-data.component";
var styles_MeterDataComponent = [i0.styles];
var RenderType_MeterDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MeterDataComponent, data: {} });
export { RenderType_MeterDataComponent as RenderType_MeterDataComponent };
function View_MeterDataComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], [[1, "value", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], null, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], null, null), (_l()(), i1.ɵted(3, null, ["", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.description; _ck(_v, 3, 0, currVal_1); }); }
function View_MeterDataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "contract-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["for", "contract-selector"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vertrag: "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "select", [["class", "container__select"], ["id", "contract-selector"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.setContract($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MeterDataComponent_3)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contracts; _ck(_v, 5, 0, currVal_0); }, null); }
function View_MeterDataComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-contract-data-meter-data-history", [], null, null, null, i4.View_ContractDataMeterDataHistoryComponent_0, i4.RenderType_ContractDataMeterDataHistoryComponent)), i1.ɵdid(1, 704512, null, 0, i5.ContractDataMeterDataHistoryComponent, [i6.ActivatedRoute, i6.Router, i1.NgZone], { whitelabel: [0, "whitelabel"], errorMessages: [1, "errorMessages"], contractId: [2, "contractId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.whitelabel; var currVal_1 = _co.errorMessages; var currVal_2 = _co.contractId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MeterDataComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-contract-data-meter-data", [], null, null, null, i7.View_ContractDataMeterDataComponent_0, i7.RenderType_ContractDataMeterDataComponent)), i1.ɵdid(1, 573440, null, 0, i8.ContractDataMeterDataComponent, [], { whitelabel: [0, "whitelabel"], errorMessages: [1, "errorMessages"], contractId: [2, "contractId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.whitelabel; var currVal_1 = _co.errorMessages; var currVal_2 = _co.contractId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MeterDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 33, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MeterDataComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 30, "div", [["class", "subtabs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "container tabs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "input", [["class", "tablet"], ["id", "subtab1_tablet"], ["name", "subtabs-tablet"], ["type", "radio"]], [[8, "checked", 0], [2, "default", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeTab("verlauf") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "label", [["class", "tablet"], ["for", "subtab1_tablet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Verlauf"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "input", [["class", "tablet"], ["id", "subtab3_tablet"], ["name", "subtabs-tablet"], ["type", "radio"]], [[8, "checked", 0], [2, "default", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeTab("z\u00E4hlerdaten") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "label", [["class", "tablet"], ["for", "subtab3_tablet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Z\u00E4hlerstand erfassen"])), (_l()(), i1.ɵeld(15, 0, null, null, 18, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "input", [["class", "mobile"], ["id", "subtab1_mobile"], ["name", "subtabs"], ["type", "radio"]], [[8, "checked", 0], [2, "default", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeTab("verlauf") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "label", [["class", "mobile"], ["for", "subtab1_mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Verlauf"])), (_l()(), i1.ɵeld(21, 0, null, null, 0, "span", [["class", "toggle-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MeterDataComponent_4)), i1.ɵdid(24, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "input", [["class", "mobile"], ["id", "subtab3_mobile"], ["name", "subtabs"], ["type", "radio"]], [[8, "checked", 0], [2, "default", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeTab("z\u00E4hlerdaten") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 3, "label", [["class", "mobile"], ["for", "subtab3_mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Z\u00E4hlerstand erfassen"])), (_l()(), i1.ɵeld(30, 0, null, null, 0, "span", [["class", "toggle-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MeterDataComponent_5)), i1.ɵdid(33, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contracts.length > 1); _ck(_v, 2, 0, currVal_0); var currVal_7 = (_co.subsection == "verlauf"); _ck(_v, 24, 0, currVal_7); var currVal_10 = (_co.subsection == "z\u00E4hlerdaten"); _ck(_v, 33, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.subsection == "verlauf"); var currVal_2 = !_co.subsection; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = (_co.subsection == "z\u00E4hlerdaten"); var currVal_4 = !_co.subsection; _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_5 = (_co.subsection == "verlauf"); var currVal_6 = !_co.subsection; _ck(_v, 17, 0, currVal_5, currVal_6); var currVal_8 = (_co.subsection == "z\u00E4hlerdaten"); var currVal_9 = !_co.subsection; _ck(_v, 26, 0, currVal_8, currVal_9); }); }
export function View_MeterDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ato-loading-spinner", [], null, null, null, i9.View_LoadingSpinnerComponent_0, i9.RenderType_LoadingSpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i10.LoadingSpinnerComponent, [], { loadingReady: [0, "loadingReady"], isRelative: [1, "isRelative"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MeterDataComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoaded; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.isLoaded; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_MeterDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-meter-data", [], null, null, null, View_MeterDataComponent_0, RenderType_MeterDataComponent)), i1.ɵdid(1, 245760, null, 0, i11.MeterDataComponent, [i6.ActivatedRoute, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MeterDataComponentNgFactory = i1.ɵccf("org-meter-data", i11.MeterDataComponent, View_MeterDataComponent_Host_0, { whitelabel: "whitelabel", errorMessages: "errorMessages", globalData: "globalData" }, {}, []);
export { MeterDataComponentNgFactory as MeterDataComponentNgFactory };
