<main class="faqTemplate" *ngIf="showTemplateGlobal">
    <!--insert header here-->
    <div class="faqTemplate__header">
        <org-header></org-header>
    </div>
    <div class="faqTemplate__wrapper">
        <div class="faqTemplate__wrapper__header">
            <h1 class="faqTemplate__wrapper__hl" >{{globalData.faqPage.headline}}</h1>
            <p class="faqTemplate__wrapper__text" [innerHTML]="globalData.faqPage.text"></p>
        </div>
        <org-faq-section *ngFor="let faqSection of faqSections.sections" [whitelabel]="whitelabel" [faqSection]="faqSection" [fragment]="fragment"></org-faq-section>
    </div>

</main>

<!--insert footer here-->
<footer class="faqTemplate__footer">
    <org-footer></org-footer>
</footer>
