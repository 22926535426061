<div class="container">
    <ato-loading-spinner [isRelative]="true" [loadingReady]="isLoaded"></ato-loading-spinner>
    <div class="row" *ngIf="isLoaded">
        <!-- Toggle col- classes depending on contract type -->
        <div class="contract-selector" *ngIf="contracts.length > 1">
            <label for="contract-selector">Vertrag: </label>
            <select class="container__select" id="contract-selector" (change)="setContract($event.target.value)">
                <option *ngFor="let contract of contracts" [attr.value]="contract.id">{{contract.description}}</option>
            </select>
        </div>
        <div class="subtabs">
            <div class="container tabs">
                <ng-container>
                    <input class="tablet" id="subtab1_tablet" type="radio" name="subtabs-tablet"
                        [checked]="subsection == 'allgemeine-daten'" [class.default]="!subsection"
                        (change)="changeTab('allgemeine-daten')">
                    <label class="tablet" for="subtab1_tablet"> <span class="text">Allgemeine Daten</span> </label>
                </ng-container>
                <ng-container>
                    <input class="tablet" id="subtab2_tablet" type="radio" name="subtabs-tablet"
                        [checked]="subsection == 'rechnung-und-zahlungsdaten'" [class.default]="!subsection"
                        (change)="changeTab('rechnung-und-zahlungsdaten')">
                    <label class="tablet" for="subtab2_tablet"> <span class="text">Rechnungs- und Zahlungsdaten</span>
                    </label>
                </ng-container>
                <ng-container>
                    <input class="tablet" id="subtab3_tablet" type="radio" name="subtabs-tablet"
                        [checked]="subsection == 'zählerdaten'" [class.default]="!subsection"
                        (change)="changeTab('zählerdaten')">
                    <label class="tablet" for="subtab3_tablet"> <span class="text">Zählerdaten</span> </label>
                </ng-container>
            </div>
            <div class="container">
                <ng-container>
                    <input class="mobile" id="subtab1_mobile" type="radio" name="subtabs"
                        [checked]="subsection == 'allgemeine-daten'" [class.default]="!subsection"
                        (change)="changeTab('allgemeine-daten')">
                    <label class="mobile" for="subtab1_mobile"> <span class="text">Allgemeine Daten</span>
                        <span class="toggle-icon"></span> </label>
                    <section>
                        <org-contract-data-general-data *ngIf="subsection == 'allgemeine-daten'"
                            [whitelabel]="whitelabel" [errorMessages]="errorMessages" [contractId]="contractId">
                        </org-contract-data-general-data>
                    </section>
                </ng-container>
                <ng-container>
                    <input class="mobile" id="subtab2_mobile" type="radio" name="subtabs"
                        [checked]="subsection == 'rechnung-und-zahlungsdaten'" [class.default]="!subsection"
                        (change)="changeTab('rechnung-und-zahlungsdaten')">
                    <label class="mobile" for="subtab2_mobile"> <span class="text">Rechnungs- und Zahlungsdaten</span>
                        <span class="toggle-icon"></span> </label>
                    <section>
                        <org-contract-data-invoice-data *ngIf="subsection == 'rechnung-und-zahlungsdaten'"
                            [whitelabel]="whitelabel" [errorMessages]="errorMessages" [contractId]="contractId">
                        </org-contract-data-invoice-data>
                    </section>
                </ng-container>
                <ng-container>
                    <input class="mobile" id="subtab3_mobile" type="radio" name="subtabs"
                        [checked]="subsection == 'zählerdaten'" [class.default]="!subsection"
                        (change)="changeTab('zählerdaten')">
                    <label class="mobile" for="subtab3_mobile"> <span class="text">Zählerdaten</span>
                        <span class="toggle-icon"></span> </label>
                    <section>
                        <org-contract-data-meter-data *ngIf="subsection == 'zählerdaten'" [whitelabel]="whitelabel"
                            [errorMessages]="errorMessages" [contractId]="contractId"></org-contract-data-meter-data>
                    </section>
                </ng-container>
            </div>
        </div>
    </div>
</div>
