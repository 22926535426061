import { API_ROOT_URL } from "./env";
import { Http } from "./http";
import { IamService } from "./iam";
import { Salutation } from "./customer";

export type Contact = {
	company: string;
	email: string;
	firstname: string;
	lastname: string;
	message: string;
	phone: string;
	region?: string;
	salutation: Salutation;
	topic: string;
	tosAccepted: true;
};

export class ContactApi {
	static async post(contact: Contact): Promise<void> {
		let isPrivateCall = contact.region && IamService.isLoggedIn();
		let res = await fetch(`${API_ROOT_URL}/api/${isPrivateCall ? "csc" : "ova"}/support`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				...(isPrivateCall ? { Authorization: `Bearer ${IamService.getToken()}` } : {})
			},
			body: JSON.stringify({
				data: {
					type: isPrivateCall ? "support-private" : "support-public",
					attributes: isPrivateCall ? contact : ({ tenantId: "lew", ...contact })
				}
			})
		});
		if (res.status === Http.UNAUTHORIZED) {
			IamService.logout();
			return;
		}
		if (res.status !== Http.ACCEPTED) {
			// TODO(Mark): Provide more specific error handling
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}
	}
}
