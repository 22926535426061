import {Component, Input, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {DataApi} from "@rem/data";

@Component({
    selector: "org-regional-content",
    templateUrl: "./regional-content.component.html",
    styleUrls: ["./regional-content.component.scss"],
})
export class RegionalContentComponent implements OnInit {
    @Input() siteArea: string;
    @Input() headlineVisible = true;
    public data: any;
    private response: any;
    public stageHeader: string;
    public videoAvailable = false;
    public whitelabel: string;

    public routeParams_: Subscription;
    private area: string;

    constructor(
        private route: ActivatedRoute,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.routeParams_ = this.route.params.subscribe(params => {
            this.whitelabel = params["region"];
        });
        this.area = this.siteArea || "landingPage";
        this.response = await DataApi.getRegionData(this.whitelabel);
        this.data = this.response[this.area];
        this.stageHeader = this.data && this.data ? this.data.stageHeadline : "";

        if (this.data &&
            this.data.video &&
            Object.keys(this.data.video).length) {
            this.videoAvailable = true;
        }
    }
}
