import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Contract, ContractApi} from "@rem/contract";
import {formatContractStatus,formatContractCancellationPeriod} from "@rem/enum-mapping";

@Component({
    selector: "org-contract-data-general-data",
    templateUrl: "./contract-data-general-data.component.html",
    styleUrls: ["./contract-data-general-data.component.scss"],
})
export class ContractDataGeneralDataComponent implements OnInit, OnChanges {
    @Input() public whitelabel: string;
    @Input() public errorMessages: any;
    @Input() public globalData: any;
    formatContractStatus = formatContractStatus;
    formatContractCancellationPeriod = formatContractCancellationPeriod;

    isLoaded = false;
    // TODO(Mark): Improve type def. This is currently just for debugging
    contract: Contract;
    @Input() contractId: string;

    async ngOnInit(): Promise<void> {
        this.contract = await ContractApi.get(this.contractId);
        this.isLoaded = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.contractId.isFirstChange()) {
            return;
        }
        this.isLoaded = false;
        this.contract = await ContractApi.get(this.contractId);
        this.isLoaded = true;
    }
}
