/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./regional-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../atoms/video/video.component.ngfactory";
import * as i3 from "../../atoms/video/video.component";
import * as i4 from "../../../utils/device-selector/device-selector.service";
import * as i5 from "../../atoms/image/image.component.ngfactory";
import * as i6 from "../../atoms/image/image.component";
import * as i7 from "@angular/common";
import * as i8 from "./regional-content.component";
import * as i9 from "@angular/router";
var styles_RegionalContentComponent = [i0.styles];
var RenderType_RegionalContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegionalContentComponent, data: {} });
export { RenderType_RegionalContentComponent as RenderType_RegionalContentComponent };
function View_RegionalContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ato-video", [], null, [["window", "device-changed"]], function (_v, en, $event) { var ad = true; if (("window:device-changed" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onDeviceChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_VideoComponent_0, i2.RenderType_VideoComponent)), i1.ɵdid(1, 4243456, null, 0, i3.VideoComponent, [i1.PLATFORM_ID, i4.DeviceSelectorService], { video: [0, "video"], poster: [1, "poster"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.video); var currVal_1 = ((_co.data == null) ? null : _co.data.poster); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_RegionalContentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ato-image", [], null, null, null, i5.View_ImageComponent_0, i5.RenderType_ImageComponent)), i1.ɵdid(1, 49152, null, 0, i6.ImageComponent, [], { image: [0, "image"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.image); _ck(_v, 1, 0, currVal_0); }, null); }
function View_RegionalContentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "regionalContent__infobox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "regionalContent__infobox__headlineWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "h1", [["class", "regionalContent__infobox__headlineWrapper__title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stageHeader; _ck(_v, 2, 0, currVal_0); }); }
export function View_RegionalContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "regionalContent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "regionalContent__background"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegionalContentComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegionalContentComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegionalContentComponent_3)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoAvailable; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.videoAvailable; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.headlineVisible; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_RegionalContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-regional-content", [], null, null, null, View_RegionalContentComponent_0, RenderType_RegionalContentComponent)), i1.ɵdid(1, 114688, null, 0, i8.RegionalContentComponent, [i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegionalContentComponentNgFactory = i1.ɵccf("org-regional-content", i8.RegionalContentComponent, View_RegionalContentComponent_Host_0, { siteArea: "siteArea", headlineVisible: "headlineVisible" }, {}, []);
export { RegionalContentComponentNgFactory as RegionalContentComponentNgFactory };
