<div class="loginForm">
	<form class="loginForm__form" [formGroup]="loginFormGroup" (ngSubmit)="onSubmit()" #loginForm="ngForm">
		<fieldset class="loginForm__form__fieldset">
			<h3 class="loginForm__headline">Bitte melden Sie sich an.</h3>
			<p>
				Bitte geben Sie hier Ihre E-Mail-Adresse und Ihr Passwort an, um sich im Kundenportal anzumelden, oder
				<a [routerLink]="['/' + whitelabel, 'passwort-hilfe']" routerLinkActive="active"
					>fordern Sie ein (neues) Passwort an</a
				>.
			</p>
			<div class="formfield flyinglabel">
				<input
					type="email"
					id="email"
					name="email"
					formControlName="email"
					[placeholder]="loginData.labelEmail"
					autocomplete="username"
				/>
				<label class="loginForm__form__label required" for="email" [innerHTML]="loginData.labelEmail"></label>
			</div>
			<div class="formfield flyinglabel">
				<input
					type="password"
					id="password"
					formControlName="password"
					[placeholder]="loginData.labelPassword"
					autocomplete="current-password"
				/>
				<label class="loginForm__form__label required" for="password" [innerHTML]="loginData.labelPassword"></label>
			</div>
			<div
				class="loginForm__form__error"
				*ngIf="loginFormGroup.get('email').invalid && loginFormGroup.get('email').touched && !loginErrorText"
			>
				{{ errorMessages.validation.email }}
			</div>
			<div class="loginForm__form__error" *ngIf="loginErrorText">{{ errorMessages.validation.loginError }}</div>
			<div class="formactions">
				<button class="secondary" *ngIf="isLoaded">
					<a [routerLink]="['/' + whitelabel, 'passwort-hilfe']" routerLinkActive="active">Kein Passwort zur Hand?</a>
				</button>
				<button *ngIf="isLoaded" class="loginForm__form__button" type="submit" [disabled]="loginForm.invalid">
					{{ loginData.submitButton }}
				</button>
				<ato-loading-spinner
					class="loading-spinner"
					*ngIf="!isLoaded"
					[isRelative]="true"
					[loadingReady]="isLoaded"
				></ato-loading-spinner>
			</div>
		</fieldset>
	</form>
</div>
