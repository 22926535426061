var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { CookieConstants } from "@p2p/constants/constants";
import { DataApi } from "@rem/data";
var GoogleOptOutButtonComponent = /** @class */ (function () {
    function GoogleOptOutButtonComponent(cookieService) {
        this.cookieService = cookieService;
        this.optOutNeeded = false;
    }
    GoogleOptOutButtonComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, DataApi.getGlobalData()];
                    case 1:
                        _a.globalData = _b.sent();
                        this.allCookies = this.cookieService.getAll();
                        this.blackListCookies = [];
                        this.checkForGoogleCookies();
                        return [2 /*return*/];
                }
            });
        });
    };
    GoogleOptOutButtonComponent.prototype.handleOptOut = function () {
        var _this = this;
        if (this.blackListCookies.length > 0) {
            this.blackListCookies.forEach(function (element) {
                _this.cookieService.delete(element);
            });
            this.optOutNeeded = false;
            this.cookieService.set(CookieConstants.DO_NOT_TRACK_COOKIE_NAME, "1", CookieConstants.EXPIRY_DURATION_IN_DAYS, "/");
        }
    };
    GoogleOptOutButtonComponent.prototype.checkForGoogleCookies = function () {
        for (var _i = 0, _a = Object.keys(this.allCookies); _i < _a.length; _i++) {
            var key = _a[_i];
            if (key.match(CookieConstants.GA_REGEXP) !== null ||
                key.match(CookieConstants.GAT_REGEXP) !== null ||
                key.match(CookieConstants.GID_REGEXP) !== null ||
                key.match(CookieConstants.COOKIE_DISCLAIMER_REGEXP) !== null) {
                this.blackListCookies.push(key);
            }
        }
        if (this.blackListCookies.length > 0) {
            this.optOutNeeded = true;
        }
    };
    return GoogleOptOutButtonComponent;
}());
export { GoogleOptOutButtonComponent };
