<section class="overlay {{absolute ? 'overlay-absolute' : ''}}" [ngClass]="{
        'is-mac': isMac
    }">
    <button (click)="onCloseAction.emit()" *ngIf="showCloseButton" class="overlay__close">&#x2715;</button>
    <div class="overlay__inner">
        <svg *ngIf="type === 'success'" class="overlay__icon" xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76">
            <g fill="none" fill-rule="nonzero" transform="translate(2 2)">
                <circle cx="36" cy="36" r="36" stroke="#86A449" stroke-width="4"/>
                <path fill="#86A449" d="M55.278 20L58 22.693 28.22 52 14 38.067l2.716-2.7 11.498 11.267z"/>
            </g>
        </svg>
        <svg *ngIf="type === 'error'" class="overlay__icon" xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76">
            <g fill="none" fill-rule="nonzero" transform="translate(2 2)">
                <circle cx="36" cy="36" r="36" stroke="#F3463F" stroke-width="4"/>
                <path fill="#F3463F" d="M32 10h8l-2 38h-4l-2-38zm4 50a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/>
            </g>
        </svg>
        <svg *ngIf="type === 'information'" class="overlay__icon" xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76">
            <g fill="none" fill-rule="nonzero" transform="translate(2 2)">
                <circle cx="36" cy="36" r="36" stroke="#3F96F2" stroke-width="4"/>
                <path fill="#3F96F2" d="M40 52h6a2 2 0 1 1 0 4H30a2 2 0 1 1 0-4h6V30h-6a2 2 0 1 1 0-4h8a2 2 0 0 1 2 2v24zm0-34a4 4 0 1 1-8.001-.001A4 4 0 0 1 40 18z"/>
            </g>
        </svg>
        <h2 [innerHtml]="headline" class="overlay__headline"></h2>
        <p *ngIf="subHeadline" [innerHtml]="subHeadline" class="overlay__subHeadline"></p>
        <p *ngIf="callToAction" class="overlay__buttons">
            <button (click)="onCallToAction.emit()" class="overlay__button">{{ callToAction }}</button>
            <button *ngIf="callToAction2" (click)="onCallToAction2.emit()" class="overlay__button">{{ callToAction2 }}</button>
        </p>
    </div>
</section>
