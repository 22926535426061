<div class="passwordResetSetForm">
    <form *ngIf="!passwordResetSetSuccess" class="passwordResetSetForm__form" [formGroup]="passwordResetSetFormGroup" (ngSubmit)="onSubmit()">
        <fieldset class="passwordResetSetForm__form__fieldset">
            <h3 class="passwordResetSetForm__headline" [innerHTML]="passwordResetSetData.headline"></h3>
            <div class="formfield flyinglabel">
                <input type="text" id="passwordResetEmail" formControlName="passwordResetEmail" placeholder="E-Mail-Adresse" autocomplete="username">
                <label class="passwordResetSetForm__form__label" for="passwordResetEmail">{{ passwordResetSetData.labelEmail }}<sup>*</sup></label>
            </div>
            <div class="passwordResetSetForm__form__error"
                 *ngIf="passwordResetSetFormGroup.get('passwordResetEmail').invalid && passwordResetSetFormGroup.get('passwordResetSetPassword2').touched">{{ errorMessages.validation.email }}</div>
            <div class="formfield flyinglabel">
                <input type="text" class="token" id="passwordResetToken" formControlName="passwordResetToken" placeholder="Sicherheitscode" autocomplete="one-time-code">
                <label class="passwordResetSetForm__form__label" for="passwordResetToken">{{ passwordResetSetData.labelToken }}<sup>*</sup></label>
            </div>
            <div class="passwordResetSetForm__form__error"
                 *ngIf="passwordResetSetFormGroup.get('passwordResetToken').invalid && passwordResetSetFormGroup.get('passwordResetSetPassword2').touched">{{ errorMessages.validation.passwordToken }}</div>
            <p [innerHtml]="passwordResetSetData.tokenHint" class="passwordResetSetForm__form__hint"></p>
            <p class="passwordResetSetForm__form__error" *ngIf="passwordResetSetErrorText">{{ passwordResetSetData.passwordResetSetError }}</p>
            <hr />
            <div class="formfield flyinglabel">
                <input type="password" id="passwordResetSetPassword" formControlName="passwordResetSetPassword" placeholder="Neues Passwort" autocomplete="new-password">
                <label class="passwordResetSetForm__form__label" for="passwordResetSetPassword">{{ passwordResetSetData.labelPasswordNew }}<sup>*</sup></label>
            </div>
            <div class="formfield flyinglabel">
                <input type="password" id="passwordResetSetPassword2" formControlName="passwordResetSetPassword2" placeholder="Neues Passwort (Wdh.)" autocomplete="new-password">
                <label class="passwordResetSetForm__form__label" for="passwordResetSetPassword2">{{ passwordResetSetData.labelPasswordNew2 }}<sup>*</sup></label>
            </div>
            <div class="passwordResetSetForm__form__error"
                *ngIf="!passwordResetSetFormGroup.get('passwordResetSetPassword2').invalid && passwordResetSetFormGroup.get('passwordResetSetPassword2').touched &&
                    passwordResetSetFormGroup.get('passwordResetSetPassword').value !== passwordResetSetFormGroup.get('passwordResetSetPassword2').value">{{
                    errorMessages.validation.password2 }}</div>
            <p [innerHtml]="errorMessages.validation.password" class="passwordResetSetForm__form__hint {{passwordResetSetFormGroup.get('passwordResetSetPassword').invalid && passwordResetSetFormGroup.get('passwordResetSetPassword').touched ? 'passwordResetSetForm__form__error' : ''}}">
            </p>
            <div class="formactions">
                <button class="passwordResetSetForm__form__button" type="submit" [disabled]="passwordResetSetFormGroup.invalid">
                    {{ passwordResetSetData.submitButton }}
                </button>
            </div>
        </fieldset>
    </form>

    <div *ngIf="passwordResetSetSuccess" class="passwordResetSetForm__password-reset-set-success" id="password-reset-set-success">
        <div class="success-check">
            <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58">
                <g fill="none" fill-rule="nonzero" transform="translate(2 2)">
                    <circle cx="27" cy="27" r="27" stroke="#86A449" stroke-width="3" />
                    <path fill="#86A449" d="M41.458 15l2.042 2.02L21.165 39 10.5 28.55l2.037-2.024 8.624 8.45z" />
                </g>
            </svg>
        </div>
        <h2>{{ passwordResetSetData.titlePasswordChanged }}</h2>
        <p>{{ passwordResetSetData.labelPasswordChanged }}</p>
        <p>
            <button [routerLink]="['/' + whitelabel, 'login']" routerLinkActive="active"
                class="passwordResetSetForm__form__button" type="button">{{ passwordResetSetData.ok }}</button>
        </p>
    </div>
</div>
