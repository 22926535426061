import * as moment from 'moment';

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Contract, ContractApi } from '@rem/contract';
import { METER_READ_ORIGIN, METER_READ_QUALITY, METER_READ_TYPE } from '@rem/enum-mapping';
import { Reading, ReadingApi } from '@rem/reading';

@Component({
	selector: "org-contract-data-meter-data",
	templateUrl: "./contract-data-meter-data.component.html",
	styleUrls: ["./contract-data-meter-data.component.scss"]
})
export class ContractDataMeterDataComponent implements OnChanges {
	@Input() public whitelabel: string;
	@Input() public errorMessages: any;
	@Input() public globalData: any;
	public formValidationError: PromiseReturnType<typeof ReadingApi.post> = null;

	isLoaded = false;
	isSubmitting = false;
	contract: Contract;
	@Input() contractId: string;

	METER_READ_TYPE = METER_READ_TYPE;
	METER_READ_ORIGIN = METER_READ_ORIGIN;
	METER_READ_QUALITY = METER_READ_QUALITY;

	public readingDataForm: FormGroup;

	async loadData(): Promise<void> {
		this.isSubmitting = false;
		this.isLoaded = false;
		this.contract = await ContractApi.get(this.contractId);

		this.readingDataForm = new FormGroup({
			readingDate: new FormControl({ value: "", disabled: false }, [
				Validators.required,
				this.existingDateValidator.bind(this),
				this.notInTheFutureValidator.bind(this)
			])
		});

		for (let register of this.contract.device.registers) {
			this.readingDataForm.addControl(
				`readingValue${register.description}`,
				new FormControl({ value: "", disabled: false }, [this.readingValueValidator])
			);
		}
		this.isLoaded = true;
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		await this.loadData();
	}

	formItemInvalid(form: FormGroup, item: string) {
		return (form.get(item).touched || form.get(item).dirty) && form.get(item).invalid;
	}

	validateReadingData() {
		const controls = Object.keys(this.readingDataForm.controls).map(controlName => this.readingDataForm.get(controlName));
		const isAnyInvalid = controls.some(control => control.invalid);
		return !isAnyInvalid;
	}

	sanitizeDate(value: string) {
		const isGermanDateFormat = value.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/);
		if (isGermanDateFormat) {
			let [day, month, year] = value.split(".");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		} else {
			return value;
		}
	}

	async saveReadingData(forceImplausibleSaving = false) {
		this.isSubmitting = true;
		this.formValidationError = null;

		const form = this.readingDataForm;
		const readingValues = [];

		for (let controlName in form.controls) {
			if (controlName.startsWith("readingValue")) {
				readingValues.push({
					registerType: controlName.replace("readingValue", ""),
					value: parseInt(form.get(controlName).value.trim())
				});
			}
		}

		let data: Partial<Reading> = {
			readAt: this.sanitizeDate(form.get("readingDate").value),
			readingValues
		};

		let result = await ReadingApi.post(this.contract.id, data, forceImplausibleSaving);
		this.formValidationError = result;
		this.isSubmitting = false;
	}

	readingValueValidator(control: FormControl): { [x: string]: boolean } {
		let val = control.value.trim();
		if (!val) {
			return { required: true };
		}
		if (!/^\d+$/.test(val)) {
			return { pattern: true };
		}
		// No range check: '0' is explicitly allowed. For example, in newly
		// constructed buildings the reading value is often times '0'.
		//
		// Negative values are not allowed, but are already handled by
		// the regex above.
		return null;
	}

	existingDateValidator(control: FormControl): { [s: string]: boolean } {
		if (!control.value) {
			return null;
		}
		return moment(this.sanitizeDate(control.value)).isValid() ? null : { mustBeValidDate: true };
	}

	notInTheFutureValidator(control: FormControl): { [s: string]: boolean } {
		// only check future date if we have a valid date
		if (this.existingDateValidator(control)) {
			return null;
		}
		const inFuture = moment(this.sanitizeDate(control.value)).isAfter(this.todayAsYYYYminusMMminusDD(), "day");
		return inFuture ? { mustNotBeInFuture: true } : null;
	}

	todayAsYYYYminusMMminusDD(): string {
		return moment().format("YYYY-MM-DD");
	}
}
