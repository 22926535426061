var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_de_DE from '@amcharts/amcharts4/lang/de_DE';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { NgZone, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsumptionsApi } from '@rem/consumptions';
import { ContractApi } from '@rem/contract';
am4core.useTheme(am4themes_animated);
var ContractDataMeterDataHistoryComponent = /** @class */ (function () {
    function ContractDataMeterDataHistoryComponent(route, router, zone) {
        this.route = route;
        this.router = router;
        this.zone = zone;
        this.validationError = null;
        // If true a spinner will be visible.
        this.isLoaded = false;
        this.contracts = [];
    }
    // This triggers contract switching …
    ContractDataMeterDataHistoryComponent.prototype.ngOnChanges = function (_changes) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadContract()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // As there is only one thing that can change, we'll trigger updateing
    // the graph along with switching the contract.
    ContractDataMeterDataHistoryComponent.prototype.loadContract = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoaded = false;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        _a = this;
                        return [4 /*yield*/, ContractApi.get(this.contractId)];
                    case 2:
                        _a.contract = _b.sent();
                        this.isLoaded = true;
                        return [4 /*yield*/, this.renderChart()];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _b.sent();
                        console.error(err_1);
                        this.isLoaded = true;
                        this.validationError = {
                            subHeadline: "Bei der Erstellung trat ein Fehler auf. Bitte versuchen Sie es später erneut."
                        };
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ContractDataMeterDataHistoryComponent.prototype.renderChart = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, chartData, _p, m;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // reset old chart data from other contracts, if any
                        if (this.chart) {
                            this.chart.data = [];
                        }
                        return [4 /*yield*/, ConsumptionsApi.get(this.contractId)];
                    case 1:
                        result = _a.sent();
                        switch (result) {
                            case "CONSUMPTION_CONTRACT_ENDED_BEFORE_THIS_YEAR":
                                this.validationError = {
                                    subHeadline: "Ihr Vertrag wurde in diesem Jahr nicht mehr beliefert, daher liegen keine Verbrauchswerte vor."
                                };
                                return [2 /*return*/];
                            case "CONSUMPTION_NO_VALID_CONTRACTSTATUS":
                                this.validationError = {
                                    subHeadline: "Ihr Vertrag befindet sich in einem Status, in dem uns noch keine Verbrauchsinformationen vorliegen. Bitte haben Sie noch etwas Geduld."
                                };
                                return [2 /*return*/];
                            case "ERROR":
                                this.validationError = {
                                    subHeadline: "Bei der Erstellung trat ein Fehler auf. Bitte versuchen Sie es später erneut."
                                };
                                return [2 /*return*/];
                            default:
                                this.validationError = null;
                                this.consumptions = result;
                        }
                        chartData = [];
                        _p = Object.values(this.consumptions.previousYearConsumption.valuesMap);
                        for (m = 0; m < 12; m += 1) {
                            chartData.push({
                                date: new Date(this.consumptions.currentYearConsumption.year, m, 15),
                                currentYearConsumption: Math.round(this.consumptions.currentYearConsumption.averageDailyValuesPerGranularity[m] * 100) / 100,
                                previousYearConsumption: Math.round(_p[m] * 100) / 100
                            });
                        }
                        setTimeout(function () {
                            _this.zone.runOutsideAngular(function () {
                                var chart = am4core.create("barchart", am4charts.XYChart3D);
                                chart.language.locale = am4lang_de_DE;
                                chart.paddingRight = 20;
                                chart.background.fill = am4core.color("#f1f1f1");
                                chart.data = chartData;
                                var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                                dateAxis.renderer.grid.template.location = 0;
                                dateAxis.baseInterval = {
                                    timeUnit: "month",
                                    count: 1
                                };
                                dateAxis.groupData = true;
                                dateAxis.renderer.minGridDistance = 40;
                                var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                                valueAxis.tooltip.disabled = true;
                                valueAxis.renderer.minWidth = 35;
                                var currentYearConsumptions = chart.series.push(new am4charts.ColumnSeries3D());
                                currentYearConsumptions.dataFields.dateX = "date";
                                currentYearConsumptions.dataFields.valueY = "currentYearConsumption";
                                currentYearConsumptions.name = "Monatsverbrauch";
                                currentYearConsumptions.tooltipText = "{name}:\n[bold]{valueY}kWh[/]";
                                var previousYearConsumptions = chart.series.push(new am4charts.LineSeries());
                                previousYearConsumptions.bullets.push(new am4charts.CircleBullet());
                                previousYearConsumptions.name = "Vorjahresverbrauch";
                                previousYearConsumptions.stroke = am4core.color("#b700d3");
                                previousYearConsumptions.strokeWidth = 5;
                                previousYearConsumptions.tensionX = 0.8;
                                previousYearConsumptions.dataFields.dateX = "date";
                                previousYearConsumptions.dataFields.valueY = "previousYearConsumption";
                                _this.consumptions.consideredReadingDates.forEach(function (readingDate) {
                                    var event = dateAxis.axisRanges.create();
                                    event.date = new Date(readingDate);
                                    event.grid.disabled = true;
                                    event.bullet = new am4core.Triangle();
                                    event.bullet.width = 15;
                                    event.bullet.height = 11;
                                    event.bullet.fill = am4core.color("#ae2626");
                                    event.bullet.horizontalCenter = "middle";
                                });
                                chart.legend = new am4charts.Legend();
                                chart.cursor = new am4charts.XYCursor();
                                var scrollbarX = new am4charts.XYChartScrollbar();
                                scrollbarX.series.push(currentYearConsumptions);
                                chart.scrollbarX = scrollbarX;
                                chart.scrollbarY = new am4core.Scrollbar();
                                _this.chart = chart;
                            });
                        }, 0);
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractDataMeterDataHistoryComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        if (this.routeQuery_)
            this.routeQuery_.unsubscribe();
        this.zone.runOutsideAngular(function () {
            if (_this.chart) {
                _this.chart.dispose();
            }
        });
    };
    ContractDataMeterDataHistoryComponent.prototype.changeTab = function (subsection) {
        this.router.navigate(["/" + this.whitelabel, "kundenportal"], {
            queryParams: {
                section: this.section,
                subsection: subsection
            }
        });
    };
    ContractDataMeterDataHistoryComponent.prototype.setContract = function (contractId) {
        this.contractId = contractId;
    };
    return ContractDataMeterDataHistoryComponent;
}());
export { ContractDataMeterDataHistoryComponent };
