import {Component, Input} from "@angular/core";
import {IamService} from "@rem/iam";

@Component({
    selector: "org-subheader",
    templateUrl: "./subheader.component.html",
    styleUrls: ["./subheader.component.scss"]
})
export class SubheaderComponent {
    @Input() public subheaderData: any;
    isLoggedIn = IamService.isLoggedIn();
}
