<footer>
	<div class="subfooter">
		<div class="footernav">
			<ul>
				<li><a href="/oberguenzburg/impressum">Impressum</a></li>
				<li><a href="/oberguenzburg/agb">AGB</a></li>
				<li><a href="/oberguenzburg/kontaktformular">Kontakt</a></li>
				<li><a href="/oberguenzburg/faq">FAQ</a></li>
				<li><a href="/TODO">Stromkennzeichnung</a></li>
				<li><a href="/oberguenzburg/datenschutz">Datenschutz</a></li>
			</ul>
		</div>
		<p class="tenant_logo"><span>LEW AG</span></p>
		<p class="copyright">© 2020 LEW AG, Augsburg.</p>
	</div>
</footer>
