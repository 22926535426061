import {Router} from "@angular/router";
import {Component, Input, OnInit} from "@angular/core";

@Component({
    selector: "org-faq-section",
    templateUrl: "./faq-section.component.html",
    styleUrls: ["./faq-section.component.scss"],
})

export class FaqSectionComponent implements OnInit {
    @Input() public faqSection: any;
    @Input() public fragment: string;
    @Input() public whitelabel: string;
    public showTemplate = false;

    constructor(
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.showTemplate = true;
    }

    changeTab(fragment: string): void {
        if (fragment === this.fragment) {
            this.router.navigate(["/" + this.whitelabel, "faq"]);
        } else {
            this.router.navigate(["/" + this.whitelabel, "faq"], {fragment: fragment});
        }
    }
}
