var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegExpConstants } from '@p2p/constants/constants';
import { City, CityApi } from '@rem/city';
import { ContractApi } from '@rem/contract';
import { Region } from '@rem/region';
var ContractDataInvoiceDataComponent = /** @class */ (function () {
    function ContractDataInvoiceDataComponent() {
        this.isLoaded = false;
        this.region = Region.getCurrent();
        this.cities = [];
        this.citynames = [];
        this.streets = [];
        this.storedCityname = "";
        this.storedStreet = "";
        this.billingDataEditMode = false;
        this.paymentDataEditMode = false;
        this.formSuccess = false;
        this.formError = false;
        this.billingDataForm = new FormGroup({
            salutation: new FormControl({ value: "", disabled: true }),
            firstname: new FormControl({ value: "", disabled: true }, [Validators.required]),
            lastname: new FormControl({ value: "", disabled: true }, [Validators.required]),
            companyName: new FormControl({ value: "", disabled: true }),
            email: new FormControl({ value: "", disabled: true }, [Validators.pattern(RegExpConstants.EMAIL_REGEXP)]),
            phone: new FormControl({ value: "", disabled: true }, [Validators.pattern(RegExpConstants.PHONE_NUMBER_REGEXP)]),
            street: new FormControl({ value: "", disabled: true }, [Validators.required]),
            houseNumber: new FormControl({ value: "", disabled: true }, [Validators.required]),
            zipcode: new FormControl({ value: "", disabled: true }, [
                Validators.required,
                Validators.pattern(RegExpConstants.ZIPCODE_REGEXP)
            ]),
            cityname: new FormControl({ value: "", disabled: true }, [Validators.required])
        });
        this.paymentDataForm = new FormGroup({
            paymentMethod: new FormControl({ value: "", disabled: true }),
            owner: new FormControl({ value: "", disabled: true }, [Validators.required]),
            iban: new FormControl({ value: "", disabled: true }, [
                Validators.required,
                Validators.pattern(RegExpConstants.IBAN_REGEXP)
            ]),
            bic: new FormControl({ value: "", disabled: true }),
            bankname: new FormControl({ value: "", disabled: true }),
            allowDirectDebit: new FormControl({ value: true, disabled: false }, [Validators.requiredTrue])
        });
    }
    ContractDataInvoiceDataComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.onBillingAddressChanges();
                        return [4 /*yield*/, this.loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // TODO debuggen, ob wir das wirklich brauchen
    ContractDataInvoiceDataComponent.prototype.ngOnChanges = function (changes) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (changes.contractId.isFirstChange()) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractDataInvoiceDataComponent.prototype.loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoaded = false;
                        _a = this;
                        return [4 /*yield*/, ContractApi.get(this.contractId)];
                    case 1:
                        _a.contract = _b.sent();
                        return [4 /*yield*/, this.postProcessLoadData()];
                    case 2:
                        _b.sent();
                        this.isLoaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractDataInvoiceDataComponent.prototype.postProcessLoadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.billingDataForm.setValue({
                    salutation: this.contract.billingAddress.contact.salutation,
                    firstname: this.contract.billingAddress.contact.firstname,
                    lastname: this.contract.billingAddress.contact.lastname,
                    companyName: this.contract.billingAddress.contact.companyName,
                    email: this.contract.billingAddress.contact.email,
                    phone: this.contract.billingAddress.contact.phone,
                    street: this.contract.billingAddress.street,
                    houseNumber: this.contract.billingAddress.houseNumber,
                    zipcode: this.contract.billingAddress.zipcode,
                    cityname: this.contract.billingAddress.city
                });
                this.storedCityname = this.contract.billingAddress.city;
                this.storedStreet = this.contract.billingAddress.street;
                this.paymentDataForm.setValue({
                    paymentMethod: this.contract.payment.paymentMethod,
                    owner: this.contract.payment.bankAccount.owner,
                    iban: this.contract.payment.bankAccount.iban,
                    bic: this.contract.payment.bankAccount.bic,
                    bankname: this.contract.payment.bankAccount.bankname,
                    allowDirectDebit: this.contract.payment.paymentMethod === "DIRECT_DEBIT" ? true : false
                });
                return [2 /*return*/];
            });
        });
    };
    ContractDataInvoiceDataComponent.prototype.editBillingData = function () {
        this.setBillingAddressEditMode(true);
        this.validateBillingData();
    };
    ContractDataInvoiceDataComponent.prototype.setBillingAddressEditMode = function (mode) {
        if (mode === void 0) { mode = true; }
        if (mode) {
            this.billingDataForm.enable();
        }
        else {
            this.billingDataForm.disable();
        }
        this.billingDataEditMode = mode;
    };
    ContractDataInvoiceDataComponent.prototype.saveBillingData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var controls, patchData, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 5]);
                        controls = this.billingDataForm;
                        patchData = {
                            contact: {
                                companyName: controls.get("companyName").value,
                                salutation: controls.get("salutation").value,
                                firstname: controls.get("firstname").value,
                                lastname: controls.get("lastname").value
                            },
                            street: controls.get("street").value,
                            houseNumber: controls.get("houseNumber").value,
                            zipcode: controls.get("zipcode").value,
                            city: controls.get("cityname").value
                        };
                        if (controls.get("email").value && controls.get("email").value.length > 0) {
                            patchData.contact.email = controls.get("email").value;
                        }
                        if (controls.get("phone").value && controls.get("phone").value.length > 0) {
                            patchData.contact.phone = controls.get("phone").value;
                        }
                        return [4 /*yield*/, ContractApi.patchBillingAddress(this.contract.id, patchData)];
                    case 1:
                        _a.sent();
                        this.formSuccess = true;
                        return [3 /*break*/, 5];
                    case 2:
                        error_1 = _a.sent();
                        this.formSuccess = false;
                        this.formError = true;
                        return [3 /*break*/, 5];
                    case 3:
                        this.setBillingAddressEditMode(false);
                        return [4 /*yield*/, this.loadData()];
                    case 4:
                        _a.sent();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ContractDataInvoiceDataComponent.prototype.cancelBillingData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.billingDataForm.reset({
                    salutation: this.contract.billingAddress.contact.salutation,
                    firstname: this.contract.billingAddress.contact.firstname,
                    lastname: this.contract.billingAddress.contact.lastname,
                    companyName: this.contract.billingAddress.contact.companyName,
                    email: this.contract.billingAddress.contact.email,
                    phone: this.contract.billingAddress.contact.phone,
                    street: this.contract.billingAddress.street,
                    houseNumber: this.contract.billingAddress.houseNumber,
                    zipcode: this.contract.billingAddress.zipcode,
                    cityname: this.contract.billingAddress.city
                });
                this.setBillingAddressEditMode(false);
                return [2 /*return*/];
            });
        });
    };
    ContractDataInvoiceDataComponent.prototype.validateBillingData = function () {
        var _this = this;
        var controls = Object.keys(this.billingDataForm.controls).map(function (controlName) { return _this.billingDataForm.get(controlName); });
        var isAnyDirty = controls.some(function (control) { return control.dirty; });
        var isAnyInvalid = controls.some(function (control) { return control.invalid; });
        return isAnyDirty && !isAnyInvalid;
    };
    ContractDataInvoiceDataComponent.prototype.editPaymentData = function () {
        this.setPaymentDataEditMode(true);
    };
    ContractDataInvoiceDataComponent.prototype.setPaymentDataEditMode = function (mode) {
        if (mode === void 0) { mode = true; }
        if (mode) {
            this.paymentDataForm.enable();
        }
        else {
            this.paymentDataForm.disable();
        }
        this.paymentDataEditMode = mode;
    };
    ContractDataInvoiceDataComponent.prototype.savePaymentData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var controls, paymentMethod, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 5]);
                        controls = this.paymentDataForm;
                        paymentMethod = controls.get("paymentMethod").value;
                        // TODO refactoring
                        return [4 /*yield*/, ContractApi.patchPayment(this.contract.id, Object.assign({
                                paymentMethod: paymentMethod
                            }, paymentMethod === "DIRECT_DEBIT"
                                ? {
                                    bankAccount: {
                                        owner: controls.get("owner").value,
                                        iban: controls.get("iban").value
                                    }
                                }
                                : {}), controls.get("allowDirectDebit").value)];
                    case 1:
                        // TODO refactoring
                        _a.sent();
                        this.formSuccess = true;
                        return [3 /*break*/, 5];
                    case 2:
                        error_2 = _a.sent();
                        this.formSuccess = false;
                        this.formError = true;
                        return [3 /*break*/, 5];
                    case 3:
                        this.setPaymentDataEditMode(false);
                        return [4 /*yield*/, this.loadData()];
                    case 4:
                        _a.sent();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ContractDataInvoiceDataComponent.prototype.cancelPaymentData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.paymentDataForm.reset({
                    paymentMethod: this.contract.payment.paymentMethod,
                    owner: this.contract.payment.bankAccount.owner,
                    iban: this.contract.payment.bankAccount.iban,
                    bic: this.contract.payment.bankAccount.bic,
                    bankname: this.contract.payment.bankAccount.bankname,
                    allowDirectDebit: this.contract.payment.paymentMethod === "DIRECT_DEBIT" ? true : false
                });
                this.setPaymentDataEditMode(false);
                return [2 /*return*/];
            });
        });
    };
    ContractDataInvoiceDataComponent.prototype.clearPaymentData = function () {
        this.paymentDataForm.patchValue({
            iban: "",
            bic: "",
            bankname: "",
            allowDirectDebit: false
        });
    };
    ContractDataInvoiceDataComponent.prototype.validatePaymentData = function () {
        var _this = this;
        if (this.paymentDataForm.get("paymentMethod").value === "BANK_TRANSFER") {
            return true;
        }
        var controls = Object.keys(this.paymentDataForm.controls).map(function (controlName) { return _this.paymentDataForm.get(controlName); });
        var isAnyDirty = controls.some(function (control) { return control.dirty; });
        var isAnyInvalid = controls.some(function (control) { return control.invalid; });
        return isAnyDirty && !isAnyInvalid;
    };
    ContractDataInvoiceDataComponent.prototype.downloadInvoice = function (name, invoiceNumber, contractId, date) {
        return __awaiter(this, void 0, void 0, function () {
            var blob, link;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ContractApi.getInvoice(contractId, invoiceNumber)];
                    case 1:
                        blob = _a.sent();
                        link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = name + "-" + contractId + "-" + date.format("YYYY-MM-DD") + ".pdf";
                        // use dispatchEvent() instead of click() to make it work in ie and older ff versions
                        link.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }));
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractDataInvoiceDataComponent.prototype.showMissingStreetsErrorMessage = function () {
        return this.billingDataEditMode && this.billingDataForm.controls.cityname.valid && this.streets.length === 0;
    };
    ContractDataInvoiceDataComponent.prototype.showMissingHousenumberErrorMessage = function () {
        // for layout reasons show the housenumber error only if there isn't an error in street selection
        return this.billingDataForm.controls.houseNumber.invalid && !this.showMissingStreetsErrorMessage();
    };
    ContractDataInvoiceDataComponent.prototype.onBillingAddressChanges = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.billingDataForm.controls.zipcode.valueChanges.subscribe(function (newZipcode) { return __awaiter(_this, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                if (!(this.billingDataForm.controls.zipcode.value.length === 5)) return [3 /*break*/, 2];
                                _a = this;
                                return [4 /*yield*/, CityApi.getAll(newZipcode)];
                            case 1:
                                _a.cities = _b.sent();
                                this.citynames = this.cities.map(function (city) { return city.name; });
                                // check for non-existing zipcode to show the error on zipcode and not on city field
                                if (this.cities.length === 0) {
                                    this.billingDataForm.controls.zipcode.setErrors({ nonExisting: true });
                                }
                                else {
                                    this.billingDataForm.controls.zipcode.setErrors(null);
                                }
                                // set the selection to the proper default value (without we wouldn't reload the streets)
                                if (this.cities.length === 1) {
                                    this.billingDataForm.controls.cityname.patchValue(this.cities[0].name);
                                }
                                else {
                                    // reset selected value, if not present anymore
                                    if (!this.citynames.includes(this.billingDataForm.controls.cityname.value)) {
                                        this.billingDataForm.controls.cityname.patchValue("");
                                    }
                                }
                                _b.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); });
                this.billingDataForm.controls.cityname.valueChanges.subscribe(function (newCityname) { return __awaiter(_this, void 0, void 0, function () {
                    var newCity, cityWithStreets;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                newCity = this.cities.find(function (city) { return city.name === newCityname; });
                                if (!newCity) return [3 /*break*/, 2];
                                return [4 /*yield*/, CityApi.getOne(newCity.id)];
                            case 1:
                                cityWithStreets = _a.sent();
                                this.streets = cityWithStreets.streets;
                                // set the selection to the proper default value
                                if (this.streets.length === 1) {
                                    this.billingDataForm.controls.street.patchValue(this.streets[0]);
                                }
                                else {
                                    // reset selected value, if not present anymore
                                    if (!this.streets.includes(this.billingDataForm.controls.street.value)) {
                                        this.billingDataForm.controls.street.patchValue("");
                                    }
                                }
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    return ContractDataInvoiceDataComponent;
}());
export { ContractDataInvoiceDataComponent };
