import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: "tpl-login",
    templateUrl: "./login-template.component.html",
    styleUrls: ["./login-template.component.scss"],
})
export class LoginTemplateComponent implements OnInit, OnDestroy {
    @Input() public globalData: any; // Keep as any for --AOT
    @Input() public whitelabel: string;
    public urlPath: string;

    private routeUrl_: Subscription;

    constructor(
        public route: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.routeUrl_ = this.route.url.subscribe(url => {
            this.urlPath = url[url.length - 1].path;
        });
    }

    ngOnDestroy(): void {
        this.routeUrl_.unsubscribe();
    }
}
