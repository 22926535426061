import {Component, OnInit} from "@angular/core";
import {CookieService} from "ngx-cookie-service";
import {CookieConstants} from "@p2p/constants/constants";
import {DataApi} from "@rem/data";

@Component({
    selector: "ato-google-opt-out-button",
    templateUrl: "./google-opt-out-button.component.html",
    styleUrls: ["./google-opt-out-button.component.scss"],
})
export class GoogleOptOutButtonComponent implements OnInit {
    public globalData: any;
    public editorialData: any;
    public allCookies: Object;
    public blackListCookies: any;
    public optOutNeeded: Boolean = false;

    constructor(private cookieService: CookieService) {}

    async ngOnInit(): Promise<void> {
        this.globalData = await DataApi.getGlobalData();
        this.allCookies = this.cookieService.getAll();
        this.blackListCookies = [];
        this.checkForGoogleCookies();
    }

    handleOptOut(): void {
        if (this.blackListCookies.length > 0) {
            this.blackListCookies.forEach(element => {
                this.cookieService.delete(element);
            });
            this.optOutNeeded = false;
            this.cookieService.set(CookieConstants.DO_NOT_TRACK_COOKIE_NAME, "1", CookieConstants.EXPIRY_DURATION_IN_DAYS, "/");
        }
    }

    checkForGoogleCookies(): void {
        for (const key of Object.keys(this.allCookies)) {
            if (
                key.match(CookieConstants.GA_REGEXP) !== null ||
                key.match(CookieConstants.GAT_REGEXP) !== null ||
                key.match(CookieConstants.GID_REGEXP) !== null ||
                key.match(CookieConstants.COOKIE_DISCLAIMER_REGEXP) !== null
            ) {
                this.blackListCookies.push(key);
            }
        }

        if (this.blackListCookies.length > 0) {
            this.optOutNeeded = true;
        }
    }
}
