import { ENV } from "./new/env";
import { Component } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
	selector: "redesign",
	template: /*html*/ `
		<iframe [src]="this.frame_src"></iframe>
	`,
	styles: [
		/*css*/ `
			iframe {
				position: fixed;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 100%;
				height: 100%;
				border: none;
				margin: 0;
				padding: 0;
				z-index: 999;
			}
		`
	]
})
export class RedesignComponent {
	frame_src: SafeResourceUrl;

	constructor(private sanitizer: DomSanitizer) {
		let tenant = "lew";
		let region = "oberguenzburg";

		let page = location.pathname.includes("/erzeuger") ? "stromerzeuger" : "stromkunden";
		let ref = encodeURIComponent(`${location.protocol}//${location.host}/${region}`);

		let frame_env = ENV === "qa" ? "qas" : ENV;
		let frame_base = `https://remdesign${frame_env}.z6.web.core.windows.net`;
		// frame_base = "http://localhost:3000"; // uncomment for dev testing
		let frame_src = `${frame_base}/index.html?ref=${ref}#/${tenant}/${region}/${page}`;

		this.frame_src = this.sanitizer.bypassSecurityTrustResourceUrl(frame_src);
	}
}
