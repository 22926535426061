// Sentry
import * as Sentry from "@sentry/browser";
import { environment } from "@p2p/environments/environment";
import { ErrorHandler } from "@angular/core";
import { registerLocaleData, isPlatformBrowser } from "@angular/common";
import localeDe from "@angular/common/locales/de";
registerLocaleData(localeDe, "de");
// Sentry with this project's specific config.
Sentry.init({
    dsn: "https://733b2041f663402e9dd0e29693527277@sentry.eon-cds.de/29"
});
// Sentry
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        Sentry.captureException(error.originalError || error);
        throw error;
    };
    return SentryErrorHandler;
}());
export { SentryErrorHandler };
// Capture only errors in prod environment.
export function provideErrorHandler(platformId) {
    if (environment.production && isPlatformBrowser(platformId)) {
        return new SentryErrorHandler();
    }
    else {
        return new ErrorHandler();
    }
}
var ɵ0 = environment.config;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
