/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ova-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../templates/ova-template/ova-template.component.ngfactory";
import * as i3 from "../../templates/ova-template/ova-template.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./ova-page.component";
import * as i7 from "../../../services/seo.service";
var styles_OvaPageComponent = [i0.styles];
var RenderType_OvaPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OvaPageComponent, data: {} });
export { RenderType_OvaPageComponent as RenderType_OvaPageComponent };
function View_OvaPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tpl-ova", [], null, null, null, i2.View_OvaTemplateComponent_0, i2.RenderType_OvaTemplateComponent)), i1.ɵdid(1, 245760, null, 0, i3.OvaTemplateComponent, [i4.ActivatedRoute], { globalData: [0, "globalData"], editorialData: [1, "editorialData"], whitelabel: [2, "whitelabel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.globalData; var currVal_1 = _co.editorialData; var currVal_2 = _co.whitelabel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_OvaPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "whitelabelPage"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OvaPageComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showTemplateGlobal && _co.showTemplateEditorial); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OvaPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pag-ova", [], null, null, null, View_OvaPageComponent_0, RenderType_OvaPageComponent)), i1.ɵdid(1, 245760, null, 0, i6.OvaPageComponent, [i4.ActivatedRoute, i7.SeoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OvaPageComponentNgFactory = i1.ɵccf("pag-ova", i6.OvaPageComponent, View_OvaPageComponent_Host_0, {}, {}, []);
export { OvaPageComponentNgFactory as OvaPageComponentNgFactory };
