import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {SeoService} from "@p2p/services/seo.service";
import {DataApi} from "@rem/data";

@Component({
    selector: "pag-login",
    templateUrl: "./login-page.component.html",
    styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit, OnDestroy {
    public globalData: object;
    public editorialData: any;
    public showTemplate = false;
    public showTemplateEditorial = false;
    public whitelabel: string;
    private routeParams_: Subscription;

    constructor (
        private route: ActivatedRoute,
        private seoService: SeoService
    ) { }

    async ngOnInit(): Promise<void> {
        this.routeParams_ = this.route.params.subscribe(async params => {
            this.whitelabel = params["region"];

            this.editorialData = await DataApi.getRegionData(this.whitelabel);
            this.showTemplateEditorial = true;
            this.seoService.setTitle(this.editorialData.pageMetas.default.title);
            this.seoService.updateMetaTags(this.editorialData.pageMetas.default.metaData);
         });

        this.globalData = await DataApi.getGlobalData();
        this.showTemplate = true;

    }

    ngOnDestroy(): void {
        this.routeParams_.unsubscribe();
    }
}
