<section class="contactInfo container lazyload-trigger" (deferLoad)="showImage=true">
    <div class="contactInfo__wrapper row">
        <div class="col-xs-4 col-md-8 col-lg-8 col-xl-12">
            <p class="contactInfo__headline" [innerHTML]="contactInfoData.headline1"></p>
            <h4 class="contactInfo__headline contactInfo__headline--big" [innerHTML]="contactInfoData.headline2"></h4>
            <figure *ngIf="contactInfoData.image" [className]="contactInfoData.isTeam ? 'contactInfo__contact-person is-team' : 'contactInfo__contact-person'">
                <img class="contactInfo__contact-person__image lazyload" *ngIf="showImage" [src]="contactInfoData.image" [alt]="contactInfoData.imageAlt"/>
                <figcaption class="contactInfo__contact-person__caption">
                    <strong [innerHTML]="contactInfoData.name"></strong><br>
                    <span [innerHTML]="contactInfoData.city"></span>
                </figcaption>
            </figure>
            <ul class="contactInfo__channels">
                <li class="contactInfo__channels__item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                        <path fill-rule="nonzero" d="M23.942 25.88c-.36-.82-.615-1.406-.826-1.908-.332-.793-.447-.843-.745-.69a1.088 1.088 0 0 0-.149.102c-.052.04-.272.218-.282.226-.942.75-1.873.993-3.305.573-2.015-.59-4.69-5.554-5.24-8.206-.32-1.533.524-3.392 2.101-4.33.831-.494.837-.511.492-1.148-.146-.27-.266-.487-.384-.695l-.054-.093c-.236-.411-1.831-3.047-2.567-4.278-.316-.53-.204-.523-.49-.286.001-.001-.326.288-.44.37-.04.027-.332.371-.609.81a9.05 9.05 0 0 0-1.086 2.565c-.735 2.83-.401 6.263 1.419 10.313 2.37 5.275 5.031 8.55 7.765 10.14 1.894 1.1 3.678 1.278 4.81.847 1.177-.448 1.206-.554.805-1.532a57.084 57.084 0 0 0-.503-1.169l-.67-1.518-.042-.093zm2.543.807c.236.538.402.923.521 1.213.79 1.922.419 3.263-1.943 4.161-4.455 1.694-10.527-1.837-15.11-12.036-2.004-4.457-2.383-8.355-1.53-11.636.559-2.155 1.699-3.957 2.476-4.506.008-.006.242-.213.318-.276 1.156-.958 2.475-.886 3.483.8.724 1.21 2.335 3.874 2.585 4.308l.057.1c.126.221.253.45.405.732.874 1.613.496 2.794-1.229 3.82-.851.506-1.309 1.514-1.166 2.202.412 1.98 2.784 6.384 3.846 6.695.759.223 1.032.151 1.496-.218-.009.006.234-.19.311-.25.155-.118.296-.212.45-.292 1.386-.712 2.742-.128 3.506 1.696.204.487.455 1.064.811 1.873l.041.093.672 1.52z"/>
                    </svg>
                    <p class="contactInfo__channels__info" [innerHTML]="contactInfoData.phone"></p>
                </li>
                <li class="contactInfo__channels__item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                        <path fill-rule="nonzero" d="M30 18c0-6.627-5.373-12-12-12-6.63 0-12 5.371-12 12s5.37 12 12 12c6.627 0 12-5.373 12-12zm2 0c0 7.732-6.269 14-14 14-7.734 0-14-6.266-14-14S10.266 4 18 4c7.731 0 14 6.268 14 14zm-7.107 5.48l-1.351 1.475-7.107-6.515v-6.657h2v5.777l6.458 5.92z"/>
                    </svg>
                    <dl class="contactInfo__channels__info contactInfo__channels__opening-hours" [innerHTML]="contactInfoData.service"></dl>
                </li>
                <li class="contactInfo__channels__item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                        <path fill-rule="nonzero" d="M23.29 19.705c-2.757 2.706-4.427 3.962-5.29 3.962-.88 0-2.601-1.307-5.459-4.127l-6.5 6.5a1 1 0 0 1-1.415-1.414l6.505-6.505c-1.715-1.756-3.766-3.951-6.2-6.617a1 1 0 0 1 1.478-1.348C13.11 17.498 17.453 21.666 18 21.666c.547 0 4.89-4.168 11.591-11.51a1 1 0 1 1 1.478 1.348c-2.512 2.752-4.617 5.002-6.365 6.785l6.336 6.337a1 1 0 1 1-1.414 1.414l-6.335-6.335zM5 9a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V10a1 1 0 0 0-1-1H5zm0-2h26a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3z"/>
                    </svg>
                    <p class="contactInfo__channels__info" [innerHTML]="contactInfoData.email"></p>
                </li>
            </ul>
            <p *ngIf="showContactFormLink" class="contactInfo__wrapper__text" [innerHTML]="contactInfoData.text"></p>
            <p *ngIf="showContactFormLink" class="contactInfo__wrapper__text">
                <a rel="noopener" [routerLink]="['/' + whitelabel, 'kontaktformular']" [queryParams]="queryParams" routerLinkActive="active" class="contactInfo__button">
                    {{ contactInfoData.button }}
                    <svg class="contactInfo__button__icon" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                        <path fill-rule="nonzero" d="M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"/>
                    </svg>
                </a>
            </p>
        </div>
    </div>
</section>
