import {Injectable} from "@angular/core";
import {WindowRef} from "@p2p/utils/window-ref";


@Injectable()
export class DeviceSelectorService {
    private device: any;

    constructor(
        private winRef: WindowRef
    ) {}

    getDevice(): String {
        return this.device;
    }

    setDevice(device): void {
        if (this.device !== device && this.winRef.nativeWindow) {
            this.device = device;
            this.winRef.nativeWindow.dispatchEvent(new Event("device-changed"));
        }

    }
}
