import {AfterViewInit, Component, ElementRef, HostListener, Inject, Input, PLATFORM_ID, ViewChild} from "@angular/core";
import {DeviceSelectorService} from "@p2p/utils/device-selector/device-selector.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
    selector: "ato-video",
    templateUrl: "./video.component.html",
    styleUrls: ["./video.component.scss"],
})

export class VideoComponent implements AfterViewInit {
    @Input() public video: any;
    @Input() public poster: string;
    @ViewChild("videoContainer", {static: false}) private videoContainer: ElementRef;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private deviceSelectorService: DeviceSelectorService,
    ) { }

    @HostListener("window:device-changed", ["$event"])
    onDeviceChanged(event: any): any {
        this.update();
    }

    ngAfterViewInit(): void {
        this.update();
    }

    private update(): void {
        if (isPlatformBrowser(this.platformId)) {
            const device = this.deviceSelectorService.getDevice();
            const videoNative = this.videoContainer.nativeElement;
            videoNative.innerHTML = "";

            if ( device === "xs" || device === "sm" || device === "md") {
                this.appendFromSourceList(this.getValues("small"));
            } else {
                this.appendFromSourceList(this.getValues("big"));
            }
            videoNative.load();
            videoNative.play();
        }
    }

    private appendFromSourceList(sourceList: any): void {
        Object.keys(sourceList).forEach((key) => {
            const source = document.createElement("source");
            const sourceLink = sourceList[key];
            const fileExt = sourceLink.match(/\.[0-9a-z]+$/i)[0].replace(".", "");
            source.src = sourceLink;
            source.type = "video/" + fileExt;
            this.videoContainer.nativeElement.appendChild(source);
        });
    }

    private getValues(attribute: any): any {
        return Object.entries(this.video).map(([key, value]) => ({key, value})).reduce( function(accumulator: any, current: any): any {
            if (current.key.match(new RegExp(attribute, "g")) && current.value) {
                accumulator[current.key] = current.value;
            }
            return accumulator;
        }, {});
    }
}

