import { Contract } from "./contract";

export function formatContractStatus(contract: Contract) {
    switch (contract.status) {
        case "CANCELLATION_PREVIOUS": return "Beim Vorlieferanten gekündigt";
        case "EXPIRED":               return "Ausgelaufen";
        case "IN_DELIVERY":           return "Aktiv - In Belieferung";
        case "NEW":                   return "Neu";
        case "TERMINATED":            return "Gekündigt";
        case "WAITING":               return "Wartend";
        default:                      return "Unbestimmt";
    }
};

export function formatContractCancellationPeriod(contract: Contract) {
    const { cancellationPeriod, cancellationPeriodUnit } = contract;
    switch (cancellationPeriod) {
        case 0:            return "Keine";
        case 1: switch (cancellationPeriodUnit) {
            case "DAYS":   return "1 Tag";
            case "WEEKS":  return "1 Woche";
            case "MONTHS": return "1 Monat";
            case "YEARS":  return "1 Jahr";
            default:       return "Unbestimmt";
        }
        default: switch (cancellationPeriodUnit) {
            case "DAYS":   return `${cancellationPeriod} Tage`;
            case "WEEKS":  return `${cancellationPeriod} Wochen`;
            case "MONTHS": return `${cancellationPeriod} Monate`;
            case "YEARS":  return `${cancellationPeriod} Jahre`;
            default:       return "Unbestimmt";
        }
    }
}

export const METER_READ_TYPE = {
    MOVE_IN: "Einzug",
    REGULAR: "Turnusabrechnung",
    HELPER_INVOICE: "Zwischenabrechnung",
    METER_REMOVAL: "Zählerausbau",
    METER_INSTALLATION: "Zählereinbau",
    MOVEOUT: "Auszug",
    IN_BETWEEN: "Zwischenablesung",
};

export const METER_READ_ORIGIN = {
    CUSTOMER: "Kunde",
    DSO: "Netzbetreiber",
    THIRD_PARTY: "Dritte",
    PREVIOUS_SUPPLIER: "Vorheriger Anbieter",
    MSB: "Messstellenbetreiber",
    UNKNOWN: "Unbekannt"
};

export const METER_READ_QUALITY = {
    ESTIMATION: "Schätzung",
    READING: "Abgelesen",
    UNUSABLE: "Wird geprüft",
    UNKNOWN: "Unbekannt"
};
