<main class="client-portal">
	<div class="client-portal__header">
		<org-header></org-header>
	</div>
	<div class="client-portal__subheader">
		<org-subheader [subheaderData]="globalData.subheader"></org-subheader>
	</div>
	<div class="client-portal__wrapper">
		<div class="container">
			<!-- Meine Kundendaten -->
			<ng-container>
				<input
					class="tablet"
					id="tab1_tablet"
					type="radio"
					name="tabs-tablet"
					[checked]="section == 'meine-kundendaten'"
					[class.default]="!section"
					(change)="changeTab('meine-kundendaten')"
				/>
				<label class="tablet" for="tab1_tablet"> <span class="text">Meine Kundendaten</span> </label>
			</ng-container>
			<!-- Meine Veträge -->
			<ng-container>
				<input
					class="tablet"
					id="tab2_tablet"
					type="radio"
					name="tabs-tablet"
					[checked]="section == 'meine-verträge'"
					[class.default]="!section"
					(change)="changeTab('meine-verträge')"
				/>
				<label class="tablet" for="tab2_tablet"> <span class="text">Meine Verträge</span> </label>
			</ng-container>
			<!-- Meine Region -->
			<ng-container>
				<input
					class="tablet"
					id="tab4_tablet"
					type="radio"
					name="tabs-tablet"
					[checked]="section == 'meine-region'"
					[class.default]="!section"
					(change)="changeTab('meine-region')"
				/>
				<label class="tablet" for="tab4_tablet"> <span class="text">Meine Region</span> </label>
			</ng-container>
			<!-- Meine Zählerdaten -->
			<ng-container>
				<input
					class="tablet"
					id="tab5_tablet"
					type="radio"
					name="tabs-tablet"
					[checked]="section == 'meine-zaehlerdaten'"
					[class.default]="!section"
					(change)="changeTab('meine-zaehlerdaten')"
				/>
				<label class="tablet" for="tab5_tablet"> <span class="text">Meine Zählerdaten</span> </label>
			</ng-container>
		</div>
		<!-- Meine Kundendaten {mobile} -->
		<ng-container>
			<input
				class="mobile"
				id="tab1_mobile"
				type="radio"
				name="tabs"
				[checked]="section == 'meine-kundendaten'"
				[class.default]="!section"
				(click)="changeTab('meine-kundendaten')"
			/>
			<label class="mobile" for="tab1_mobile">
				<span class="text">Meine Kundendaten</span> <span class="toggle-icon"></span>
			</label>
			<section>
				<org-customer-data
					*ngIf="section == 'meine-kundendaten'"
					[whitelabel]="whitelabel"
					[errorMessages]="globalData.errorMessages"
				></org-customer-data>
			</section>
		</ng-container>
		<!-- Meine Verträge {mobile} -->
		<ng-container>
			<input
				class="mobile"
				id="tab2_mobile"
				type="radio"
				name="tabs"
				[checked]="section == 'meine-verträge'"
				(click)="changeTab('meine-verträge')"
			/>
			<label class="mobile" for="tab2_mobile">
				<span class="text">Meine Verträge</span> <span class="toggle-icon"></span>
			</label>
			<section>
				<org-contract-data
					*ngIf="section == 'meine-verträge'"
					[whitelabel]="whitelabel"
					[errorMessages]="globalData.errorMessages"
				></org-contract-data>
			</section>
		</ng-container>
		<!-- Meine Region {mobile} -->
		<ng-container>
			<input
				class="mobile"
				id="tab4_mobile"
				type="radio"
				name="tabs"
				[checked]="section == 'meine-region'"
				(click)="changeTab('meine-region')"
			/>
			<label class="mobile" for="tab4_mobile">
				<span class="text">Meine Region</span> <span class="toggle-icon"></span>
			</label>
			<section>
				<org-producers-map *ngIf="section == 'meine-region'"></org-producers-map>
			</section>
		</ng-container>
		<!-- Meine Zählerdaten {mobile} -->
		<ng-container>
			<input
				class="mobile"
				id="tab5_mobile"
				type="radio"
				name="tabs"
				[checked]="section == 'meine-zaehlerdaten'"
				(click)="changeTab('meine-zaehlerdaten')"
			/>
			<label class="mobile" for="tab5_mobile">
				<span class="text">Meine Zählerdaten</span> <span class="toggle-icon"></span>
			</label>
			<section>
				<org-meter-data
					*ngIf="section == 'meine-zaehlerdaten'"
					[whitelabel]="whitelabel"
					[errorMessages]="globalData.errorMessages"
				></org-meter-data>
			</section>
		</ng-container>
	</div>
	<mol-overlay
		*ngIf="pendingInformation"
		[type]="'information'"
		[headline]="'Vertrag in Bearbeitung'"
		[subHeadline]="
			'Ihr Vertrag wird derzeit von uns bearbeitet. Ihre Daten werden innerhalb der nächsten 2 Wochen vervollständigt.'
		"
		[callToAction]="'Alles klar'"
		(onCallToAction)="pendingInformation = false"
	></mol-overlay>
</main>
<!--insert footer here-->
<footer class="client-portal__footer">
	<org-footer></org-footer>
</footer>
