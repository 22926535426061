var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// See: https://confluence.dev.eon.com/display/P2P/Active+Regions
var regionMap = {
    oberguenzburg: {
        email: "%EMAIL%",
        supportNumber: { value: "%SUPPORT_TELEFONNUMMER%", displayValue: "%SUPPORT_TELEFONNUMMER%" },
        contactNumber: [
            { value: "%KONTAKT_TELEFONNUMMER%", displayValue: "%KONTAKT_TELEFONNUMMER%" },
        ],
        openingHours: [
            ["%ÖFFNUNGSZEITEN%", "%ÖFFNUNGSZEITEN%"],
        ]
    },
};
var dayMap = { So: 0, Mo: 1, Di: 2, Mi: 3, Do: 4, Fr: 5, Sa: 6 };
// "Do" -> [4]
// "Di-Do" -> [2, 3, 4]
// "Di, Do" -> [2, 4]
function parseDays(days) {
    if (days.includes("-")) {
        var split = days.split("-");
        var first = split[0], last = split[1];
        return range(dayMap[first], dayMap[last]);
    }
    if (days.includes(", ")) {
        var split = days.split(", ");
        return split.map(function (d) { return dayMap[d]; });
    }
    return [dayMap[days]];
}
// 2, 4 -> [2, 3, 4]
function range(low, high) {
    var r = new Array();
    for (var i = low; i <= high; i++)
        r.push(i);
    return r;
}
// "07:30 - 18:00" -> [[7, 30], [18, 0]]
function parseTime(time) {
    return time.split(" - ").map(function (t) { return t.split(":").map(function (i) { return parseInt(i); }); });
}
export function CustomerService(region) {
    var customerService = regionMap[region];
    var parsedOpeningHours = customerService.openingHours.map(function (_a) {
        var days = _a[0], time = _a[1];
        return ({
            days: parseDays(days),
            time: parseTime(time)
        });
    });
    var isOpen = function (now) {
        if (now === void 0) { now = new Date(); }
        var day = now.getDay();
        for (var _i = 0, parsedOpeningHours_1 = parsedOpeningHours; _i < parsedOpeningHours_1.length; _i++) {
            var openingHour = parsedOpeningHours_1[_i];
            if (openingHour.days.includes(day)) {
                var _a = openingHour.time, start = _a[0], end = _a[1];
                var startDate = new Date(now.getTime());
                startDate.setHours(start[0]);
                startDate.setMinutes(start[1]);
                var endDate = new Date(now.getTime());
                endDate.setHours(end[0]);
                endDate.setMinutes(end[1]);
                if (now >= startDate && now <= endDate) {
                    return true;
                }
            }
        }
        return false;
    };
    return __assign({}, customerService, { isOpen: isOpen });
}
