/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header.component";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "section", [["class", "toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "/oberguenzburg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Oberg\u00FCnzburg"], ["class", "region_logo"], ["src", "/assets/editorial/oberguenzburg/media/logo-kommune.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "ul", [["class", "toolbar_items"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "li", [["class", "item-account"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["class", "account"], ["href", "/oberguenzburg/login"], ["title", "Wenn Sie bereits Kunde sind, schauen Sie hier in Ihr Kundenkonto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kundenkonto"]))], null, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 49152, null, 0, i2.HeaderComponent, [], null, null)], null, null); }
var HeaderComponentNgFactory = i1.ɵccf("org-header", i2.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
