import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ContractApi, ContractHeader} from "@rem/contract";

@Component({
    selector: "org-contract-data",
    templateUrl: "./contract-data.component.html",
    styleUrls: ["./contract-data.component.scss"]
})
export class ContractDataComponent implements OnInit, OnDestroy {
    @Input() public whitelabel: string;
    @Input() public errorMessages: any;
    @Input() public globalData: any;

    public isLoaded = false;
    public contracts: ContractHeader[] = [];

    public section: string;
    public subsection: string;
    private routeQuery_: Subscription;
    contractId: string;

    constructor(public route: ActivatedRoute, private router: Router) {}

    async ngOnInit(): Promise<void> {
        this.contracts = await ContractApi.getAll();
        this.contractId = this.contracts[0].id;
        this.routeQuery_ = this.route.queryParams.subscribe(queryParams => {
            this.section = (queryParams || {}).section;
            this.subsection = (queryParams || {}).subsection || "allgemeine-daten";
        });
        this.isLoaded = true;
    }

    ngOnDestroy(): void {
        if (this.routeQuery_) this.routeQuery_.unsubscribe();
    }

    changeTab(subsection: string): void {
        this.router.navigate(["/" + this.whitelabel, "kundenportal"], {
            queryParams: {
                section: this.section,
                subsection: subsection
            }
        });
    }

    setContract(contractId: string) {
        this.contractId = contractId;
    }
}
