// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    config: {
        appUrl: "https://dev.www.bayernwerk-regio-energie.de",
        appApiUrl: "https://api.dev.bayernwerk-regio-energie.de/v1",
        appApiV2Url: "https://api.dev.rem.bayernwerk-regio-energie.de/api",
        appBasicAuth: "Basic dXNlcjpoYXo5aXU4VWNlM3BoYWVkYWVIMmVxdWU5cmVlNm91bA==",
        mapApiUrl: "/mapdata"
    }
};
