import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {Meta, Title} from "@angular/platform-browser";
import {environment} from "@p2p/environments/environment";

export interface IMetaTag {
    attribute: "content" | "property";
    attributeValue: string;
    content: string;
}

@Injectable()
export class SeoService {
    constructor(
        @Inject(DOCUMENT) private doc,
        private titleService: Title,
        private meta: Meta
    ) {}

    createCanonicalTag(): void {
        const link: HTMLLinkElement = this.doc.createElement("link");
        const url  = `${environment.config.appUrl}${this.doc.URL}`;
        link.setAttribute("rel", "canonical");
        this.doc.head.appendChild(link);
        link.setAttribute("href", url);
     }

     setTitle(title: string): void {
        this.titleService.setTitle(title);
     }

     updateMetaTags(metaData: Array<IMetaTag>): void {
        metaData.forEach((meta: IMetaTag) => {
            meta.attribute === "property" ?
                this.meta.updateTag({ property: meta.attributeValue, content: meta.content }) :
                this.meta.updateTag({ name: meta.attributeValue, content: meta.content });
        });
     }
}
