// Sentry
import * as Sentry from "@sentry/browser";
import { environment } from "@p2p/environments/environment";
//
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule, Title } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { LOCALE_ID, NgModule, ErrorHandler, Injectable, PLATFORM_ID } from "@angular/core";
import { registerLocaleData, isPlatformBrowser } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import { TransferHttpCacheModule } from "@nguniversal/common";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

// import all patterns here
import { AgbBagComponent } from "@p2p/pages/agb-bag/agb-bag.component";
import { AgbPageComponent } from "@p2p/pages/agb-page/agb-page.component";
import { AuthGuard } from "@p2p/utils/auth.guard";
import { CallToActionComponent } from "@p2p/molecules/call-to-action/call-to-action.component";
import { CtaLinkComponent } from "@p2p/atoms/cta-link/cta-link.component";
import { CardComponent } from "@p2p/molecules/card/card.component";
import { ClientPortalPageComponent } from "@p2p/pages/client-portal-page/client-portal-page.component";
import { ClientPortalTemplateComponent } from "@p2p/templates/client-portal-template/client-portal-template.component";
import { ContactFormComponent } from "@p2p/organisms/contact-form/contact-form.component";
import { ContactFormPageComponent } from "@p2p/pages/contact-form-page/contact-form-page.component";
import { ContactFormTemplateComponent } from "@p2p/templates/contact-form-template/contact-form-template.component";
import { ContactFormV2AltComponent } from "@p2p/organisms/contact-form-v2/contact-form-v2-alt.component";
import { ContactFormV2Component } from "@p2p/organisms/contact-form-v2/contact-form-v2.component";
import { ContactFormV2PageAltComponent } from "@p2p/pages/contact-form-v2-page/contact-form-v2-page-alt.component";
import { ContactFormV2PageComponent } from "@p2p/pages/contact-form-v2-page/contact-form-v2-page.component";
import { ContactFormV2TemplateAltComponent } from "@p2p/templates/contact-form-v2-template/contact-form-v2-template-alt.component";
import { ContactFormV2TemplateComponent } from "@p2p/templates/contact-form-v2-template/contact-form-v2-template.component";
import { ContactInfoComponent } from "@p2p/molecules/contact-info/contact-info.component";
import { ContentStageComponent } from "@p2p/organisms/content-stage/content-stage.component";
import { ContractViewComponent } from "@p2p/molecules/contract-view/contract-view.component";
import { ContractDataMeterDataHistoryComponent } from "@p2p/organisms/client-portal/contract-data-meter-data-history/contract-data-meter-data-history.component";
import { CtaButtonComponent } from "@p2p/atoms/cta-button/cta-button.component";
import { DeviceSelectorComponent } from "@p2p/utils/device-selector/device-selector.component";
import { DisclaimerPageAltComponent } from "@p2p/pages/disclaimer-page/disclaimer-page-alt.component";
import { DisclaimerPageComponent } from "@p2p/pages/disclaimer-page/disclaimer-page.component";
import { FaqPageAltComponent } from "@p2p/pages/faq-page/faq-page-alt.component";
import { FaqPageComponent } from "@p2p/pages/faq-page/faq-page.component";
import { FaqSectionAltComponent } from "@p2p/organisms/faq-section/faq-section-alt.component";
import { FaqSectionComponent } from "@p2p/organisms/faq-section/faq-section.component";
import { FaqTemplateAltComponent } from "@p2p/templates/faq-template/faq-template-alt.component";
import { FaqTemplateComponent } from "@p2p/templates/faq-template/faq-template.component";
import { FooterComponent } from "@p2p/organisms/footer/footer.component";
import { ProducersMapComponent } from "@p2p/organisms/producers-map/producers-map.component";
import { CookieDisclaimerComponent } from "@p2p/molecules/cookie-disclaimer/cookie-disclaimer.component";
import { GoogleOptOutButtonComponent } from "@p2p/atoms/google-opt-out-button/google-opt-out-button.component";
import { HeaderComponent } from "@p2p/organisms/header/header.component";
import { HeadlineComponent } from "@p2p/atoms/headline/headline.component";
import { ImageComponent } from "@p2p/atoms/image/image.component";
import { ImageSliderComponent } from "@p2p/organisms/image-slider/image-slider.component";
import { ImageTeaserComponent } from "@p2p/organisms/image-teaser/image-teaser.component";
import { LandingPageComponent } from "@p2p/pages/landing-page/landing-page.component";
import { LandingTemplateComponent } from "@p2p/templates/landing-template/landing-template.component";
import { LeadPageComponent } from "@p2p/pages/lead-page/lead-page.component";
import { LeadFormComponent } from "@p2p/organisms/lead-form/lead-form.component";
import { LegalPageAltComponent } from "@p2p/pages/legal-page/legal-page-alt.component";
import { LegalPageComponent } from "@p2p/pages/legal-page/legal-page.component";
import { LoadingSpinnerComponent } from "@p2p/atoms/loading-spinner/loading-spinner.component";
import { LoginComponent } from "@p2p/organisms/login/login.component";
import { LoginPageComponent } from "@p2p/pages/login-page/login-page.component";
import { LoginTemplateComponent } from "@p2p/templates/login-template/login-template.component";
import { LogoComponent } from "@p2p/atoms/logo/logo.component";
import { MeterDataComponent } from "@p2p/organisms/client-portal/meter-data/meter-data.component";
import { OvaFormComponent } from "@p2p/organisms/ova-form/ova-form.component";
import { OvaSuccessComponent } from "@p2p/organisms/ova-success/ova-success.component";
import { OvaPageComponent } from "@p2p/pages/ova-page/ova-page.component";
import { OvaTemplateComponent } from "@p2p/templates/ova-template/ova-template.component";
import { OverlayComponent } from "@p2p/molecules/overlay/overlay.component";
import { PasswordResetComponent } from "@p2p/organisms/password-reset/password-reset.component";
import { PasswordResetSetComponent } from "@p2p/organisms/password-reset-set/password-reset-set.component";
import { PersonsViewComponent } from "@p2p/atoms/persons-view/persons-view.component";
import { PriceCalculatorComponent } from "@p2p/organisms/price-calculator/price-calculator.component";
//import {ProducerDataComponent} from "@p2p/organisms/client-portal/producer-data/producer-data.component";
import { ProducerInfoComponent } from "@p2p/organisms/producer-info/producer-info.component";
import { ProgressBarComponent } from "@p2p/molecules/progress-bar/progress-bar.component";
import { QuoteComponent } from "@p2p/organisms/quote/quote.component";
import { RegionalContentComponent } from "@p2p/organisms/regional-content/regional-content.component";
import { SubheaderComponent } from "@p2p/organisms/subheader/subheader.component";
import { SubheadlineComponent } from "@p2p/atoms/subheadline/subheadline.component";
import { TellAFriendComponent } from "@p2p/organisms/tell-a-friend/tell-a-friend.component";
import { TextboxComponent } from "@p2p/molecules/textbox/textbox.component";
import { TextParagraphComponent } from "@p2p/atoms/text-paragraph/text-paragraph.component";
import { ToggleComponent } from "@p2p/organisms/toggle/toggle.component";
import { TokenInterceptor } from "@p2p/utils/token-interceptor";
import { VideoComponent } from "@p2p/atoms/video/video.component";
import { WhiteLabelPageComponent } from "@p2p/pages/white-label-page/white-label-page.component";
import { WhiteLabelErzeugerPageComponent } from "@p2p/pages/white-label-erzeuger-page/white-label-erzeuger-page.component";
import { WhiteLabelTemplateComponent } from "@p2p/templates/white-label-template/white-label-template.component";
import { WhiteLabelErzeugerTemplateComponent } from "@p2p/templates/white-label-erzeuger-template/white-label-erzeuger-template.component";
import { ZipBoxComponent } from "@p2p/molecules/zip-box/zip-box.component";
import { CurrencyService } from "@p2p/services/currency.service";
import { DeviceSelectorService } from "@p2p/utils/device-selector/device-selector.service";
import { OnlyNumbersDirective } from "@p2p/directives/only-numbers.directive";
import { WindowRef } from "@p2p/utils/window-ref";
import { SeoService } from "@p2p/services/seo.service";
import { ServiceWorkerModule } from "@angular/service-worker";

// Third party
import { APP_CONFIG } from "@p2p/config/app.config";

import { CookieService } from "ngx-cookie-service";
import { DeferLoadModule } from "@trademe/ng-defer-load";
import { CustomerDataComponent } from "@p2p/organisms/client-portal/customer-data/customer-data.component";
import { ContractDataComponent } from "@p2p/organisms/client-portal/contract-data/contract-data.component";
import { ContractDataGeneralDataComponent } from "@p2p/organisms/client-portal/contract-data-general-data/contract-data-general-data.component";
import { ContractDataInvoiceDataComponent } from "@p2p/organisms/client-portal/contract-data-invoice-data/contract-data-invoice-data.component";
import { ContractDataMeterDataComponent } from "@p2p/organisms/client-portal/contract-data-meter-data/contract-data-meter-data.component";
import { LeadConfirmPageComponent } from "@p2p/pages/lead-confirm-page/lead-confirm-page.component";
import { RedesignComponent } from "./redesign.component";

registerLocaleData(localeDe, "de");

// Sentry with this project's specific config.
Sentry.init({
	dsn: "https://733b2041f663402e9dd0e29693527277@sentry.eon-cds.de/29"
});

// Sentry
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
	handleError(error) {
		Sentry.captureException(error.originalError || error);
		throw error;
	}
}

// Capture only errors in prod environment.
export function provideErrorHandler(platformId: object) {
	if (environment.production && isPlatformBrowser(platformId)) {
		return new SentryErrorHandler();
	} else {
		return new ErrorHandler();
	}
}

@NgModule({
	declarations: [
		AgbBagComponent,
		AgbPageComponent,
		AppComponent,
		CallToActionComponent,
		CardComponent,
		ContractDataComponent,
		ContractDataGeneralDataComponent,
		ContractDataInvoiceDataComponent,
		ContractDataMeterDataComponent,
		ContractDataMeterDataHistoryComponent,
		ClientPortalPageComponent,
		ClientPortalTemplateComponent,
		CookieDisclaimerComponent,
		ContactFormComponent,
		ContactFormPageComponent,
		ContactFormTemplateComponent,
		ContactFormV2AltComponent,
		ContactFormV2Component,
		ContactFormV2PageAltComponent,
		ContactFormV2PageComponent,
		ContactFormV2TemplateAltComponent,
		ContactFormV2TemplateComponent,
		ContactInfoComponent,
		ContentStageComponent,
		ContractViewComponent,
		CtaLinkComponent,
		CtaButtonComponent,
		CustomerDataComponent,
		DeviceSelectorComponent,
		DisclaimerPageAltComponent,
		DisclaimerPageComponent,
		FaqPageAltComponent,
		FaqPageComponent,
		FaqSectionAltComponent,
		FaqSectionComponent,
		FaqTemplateAltComponent,
		FaqTemplateComponent,
		FooterComponent,
		ProducersMapComponent,
		GoogleOptOutButtonComponent,
		HeaderComponent,
		HeadlineComponent,
		ImageComponent,
		ImageSliderComponent,
		ImageTeaserComponent,
		LandingPageComponent,
		LandingTemplateComponent,
		LeadPageComponent,
		LeadConfirmPageComponent,
		LeadFormComponent,
		LegalPageAltComponent,
		LegalPageComponent,
		LoadingSpinnerComponent,
		LoginComponent,
		LoginPageComponent,
		LoginTemplateComponent,
		LogoComponent,
		MeterDataComponent,
		OvaFormComponent,
		OvaSuccessComponent,
		OnlyNumbersDirective,
		OvaPageComponent,
		OvaTemplateComponent,
		OverlayComponent,
		PasswordResetComponent,
		PasswordResetSetComponent,
		PersonsViewComponent,
		PriceCalculatorComponent,
		//ProducerDataComponent,
		ProducerInfoComponent,
		ProgressBarComponent,
		QuoteComponent,
		RegionalContentComponent,
		SubheaderComponent,
		SubheadlineComponent,
		TellAFriendComponent,
		TextboxComponent,
		TextParagraphComponent,
		ToggleComponent,
		VideoComponent,
		WhiteLabelPageComponent,
		WhiteLabelErzeugerPageComponent,
		WhiteLabelTemplateComponent,
		WhiteLabelErzeugerTemplateComponent,
		ZipBoxComponent,
		RedesignComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: "rsm" }),
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		TransferHttpCacheModule,
		DeferLoadModule,
		ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production ? true : false })
	],
	providers: [
		AuthGuard,
		CurrencyService,
		DeviceSelectorService,
		Title,
		WindowRef,
		CookieService,
		SeoService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: LOCALE_ID,
			useValue: "de"
		},
		{
			provide: APP_CONFIG,
			useValue: environment.config
		},
		{
			provide: ErrorHandler,
			useFactory: provideErrorHandler,
			deps: [PLATFORM_ID]
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
