var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { RegExpConstants } from "@p2p/constants/constants";
import { DataApi } from "@rem/data";
import { ContactApi } from "@rem/contact";
import { Region } from "@rem/region";
import { CustomerApi } from "@rem/customer";
import { IamService } from "@rem/iam";
var ContactFormV2Component = /** @class */ (function () {
    function ContactFormV2Component(router, activatedRoute) {
        var _this = this;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.showTemplateGlobal = false;
        this.showTemplateEditorial = false;
        this.contactCreated = false;
        this.contactError = false;
        this.fromCsc = false;
        this.isWaiting = false;
        this.contactForm = new FormGroup({
            salutation: new FormControl("", Validators.required),
            firstname: new FormControl("", [Validators.required, Validators.pattern(RegExpConstants.SIMPLE_TEXT_REGEXP)]),
            lastname: new FormControl("", [Validators.required, Validators.pattern(RegExpConstants.SIMPLE_TEXT_REGEXP)]),
            company: new FormControl("", []),
            message: new FormControl("", [Validators.required]),
            phone: new FormControl("", [Validators.pattern(RegExpConstants.PHONE_NUMBER_REGEXP)]),
            email: new FormControl("", [Validators.required, Validators.pattern(RegExpConstants.EMAIL_REGEXP)]),
            topic: new FormControl("", [Validators.required]),
            tosAccepted: new FormControl("", [Validators.requiredTrue])
        });
        this.activatedRoute.queryParams.subscribe(function (evt) {
            if (evt.fromCsc) {
                _this.fromCsc = true;
            }
        });
    }
    ContactFormV2Component.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, customer, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, DataApi.getGlobalData()];
                    case 1:
                        _a.globalData = _d.sent();
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 5, , 6]);
                        if (!IamService.isLoggedIn()) return [3 /*break*/, 4];
                        return [4 /*yield*/, CustomerApi.get(false)];
                    case 3:
                        customer = _d.sent();
                        this.contactForm.patchValue({
                            // TODO(Mark): Inconsistent validation for firstname, lastname, (no number allowed here, e.g. "Peterchen 3")
                            // TODO(Mark): Inconsistent phone number, sometimes 1 field, sometimes 2
                            salutation: customer.salutation,
                            firstname: customer.firstname,
                            lastname: customer.lastname,
                            email: customer.emailPrivate,
                            phone: customer.phone
                        });
                        _d.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        _b = _d.sent();
                        return [3 /*break*/, 6];
                    case 6:
                        this.formData = this.globalData.ovaForm;
                        this.errorMessages = this.globalData.errorMessages;
                        this.showTemplateGlobal = true;
                        _c = this;
                        return [4 /*yield*/, DataApi.getRegionData(this.whitelabel)];
                    case 7:
                        _c.editorialData = _d.sent();
                        this.showTemplateEditorial = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactFormV2Component.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isWaiting = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, ContactApi.post(__assign({ region: Region.getCurrent() }, this.contactForm.value))];
                    case 2:
                        _a.sent();
                        this.contactCreated = true;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.contactError = true;
                        return [3 /*break*/, 4];
                    case 4:
                        this.isWaiting = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactFormV2Component.prototype.navigateBack = function () {
        if (this.fromCsc) {
            this.router.navigateByUrl("/" + this.whitelabel + "/kundenportal?section=meine-ansprechpartner");
        }
        else {
            this.router.navigateByUrl("/" + this.whitelabel);
        }
    };
    return ContactFormV2Component;
}());
export { ContactFormV2Component };
