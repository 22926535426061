import { Http } from '@rem/http';

import { API_ROOT_URL } from './env';
import { IamService } from './iam';

export type Consumptions = {
	previousYearConsumption: {
		year: number;
		slpProfile: string;
		distanceBetweenValues: string;
		consumptionFactor: number;
		valuesMap: {
			date: string;
			value: number;
		}[];
	};
	consideredReadingDates: string[];
	contractStart: string;
	currentYearConsumption: {
		year: number;
		slpProfile: string;
		currentYearGranularity: string;
		dateFirstValue: string;
		averageDailyValuesPerGranularity: number[];
	};
};

// See: https://confluence.dev.eon.com/display/P2P/Error-Handling
export type ConsumptionError =
	| "ERROR"
	| "CONSUMPTION_NO_VALID_CONTRACTSTATUS"
	| "CONSUMPTION_CONTRACT_ENDED_BEFORE_THIS_YEAR";

export class ConsumptionsApi {
	static async get(
		contractId: string,
		currentYearGranularity: string = "MONTH",
		previousYearValueDistance: string = "MONTH"
	): Promise<Consumptions | ConsumptionError> {
		try {
			let res = await fetch(
				`${API_ROOT_URL}/api/csc/consumptions` +
					`?contractId=${contractId}` +
					`&currentYearGranularity=${currentYearGranularity}` +
					`&previousYearValueDistance=${previousYearValueDistance}`,
				{
					headers: {
						Accept: "application/vnd.api+json",
						Authorization: `Bearer ${IamService.getToken()}`
					}
				}
			);

			if (res.status === Http.UNAUTHORIZED) {
				IamService.logout();
				return "ERROR";
			}

			let resBody: ResBody = await res.json();
			if (res.status !== Http.OKAY) {
				let code = resBody.errors[0].code;
				// We expect to get a ReadingError here, but it could be any other kind of API error.
				// For example, submitting a reading with a future date will return 400 "GENERAL_FIELD_CONTENT".
				// Thus, all cases that we have not explicitly covered, will return the generic "ERROR";
				switch (code as ConsumptionError) {
					case "CONSUMPTION_CONTRACT_ENDED_BEFORE_THIS_YEAR":
					case "CONSUMPTION_NO_VALID_CONTRACTSTATUS":
						return code as ConsumptionError;
					default:
						console.error(res);
						return "ERROR";
				}
			}

			return resBody.data.attributes;
		} catch (err) {
			console.error(err);
			return "ERROR"; // Network error, timeout, or coding error in try-block
		}
	}
}

type ResBody = {
	errors?: any[];
	data: {
		id: string;
		type: "consumptions";
		attributes: Consumptions;
	};
};
