import {Component, Input} from "@angular/core";

@Component({
    selector: "ato-loading-spinner",
    templateUrl: "./loading-spinner.component.html",
    styleUrls: ["./loading-spinner.component.scss"],
})

export class LoadingSpinnerComponent {
    @Input() public loadingReady = false;
    @Input() public isRelative = false;
}
