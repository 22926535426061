import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {DataApi} from "@rem/data";

// import { GoogleOptOutButtonComponent } from "../../atoms/google-opt-out-button/google-opt-out-button.component";
@Component({
    selector: "pag-legal-alt",
    templateUrl: "./legal-page-alt.component.html",
    styleUrls: ["./legal-page-alt.component.scss"],
})
export class LegalPageAltComponent implements OnInit, OnDestroy {
    public showTemplate = true;
    public editorialData: any;
    public whitelabel: string;

    private routeParams_: Subscription;

    constructor(
        private route: ActivatedRoute,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.routeParams_ = this.route.params.subscribe(async params => {
            this.whitelabel = params["region"];

            this.editorialData = await DataApi.getRegionData(this.whitelabel);
            this.showTemplate = true;
        });
    }

    ngOnDestroy(): void {
    }
}
