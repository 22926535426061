import { RegionName } from "./region";

interface PhoneNumber {
	value: string;
	displayValue: string;
}

interface CustomerService {
	email: string;
	supportNumber: PhoneNumber;
	contactNumber: PhoneNumber[];
	openingHours: Array<[string, string]>;
}

// See: https://confluence.dev.eon.com/display/P2P/Active+Regions

const regionMap: { [r in RegionName]: CustomerService } = {
	oberguenzburg: {
		email: "%EMAIL%",
		supportNumber: { value: "%SUPPORT_TELEFONNUMMER%", displayValue: "%SUPPORT_TELEFONNUMMER%" },
		contactNumber: [
			{ value: "%KONTAKT_TELEFONNUMMER%", displayValue: "%KONTAKT_TELEFONNUMMER%" },
		],
		openingHours: [
			["%ÖFFNUNGSZEITEN%", "%ÖFFNUNGSZEITEN%"],
		]
	},
	/*
	abensberg: {
		email: "info@naturstrom-abensberg.de",
		supportNumber: { value: "+499443910355", displayValue: "09443/910355" },
		contactNumber: [
			{ value: "+4994439103420", displayValue: "09443/9103420" },
			{ value: "+4994439103422", displayValue: "09443/9103422" }
		],
		openingHours: [
			["Mo-Mi", "08:00 - 15:00"],
			["Do", "08:00 - 17:30"],
			["Fr", "08:00 - 12:00"]
		]
	},
	bemo: {
		email: "bemo@bayernwerk-regio-energie.de",
		supportNumber: { value: "+499212855801", displayValue: "0921/2855801" },
		contactNumber: [{ value: "+49943324070", displayValue: "09433/24070" }],
		openingHours: [["Mo-Fr", "07:30 - 17:00"]]
	},
	"calenberger-land": {
		email: "kundenservice@strombewegung.de",
		supportNumber: { value: "+4951198449309", displayValue: "0511/98449309" },
		contactNumber: [{ value: "+4951198449309", displayValue: "0511/98449309" }],
		openingHours: [["Mo-Fr", "08:00 - 17:00"]]
	},
	"landkreis-bamberg": {
		email: "info@regionalwerke-bamberg.de",
		supportNumber: { value: "+4995151936769", displayValue: "0951/51936769" },
		contactNumber: [{ value: "+4995151936766", displayValue: "0951/51936766" }],
		openingHours: [
			["Mo-Mi", "08:00 - 16:00"],
			["Do", "08:00 - 17:00"],
			["Fr", "08:00 - 12:00"]
		]
	},
	pfettrachtal: {
		email: "pfettrachstrom@bayernwerk-regio-energie.de",
		supportNumber: { value: "+498704911950", displayValue: "08704/911950" },
		contactNumber: [{ value: "+49870491190", displayValue: "08704/9119-0" }],
		openingHours: [
			["Mo-Fr", "08:00 - 12:00"],
			["Do", "13:30 - 18:00"]
		]
	},
	marienbachtal: {
		email: "marienbachtal@bayernwerk-regio-energie.de",
		supportNumber: { value: "+499725712456", displayValue: "09725/712456" },
		contactNumber: [{ value: "+49972571240", displayValue: "09725 / 7124-0" }],
		openingHours: [
			["Mo, Mi, Fr", "08:00 - 12:00"],
			["Di", "14:00 - 16:00"],
			["Do", "14:00 - 18:00"]
		]
	},
	viechtach: {
		email: "viechtach@bayernwerk-regio-energie.de",
		supportNumber: { value: "+499942808113", displayValue: "09942 / 808-113" },
		contactNumber: [{ value: "+4909942808111", displayValue: "09942 / 808-111" }],
		openingHours: [
			["Mo-Fr", "08:00 - 12:00"],
			["Mo, Di, Do", "14:00 - 16:00"]
		],
	},
	*/
};

const dayMap = { So: 0, Mo: 1, Di: 2, Mi: 3, Do: 4, Fr: 5, Sa: 6 };

// "Do" -> [4]
// "Di-Do" -> [2, 3, 4]
// "Di, Do" -> [2, 4]
function parseDays(days: string): number[] {
	if (days.includes("-")) {
		let split = days.split("-");
		let [first, last] = split;
		return range(dayMap[first], dayMap[last]);
	}
	if (days.includes(", ")) {
		let split = days.split(", ");
		return split.map(d => dayMap[d]);
	}
	return [dayMap[days]];
}

// 2, 4 -> [2, 3, 4]
function range(low: number, high: number): number[] {
	let r = new Array();
	for (let i = low; i <= high; i++) r.push(i);
	return r;
}

// "07:30 - 18:00" -> [[7, 30], [18, 0]]
function parseTime(time: string): number[][] {
	return time.split(" - ").map(t => t.split(":").map(i => parseInt(i)));
}

export function CustomerService(region: RegionName) {
	let customerService = regionMap[region];

	let parsedOpeningHours = customerService.openingHours.map(([days, time]) => ({
		days: parseDays(days),
		time: parseTime(time)
	}));

	let isOpen = (now = new Date()) => {
		let day = now.getDay();
		for (let openingHour of parsedOpeningHours) {
			if (openingHour.days.includes(day)) {
				let [start, end] = openingHour.time;

				let startDate = new Date(now.getTime());
				startDate.setHours(start[0]);
				startDate.setMinutes(start[1]);

				let endDate = new Date(now.getTime());
				endDate.setHours(end[0]);
				endDate.setMinutes(end[1]);

				if (now >= startDate && now <= endDate) {
					return true;
				}
			}
		}
		return false;
	};

	return { ...customerService, isOpen };
}
