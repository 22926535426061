import * as Sentry from '@sentry/browser';
import { API_ROOT_URL } from "./env";
import { Http } from "./http";

export type CityWithStreets = City & {
	streets: string[];
};

export interface City {
	id: string,
	zipCode: string,
	name: string
}

export class CityApi {
	static async getAll(zipCode: string): Promise<City[]> {
		const res = await fetch(`${API_ROOT_URL}/api/ova/cities?zipcode=${zipCode}`, {
			headers: { Accept: "application/vnd.api+json" }
		});
		if (res.status !== Http.OKAY) {
			// TODO(Mark): Provide more specific error handling
			Sentry.configureScope(scope => {
				scope.setTag("api", `${API_ROOT_URL}/api/ova/cities?zipcode=${zipCode}`);
				scope.setExtra("API_FAILURE", "CityApi::getAll");
			});
			throw new Error(`API_FAILURE (getAll): /api/ova/cities?zipcode=${zipCode}`);
		}
		const body = await res.json();
		return body.data.map(c => ({
			id: c.id,
			zipCode: c.attributes.zipcode,
			name: c.attributes.name
		}));
	}

	static async getOne(id: string): Promise<CityWithStreets> {
		const res = await fetch(`${API_ROOT_URL}/api/ova/cities/${id}`, {
			headers: { Accept: "application/vnd.api+json" }
		});
		if (res.status !== Http.OKAY) {
			// TODO(Mark): Provide more specific error handling
			Sentry.configureScope(scope => {
				scope.setTag("api", `${API_ROOT_URL}/api/ova/cities/${id}`);
				scope.setExtra("API_FAILURE", "CityApi::getOne");
			});
			throw new Error(`API_FAILURE (getOne): /api/ova/cities/${id}`);
		}
		const body = await res.json();
		return {
			id: body.data.id,
			zipCode: body.data.attributes.zipcode,
			name: body.data.attributes.name,
			streets: body.data.attributes.streets
		};
	}
}
