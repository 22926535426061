import { isPlatformBrowser } from "@angular/common";
var WindowRef = /** @class */ (function () {
    function WindowRef(platformId) {
        this.platformId = platformId;
        this.isBrowser = isPlatformBrowser(this.platformId);
    }
    Object.defineProperty(WindowRef.prototype, "nativeWindow", {
        get: function () {
            return this._window();
        },
        enumerable: true,
        configurable: true
    });
    WindowRef.prototype._window = function () {
        // return the global native browser window object
        return this.isBrowser ? window : undefined;
    };
    return WindowRef;
}());
export { WindowRef };
