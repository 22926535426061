/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq-template.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../organisms/faq-section/faq-section.component.ngfactory";
import * as i3 from "../../organisms/faq-section/faq-section.component";
import * as i4 from "@angular/router";
import * as i5 from "../../organisms/header/header.component.ngfactory";
import * as i6 from "../../organisms/header/header.component";
import * as i7 from "@angular/common";
import * as i8 from "../../organisms/footer/footer.component.ngfactory";
import * as i9 from "../../organisms/footer/footer.component";
import * as i10 from "./faq-template.component";
var styles_FaqTemplateComponent = [i0.styles];
var RenderType_FaqTemplateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqTemplateComponent, data: {} });
export { RenderType_FaqTemplateComponent as RenderType_FaqTemplateComponent };
function View_FaqTemplateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-faq-section", [], null, null, null, i2.View_FaqSectionComponent_0, i2.RenderType_FaqSectionComponent)), i1.ɵdid(1, 114688, null, 0, i3.FaqSectionComponent, [i4.Router], { faqSection: [0, "faqSection"], fragment: [1, "fragment"], whitelabel: [2, "whitelabel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.fragment; var currVal_2 = _co.whitelabel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_FaqTemplateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "main", [["class", "faqTemplate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "faqTemplate__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "org-header", [], null, null, null, i5.View_HeaderComponent_0, i5.RenderType_HeaderComponent)), i1.ɵdid(3, 49152, null, 0, i6.HeaderComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "faqTemplate__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "faqTemplate__wrapper__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [["class", "faqTemplate__wrapper__hl"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "p", [["class", "faqTemplate__wrapper__text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqTemplateComponent_2)), i1.ɵdid(10, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faqSections.sections; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.globalData.faqPage.headline; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.globalData.faqPage.text; _ck(_v, 8, 0, currVal_1); }); }
export function View_FaqTemplateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqTemplateComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "footer", [["class", "faqTemplate__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "org-footer", [], null, null, null, i8.View_FooterComponent_0, i8.RenderType_FooterComponent)), i1.ɵdid(4, 49152, null, 0, i9.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTemplateGlobal; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FaqTemplateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tpl-faq", [], null, null, null, View_FaqTemplateComponent_0, RenderType_FaqTemplateComponent)), i1.ɵdid(1, 245760, null, 0, i10.FaqTemplateComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FaqTemplateComponentNgFactory = i1.ɵccf("tpl-faq", i10.FaqTemplateComponent, View_FaqTemplateComponent_Host_0, { globalData: "globalData", whitelabel: "whitelabel" }, {}, []);
export { FaqTemplateComponentNgFactory as FaqTemplateComponentNgFactory };
