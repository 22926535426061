/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ova-template.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../organisms/ova-form/ova-form.component.ngfactory";
import * as i3 from "../../organisms/ova-form/ova-form.component";
import * as i4 from "@angular/router";
import * as i5 from "../../organisms/ova-success/ova-success.component.ngfactory";
import * as i6 from "../../organisms/ova-success/ova-success.component";
import * as i7 from "../../organisms/header/header.component.ngfactory";
import * as i8 from "../../organisms/header/header.component";
import * as i9 from "@angular/common";
import * as i10 from "../../molecules/contact-info/contact-info.component.ngfactory";
import * as i11 from "../../molecules/contact-info/contact-info.component";
import * as i12 from "../../organisms/footer/footer.component.ngfactory";
import * as i13 from "../../organisms/footer/footer.component";
import * as i14 from "./ova-template.component";
var styles_OvaTemplateComponent = [i0.styles];
var RenderType_OvaTemplateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OvaTemplateComponent, data: {} });
export { RenderType_OvaTemplateComponent as RenderType_OvaTemplateComponent };
function View_OvaTemplateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "whitelabelTemplate__section whitelabelTemplate__ova"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "org-ova-form", [], null, null, null, i2.View_OvaFormComponent_0, i2.RenderType_OvaFormComponent)), i1.ɵdid(2, 245760, null, 0, i3.OvaFormComponent, [i4.Router, i4.ActivatedRoute], { globalData: [0, "globalData"], editorialData: [1, "editorialData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.globalData; var currVal_1 = _co.editorialData; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_OvaTemplateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "whiteLabelTemplate__section whiteLabelTemplate__ova-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "org-ova-success", [], null, null, null, i5.View_OvaSuccessComponent_0, i5.RenderType_OvaSuccessComponent)), i1.ɵdid(2, 49152, null, 0, i6.OvaSuccessComponent, [i4.Router], { editorialData: [0, "editorialData"], whiteLabel: [1, "whiteLabel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editorialData; var currVal_1 = _co.whitelabel; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_OvaTemplateComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "whiteLabelTemplate__section whiteLabelTemplate__ova-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "org-ova-success", [["noconfirmation", "true"]], null, null, null, i5.View_OvaSuccessComponent_0, i5.RenderType_OvaSuccessComponent)), i1.ɵdid(2, 49152, null, 0, i6.OvaSuccessComponent, [i4.Router], { editorialData: [0, "editorialData"], whiteLabel: [1, "whiteLabel"], noconfirmation: [2, "noconfirmation"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editorialData; var currVal_1 = _co.whitelabel; var currVal_2 = "true"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_OvaTemplateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "main", [["class", "whitelabelTemplate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "whitelabelTemplate__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "org-header", [], null, null, null, i7.View_HeaderComponent_0, i7.RenderType_HeaderComponent)), i1.ɵdid(3, 49152, null, 0, i8.HeaderComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OvaTemplateComponent_2)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OvaTemplateComponent_3)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OvaTemplateComponent_4)), i1.ɵdid(9, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "section", [["class", "whiteLabelTemplate__section whiteLabelTemplate__client-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "mol-contact-info", [], null, null, null, i10.View_ContactInfoComponent_0, i10.RenderType_ContactInfoComponent)), i1.ɵdid(12, 49152, null, 0, i11.ContactInfoComponent, [], { contactInfoData: [0, "contactInfoData"], whitelabel: [1, "whitelabel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.fragment !== "erfolgreichem-abschluss"); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.fragment === "erfolgreichem-abschluss"); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.fragment === "erfolgreichem-abschluss-no-confirmation"); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.editorialData.ovaPage.contact; var currVal_4 = _co.whitelabel; _ck(_v, 12, 0, currVal_3, currVal_4); }, null); }
export function View_OvaTemplateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OvaTemplateComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "footer", [["class", "whitelabelTemplate__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "org-footer", [], null, null, null, i12.View_FooterComponent_0, i12.RenderType_FooterComponent)), i1.ɵdid(4, 49152, null, 0, i13.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTemplate; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OvaTemplateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tpl-ova", [], null, null, null, View_OvaTemplateComponent_0, RenderType_OvaTemplateComponent)), i1.ɵdid(1, 245760, null, 0, i14.OvaTemplateComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OvaTemplateComponentNgFactory = i1.ɵccf("tpl-ova", i14.OvaTemplateComponent, View_OvaTemplateComponent_Host_0, { globalData: "globalData", editorialData: "editorialData", whitelabel: "whitelabel" }, {}, []);
export { OvaTemplateComponentNgFactory as OvaTemplateComponentNgFactory };
