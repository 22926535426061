import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RegExpConstants } from "@p2p/constants/constants";
import { Customer, CustomerApi } from "@rem/customer";
import { IamApi, IamService } from "@rem/iam";
import { Region } from "@rem/region";

@Component({
	selector: "org-customer-data",
	templateUrl: "./customer-data.component.html",
	styleUrls: ["./customer-data.component.scss"]
})
export class CustomerDataComponent implements OnInit {
	@Input() public whitelabel: string;
	@Input() public errorMessages: any;
	@Input() public globalData: any;

	public isLoaded = false;
	public customer: Customer = {} as Customer;
	public editMode = false;
	public editModeEmail = false;
	public formSuccess = false;
	public formError = false;
	public emailChangeInProgress: boolean = false;
	copyToClipboardStatus: "default" | "success" | "error" = "default";
	copyToClipboardResetTimeout: number | undefined;

	errorSubHeadlineDefault = "Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut";
	errorSubHeadline = this.errorSubHeadlineDefault;

	public resetErrorSubHeadline() {
		this.errorSubHeadline = this.errorSubHeadlineDefault;
	}

	public salutationList = {
		UNKNOWN: "---",
		MISS: "Frau",
		MISTER: "Herr"
	};

	public customerDataForm: FormGroup = new FormGroup({
		rem_title: new FormControl({ value: "", disabled: true }),
		phone: new FormControl({ value: "", disabled: true }, [
			Validators.required,
			Validators.pattern(RegExpConstants.PHONE_NUMBER_REGEXP)
		])
	});

	public emailForm: FormGroup = new FormGroup({
		email: new FormControl({ value: "", disabled: true }, [
			Validators.required,
			Validators.pattern(RegExpConstants.EMAIL_OVA_REGEXP)
		])
	});

	public passwordDataForm: FormGroup = new FormGroup({
		oldPassword: new FormControl({ value: "", disabled: false }, []),
		password: new FormControl({ value: "", disabled: false }, [Validators.pattern(RegExpConstants.PASSWORD_REGEXP)]),
		passwordRepeat: new FormControl({ value: "", disabled: false }, [])
	});

	public adDataForm: FormGroup = new FormGroup({
		mailAds: new FormControl({ value: "", disabled: false }),
		emailAds: new FormControl({ value: "", disabled: false }),
		phoneAds: new FormControl({ value: "", disabled: false })
	});

	async ngOnInit(): Promise<void> {
		this.customer = await CustomerApi.get();
		this.customer.personalRecommendationCode = this.customer.personalRecommendationCode.toUpperCase();
		this.customer.dateOfBirth = (this.customer.dateOfBirth || "").replace(/(\d+)-(\d+)-(\d+)/g, "$3.$2.$1");
		this.customerDataForm.setValue({
			rem_title: this.customer.title || "",
			phone: this.customer.phone || ""
		});

		this.resetEmailForm();

		this.adDataForm.setValue({
			mailAds: this.customer.adsPost || false,
			emailAds: this.customer.adsMail || false,
			phoneAds: this.customer.adsPhone || false
		});

		this.isLoaded = true;
	}

	private setEditMode(mode: boolean = true) {
		for (const control in this.customerDataForm.controls) {
			if (mode) {
				this.customerDataForm.get(control).enable();
			} else {
				this.customerDataForm.get(control).disable();
			}
		}
		this.editMode = mode;
	}

	private toggleEmailEditMode(enable = true) {
		for (const control in this.emailForm.controls) {
			if (enable) {
				this.emailForm.get(control).enable();
			} else {
				this.emailForm.get(control).disable();
			}
		}
		this.editModeEmail = enable;
	}

	// Customer data
	editCustomerData(): void {
		this.setEditMode(true);
	}

	// Emailadress
	editEmail(): void {
		this.toggleEmailEditMode(true);
	}

	async saveCustomerData(): Promise<void> {
		try {
			const controls = this.customerDataForm;
			const res = await CustomerApi.patch(this.customer.id, {
				phone: controls.get("phone").value,
				title: controls.get("rem_title").value
			});
			this.formSuccess = true;
		} catch (error) {
			this.formSuccess = false;
			this.formError = true;
		} finally {
			this.setEditMode(false);
		}
	}

	emailSaveButtonEnabled() {
		if (this.emailChangeInProgress) {
			return false;
		}

		if (this.customer.emailPrivate == this.emailForm.controls.email.value) {
			return false;
		}

		if (!this.emailForm.controls.email.valid) {
			return false;
		}

		return true;
	}

	async saveEmail(): Promise<void> {
		try {
			this.emailChangeInProgress = true;
			const controls = this.emailForm;
			await IamApi.changeUserId(controls.get("email").value);
			this.customer.emailPrivate = controls.get("email").value;
			this.formSuccess = true;
		} catch (e) {
			let error: Error = e;
			this.formSuccess = false;
			this.formError = true;
			this.errorSubHeadline = error.message;
			this.resetEmailForm();
		} finally {
			this.emailChangeInProgress = false;
			this.toggleEmailEditMode(false);
		}
	}

	resetEmailForm() {
		this.emailForm.setValue({
			email: this.customer.emailPrivate
		});
	}

	cancelCustomerData(): void {
		this.customerDataForm.setValue({
			rem_title: this.customer.title || "",
			phone: this.customer.phone || ""
		});

		this.customerDataForm.disable();
		this.setEditMode(false);
	}

	cancelEmail(): void {
		this.emailForm.setValue({
			email: this.customer.emailPrivate || ""
		});

		this.emailForm.disable();
		this.toggleEmailEditMode(false);
	}

	validateCustomerData(): boolean {
		const controls = Object.keys(this.customerDataForm.controls).map(controlName =>
			this.customerDataForm.get(controlName)
		);
		const isAnyDirty = controls.some(control => control.dirty);
		const isAnyInvalid = controls.some(control => control.invalid);
		return isAnyDirty && !isAnyInvalid;
	}

	// Password
	validatePassword(): boolean {
		const controls = Object.keys(this.passwordDataForm.controls).map(controlName =>
			this.passwordDataForm.get(controlName)
		);
		const isAnyDirty = controls.some(control => control.dirty);
		const isAnyInvalid = controls.some(control => control.invalid);
		const passwordsAreEqual =
			this.passwordDataForm.get("password").value === this.passwordDataForm.get("passwordRepeat").value;
		const passwordsHaveLength =
			this.passwordDataForm.get("password").value.length > 0 &&
			this.passwordDataForm.get("passwordRepeat").value.length > 0 &&
			this.passwordDataForm.get("oldPassword").value.length > 0;
		return isAnyDirty && !isAnyInvalid && passwordsAreEqual && passwordsHaveLength;
	}

	async savePassword(): Promise<void> {
		try {
			const controls = this.passwordDataForm;

			const { token } = await IamApi.changePassword({
				oldPassword: controls.get("oldPassword").value,
				newPassword: controls.get("password").value
			});

			IamService.login(token);

			this.formSuccess = true;
		} catch (error) {
			this.formSuccess = false;
			this.formError = true;
		} finally {
			this.setEditMode(false);
		}
	}

	// Ads
	async saveAds(): Promise<void> {
		try {
			const controls = this.adDataForm;
			await CustomerApi.patch(this.customer.id, {
				adsPhone: controls.get("phoneAds").value,
				adsMail: controls.get("emailAds").value,
				adsPost: controls.get("mailAds").value
			});
			this.formSuccess = true;
		} catch (error) {
			this.formSuccess = false;
			this.formError = true;
		} finally {
			this.setEditMode(false);
		}
	}

	async copyPersonalRecommendationCodeToClipboard() {
		try {
			if ("clipboard" in navigator && "writeText" in navigator.clipboard) {
				await navigator.clipboard.writeText(this.customer.personalRecommendationCode);
			} else {
				let input = document.getElementById("personalRecommendationCode") as HTMLInputElement;
				input.select();
				input.setSelectionRange(0, 10);
				document.execCommand("copy");
			}
			this.copyToClipboardStatus = "success";
		} catch (err) {
			this.copyToClipboardStatus = "error";
		} finally {
			const reset = () => {
				this.copyToClipboardStatus = "default";
				this.copyToClipboardResetTimeout = undefined;
			};
			if (this.copyToClipboardResetTimeout === undefined) {
				this.copyToClipboardResetTimeout = (setTimeout(reset, 10000) as any) as number;
			} else {
				clearTimeout(this.copyToClipboardResetTimeout);
				this.copyToClipboardResetTimeout = (setTimeout(reset, 10000) as any) as number;
			}
		}
	}

	recommendationLink() {
		return `${location.protocol}//${location.host}/${Region.getCurrent()}?code=${this.customer.personalRecommendationCode}`;
	}

	mailRecommendationLink() {
		// TODO: Let's update these texts
		let subject = `Strom aus deiner Region`;
		let body = `Hallo! Kennst du schon unseren Regionalen Strommarkt? Hier bekommst du Ökostrom aus unserer Region zu fairen Preisen. Ich bin auch bereits dabei. Klick einfach auf den Link und mach mit! ${this.recommendationLink()}`;
		return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
	}

	whatsAppRecommendationLink() {
		// TODO: Let's update these texts
		let text = `Hallo! Kennst du schon unseren Regionalen Strommarkt? Hier bekommst du Ökostrom aus unserer Region zu fairen Preisen. Ich bin auch bereits dabei. Klick einfach auf den Link und mach mit! ${this.recommendationLink()}`;
		return `whatsapp://send?text=${encodeURIComponent(text)}`;
	}
}
