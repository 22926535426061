var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as moment from 'moment';
import { OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContractApi } from '@rem/contract';
import { METER_READ_ORIGIN, METER_READ_QUALITY, METER_READ_TYPE } from '@rem/enum-mapping';
import { ReadingApi } from '@rem/reading';
var ContractDataMeterDataComponent = /** @class */ (function () {
    function ContractDataMeterDataComponent() {
        this.formValidationError = null;
        this.isLoaded = false;
        this.isSubmitting = false;
        this.METER_READ_TYPE = METER_READ_TYPE;
        this.METER_READ_ORIGIN = METER_READ_ORIGIN;
        this.METER_READ_QUALITY = METER_READ_QUALITY;
    }
    ContractDataMeterDataComponent.prototype.loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _i, _b, register;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.isSubmitting = false;
                        this.isLoaded = false;
                        _a = this;
                        return [4 /*yield*/, ContractApi.get(this.contractId)];
                    case 1:
                        _a.contract = _c.sent();
                        this.readingDataForm = new FormGroup({
                            readingDate: new FormControl({ value: "", disabled: false }, [
                                Validators.required,
                                this.existingDateValidator.bind(this),
                                this.notInTheFutureValidator.bind(this)
                            ])
                        });
                        for (_i = 0, _b = this.contract.device.registers; _i < _b.length; _i++) {
                            register = _b[_i];
                            this.readingDataForm.addControl("readingValue" + register.description, new FormControl({ value: "", disabled: false }, [this.readingValueValidator]));
                        }
                        this.isLoaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractDataMeterDataComponent.prototype.ngOnChanges = function (changes) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractDataMeterDataComponent.prototype.formItemInvalid = function (form, item) {
        return (form.get(item).touched || form.get(item).dirty) && form.get(item).invalid;
    };
    ContractDataMeterDataComponent.prototype.validateReadingData = function () {
        var _this = this;
        var controls = Object.keys(this.readingDataForm.controls).map(function (controlName) { return _this.readingDataForm.get(controlName); });
        var isAnyInvalid = controls.some(function (control) { return control.invalid; });
        return !isAnyInvalid;
    };
    ContractDataMeterDataComponent.prototype.sanitizeDate = function (value) {
        var isGermanDateFormat = value.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/);
        if (isGermanDateFormat) {
            var _a = value.split("."), day = _a[0], month = _a[1], year = _a[2];
            return year + "-" + month.padStart(2, "0") + "-" + day.padStart(2, "0");
        }
        else {
            return value;
        }
    };
    ContractDataMeterDataComponent.prototype.saveReadingData = function (forceImplausibleSaving) {
        if (forceImplausibleSaving === void 0) { forceImplausibleSaving = false; }
        return __awaiter(this, void 0, void 0, function () {
            var form, readingValues, controlName, data, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isSubmitting = true;
                        this.formValidationError = null;
                        form = this.readingDataForm;
                        readingValues = [];
                        for (controlName in form.controls) {
                            if (controlName.startsWith("readingValue")) {
                                readingValues.push({
                                    registerType: controlName.replace("readingValue", ""),
                                    value: parseInt(form.get(controlName).value.trim())
                                });
                            }
                        }
                        data = {
                            readAt: this.sanitizeDate(form.get("readingDate").value),
                            readingValues: readingValues
                        };
                        return [4 /*yield*/, ReadingApi.post(this.contract.id, data, forceImplausibleSaving)];
                    case 1:
                        result = _a.sent();
                        this.formValidationError = result;
                        this.isSubmitting = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractDataMeterDataComponent.prototype.readingValueValidator = function (control) {
        var val = control.value.trim();
        if (!val) {
            return { required: true };
        }
        if (!/^\d+$/.test(val)) {
            return { pattern: true };
        }
        // No range check: '0' is explicitly allowed. For example, in newly
        // constructed buildings the reading value is often times '0'.
        //
        // Negative values are not allowed, but are already handled by
        // the regex above.
        return null;
    };
    ContractDataMeterDataComponent.prototype.existingDateValidator = function (control) {
        if (!control.value) {
            return null;
        }
        return moment(this.sanitizeDate(control.value)).isValid() ? null : { mustBeValidDate: true };
    };
    ContractDataMeterDataComponent.prototype.notInTheFutureValidator = function (control) {
        // only check future date if we have a valid date
        if (this.existingDateValidator(control)) {
            return null;
        }
        var inFuture = moment(this.sanitizeDate(control.value)).isAfter(this.todayAsYYYYminusMMminusDD(), "day");
        return inFuture ? { mustNotBeInFuture: true } : null;
    };
    ContractDataMeterDataComponent.prototype.todayAsYYYYminusMMminusDD = function () {
        return moment().format("YYYY-MM-DD");
    };
    return ContractDataMeterDataComponent;
}());
export { ContractDataMeterDataComponent };
