import { Component, Input } from "@angular/core";

@Component({
	selector: "mol-progress-bar",
	templateUrl: "./progress-bar.component.html",
	styleUrls: ["./progress-bar.component.scss"]
})
export class ProgressBarComponent {
	@Input() public currentStep: Step = 1;
	@Input() public stepLabels: string[] = [];
}

export type Step = 1 | 2 | 3;
