import { API_ROOT_URL } from './env';
import { Http } from '@rem/http';

export type Product = {
	id: string;
	productName: string;
	productCode: string;
	maxConsumption: number;
	minConsumption: number;
	cancellationPeriod: number;
	cancellationPeriodDaysType: string;
	workingPricePerKWh: number;
	totalBonus: number;
	totalPricePerYear: number;
	totalPricePerYearWithBonus: number; // deprecated, use totalBonus instead.
	basePricePerMonth: number;
	basePricePerYear: number;
	depositPerMonth: number;
	savedCO2Kgs: number;
	productFeatures: string[];
};

export class ProductsApi {
	static async get(region: string, consumption: number): Promise<Product[]> {
		const res = await fetch(`${API_ROOT_URL}/api/ova/productcalculations?regionid=${region}&consumption=${consumption}`);
		const resBody: ResBody = await res.json();

		if (res.status !== Http.OKAY) {
			throw new Error(
				`Requesting ${API_ROOT_URL}/api/ova/productcalculations?regionid=${region}&consumption=${consumption} failed.`
			);
		}
		if (!resBody.data || resBody.data.length < 1) {
			throw new Error(
				`Requesting ${API_ROOT_URL}/api/ova/productcalculations?regionid=${region}&consumption=${consumption} returned no data or an empty list.`
			);
		}

		return resBody.data.map(item => ({
			id: item.id,
			...item.attributes
		}));
	}
}

type ResBody = {
	errors?: any[];
	data: {
		id: string;
		type: "productcalculation";
		attributes: Product;
	}[];
};
