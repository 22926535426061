import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

import {Subscription} from "rxjs";

@Component({
    selector: "tpl-faq",
    templateUrl: "./faq-template.component.html",
    styleUrls: ["./faq-template.component.scss"],
})
export class FaqTemplateComponent implements OnInit, OnDestroy {
    @Input() public globalData: any;
    @Input() public whitelabel: any;
    public showTemplateGlobal = false;
    public faqSections: object;
    public fragment: string;

    private routeFragment_: Subscription;

    constructor(
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.faqSections = this.globalData.faqPage;
        this.showTemplateGlobal = true;

        this.routeFragment_ = this.route.fragment.subscribe(fragment => {
            this.fragment = fragment;
        });
    }

    ngOnDestroy(): void {
        this.routeFragment_.unsubscribe();
    }
}
