/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./client-portal-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../templates/client-portal-template/client-portal-template.component.ngfactory";
import * as i3 from "../../templates/client-portal-template/client-portal-template.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./client-portal-page.component";
var styles_ClientPortalPageComponent = [i0.styles];
var RenderType_ClientPortalPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientPortalPageComponent, data: {} });
export { RenderType_ClientPortalPageComponent as RenderType_ClientPortalPageComponent };
function View_ClientPortalPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tpl-client-portal", [], null, null, null, i2.View_ClientPortalTemplateComponent_0, i2.RenderType_ClientPortalTemplateComponent)), i1.ɵdid(1, 245760, null, 0, i3.ClientPortalTemplateComponent, [i4.ActivatedRoute, i4.Router], { globalData: [0, "globalData"], editorialData: [1, "editorialData"], whitelabel: [2, "whitelabel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.globalData; var currVal_1 = _co.editorialData; var currVal_2 = _co.whitelabel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ClientPortalPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "pageIndex"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientPortalPageComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showTemplateGlobal && _co.showTemplateEditorial); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ClientPortalPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pag-client-portal", [], null, null, null, View_ClientPortalPageComponent_0, RenderType_ClientPortalPageComponent)), i1.ɵdid(1, 245760, null, 0, i6.ClientPortalPageComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientPortalPageComponentNgFactory = i1.ɵccf("pag-client-portal", i6.ClientPortalPageComponent, View_ClientPortalPageComponent_Host_0, {}, {}, []);
export { ClientPortalPageComponentNgFactory as ClientPortalPageComponentNgFactory };
