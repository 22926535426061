/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./device-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./device-selector.component";
import * as i3 from "./device-selector.service";
import * as i4 from "../window-ref";
var styles_DeviceSelectorComponent = [i0.styles];
var RenderType_DeviceSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeviceSelectorComponent, data: {} });
export { RenderType_DeviceSelectorComponent as RenderType_DeviceSelectorComponent };
export function View_DeviceSelectorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { deviceWrapper: 0 }), i1.ɵqud(671088640, 2, { deviceSelector: 1 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["deviceWrapper", 1]], null, 5, "div", [["class", "device-selector-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[2, 0], ["deviceSelector", 1]], null, 0, "div", [["class", "device-selector device-xs"], ["data-devicetype", "xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[2, 0], ["deviceSelector", 1]], null, 0, "div", [["class", "device-selector device-sm"], ["data-devicetype", "sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, [[2, 0], ["deviceSelector", 1]], null, 0, "div", [["class", "device-selector device-md"], ["data-devicetype", "md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, [[2, 0], ["deviceSelector", 1]], null, 0, "div", [["class", "device-selector device-lg"], ["data-devicetype", "lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [[2, 0], ["deviceSelector", 1]], null, 0, "div", [["class", "device-selector device-xl"], ["data-devicetype", "xl"]], null, null, null, null, null))], null, null); }
export function View_DeviceSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-device-selector", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DeviceSelectorComponent_0, RenderType_DeviceSelectorComponent)), i1.ɵdid(1, 114688, null, 0, i2.DeviceSelectorComponent, [i1.ElementRef, i3.DeviceSelectorService, i4.WindowRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeviceSelectorComponentNgFactory = i1.ɵccf("app-device-selector", i2.DeviceSelectorComponent, View_DeviceSelectorComponent_Host_0, {}, {}, []);
export { DeviceSelectorComponentNgFactory as DeviceSelectorComponentNgFactory };
