import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SeoService } from "@p2p/services/seo.service";
import { RegionsApi } from "@rem/regions";
import { Region as CurrentRegion } from "@rem/region";
import { Subscription } from "rxjs";
import { DataApi } from "@rem/data";

@Component({
	selector: "pag-agb",
	templateUrl: "./agb-page.component.html",
	styleUrls: ["./agb-page.component.scss"]
})
export class AgbPageComponent implements OnInit, OnDestroy {
	public globalData: any;
	public showTemplate = true;
	public editorialData: any;
	public whitelabel: string;

	private routeParams_: Subscription;
	currentRegion = CurrentRegion.getCurrent();

	constructor(private route: ActivatedRoute, private seoService: SeoService) {}

	async ngOnInit(): Promise<void> {
		this.globalData = await DataApi.getGlobalData();
		this.routeParams_ = this.route.params.subscribe(async params => {
			this.whitelabel = params["region"];

			this.editorialData = await DataApi.getRegionData(this.whitelabel);

			if (this.editorialData && this.editorialData.pageMetas) {
				this.seoService.setTitle(this.editorialData.pageMetas.default.title);
				this.seoService.updateMetaTags(this.editorialData.pageMetas.default.metaData);
			}
			this.showTemplate = true;
		});
		let regionPromise = RegionsApi.get(this.currentRegion);
	}

	ngOnDestroy(): void {
		if (this.routeParams_)
			this.routeParams_.unsubscribe();
	}
}
