// This file contains helper functions to query the elements used in the OVA form
// in a type-safe fashion. For implementation details, see function `getElementsById`.

// Note: If any IDs are missing, the whole form breaks - by design. This is to make it
// obvious when this list of IDs needs to be updated.

export function initInputs() {
	// Generated in the console so we don't forget any IDs:
	// > Array.from(document.querySelector("form").querySelectorAll("input")).map(i => i.id).filter(Boolean).sort().map(i => `'${i}'`).join(",")
	return getInputsById([
		// UPPERCASE inputs are radio buttons.
		"ALREADY_CANCELLED",
		"CANCELLATION_DESIRED",
		"DELIVERY_ASAP",
		"DELIVERY_AT_DATE",
		"NO_RELOCATION",
		"RECENTLY_RELOCATED",
		"WILL_RELOCATE",
		// All other inputs.
		"accountOwner",
		"adsMail",
		"adsPhone",
		"adsPost",
		"alternativeCompanyName",
		"alternativeZipcode",
		"alternativeBillingAddress",
		"alternativeFirstname",
		"alternativeHouseNumber",
		"alternativeLastname",
		"alternativeStreetFallback",
		"cancellationDate",
		"companyName",
		"dateOfBirth",
		"desiredDate",
		"deviceNumber",
		"directDebitAuthority",
		"email",
		"firstname",
		"houseNumber",
		"iban",
		"lastname",
		"phone",
		"recruitFriends",
		"referralBillboards",
		"referralFriends",
		"referralInternet",
		"referralNewspaper",
		"referralOthers",
		"referralOthersText",
		"relocationDate",
		"termsAndConditions",
		"streetFallback",
		"title"
	]);
}

export function initDivs() {
	return getDivsById([
		"streetContainer",
		"streetFallbackContainer",
		"alternativeStreetContainer",
		"alternativeStreetFallbackContainer",
		"alternativeBillingAddressContainer",
		"bankTransferContainer",
		"cancellationContainer",
		"cancellationDateContainer",
		"cancellationDisclaimer",
		"desiredDateContainer",
		"directDebitContainer",
		"relocationDateContainer"
	]);
}

export function initSelects() {
	return getSelectsById([
		"alternativeCity",
		"alternativeSalutation",
		"alternativeStreet",
		"city",
		"paymentType",
		"provider",
		"salutation",
		"street",
		"zipcode"
	]);
}

export function initSections() {
	return getSectionsById(["deliveryDateSection"]);
}

export function initFieldsets() {
	return getFieldsetsById(["step-1", "step-2", "step-3"]);
}

function getInputsById<I extends Readonly<I> & string>(ids: I[]) {
	return getElementsById<HTMLInputElement, typeof ids[number]>("INPUT", ids);
}

function getDivsById<I extends Readonly<I> & string>(ids: I[]) {
	return getElementsById<HTMLDivElement, typeof ids[number]>("DIV", ids);
}

function getSelectsById<I extends Readonly<I> & string>(ids: I[]) {
	return getElementsById<HTMLSelectElement, typeof ids[number]>("SELECT", ids);
}

function getFieldsetsById<I extends Readonly<I> & string>(ids: I[]) {
	return getElementsById<HTMLFieldSetElement, typeof ids[number]>("FIELDSET", ids);
}

function getSectionsById<I extends Readonly<I> & string>(ids: I[]) {
	return getElementsById<HTMLElement, typeof ids[number]>("SECTION", ids);
}

/**
 * Retrieve a number of `HTMLElement`s by `id` in a statically typed fashion.
 *
 * Example usage:
 * ```typescript
 * let C = getElementsById(["a", "b"]);
 * // Type of C is now:
 * // {
 * //    a: HTMLElement;
 * //    b: HTMLElement;
 * // }
 * ```
 */
function getElementsById<T extends HTMLElement, I extends Readonly<I> & string>(tagName: string, ids: I[]) {
	type Ids = typeof ids[number];
	let result: { [x in Ids]: T } = {} as any;
	for (let id of ids) {
		let el = document.getElementById(id) as T;
		if (!el) throw new Error(`Unknown id: ${id}`);
		if (el.tagName !== tagName)
			throw new Error(`Invalid tagName for id '${id}': expected '${tagName}', got '${el.tagName}'`);
		result[id] = el;
	}
	return result;
}
