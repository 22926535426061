import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {IamService} from "@rem/iam";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (IamService.isLoggedIn()) {
            return true;
        }
        this.router.navigateByUrl(`/${route.paramMap.get("region")}`);
        return false;
    }
}
