/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./video.component";
import * as i3 from "../../../utils/device-selector/device-selector.service";
var styles_VideoComponent = [i0.styles];
var RenderType_VideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoComponent, data: {} });
export { RenderType_VideoComponent as RenderType_VideoComponent };
export function View_VideoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { videoContainer: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["videoContainer", 1]], null, 0, "video", [["class", "video"], ["loop", ""], ["oncanplay", "this.play()"], ["onloadedmetadata", "this.muted = true"], ["playsinline", ""], ["preload", ""]], [[8, "autoplay", 0], [8, "muted", 0], [8, "poster", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = _co.poster; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_VideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ato-video", [], null, [["window", "device-changed"]], function (_v, en, $event) { var ad = true; if (("window:device-changed" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onDeviceChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_VideoComponent_0, RenderType_VideoComponent)), i1.ɵdid(1, 4243456, null, 0, i2.VideoComponent, [i1.PLATFORM_ID, i3.DeviceSelectorService], null, null)], null, null); }
var VideoComponentNgFactory = i1.ɵccf("ato-video", i2.VideoComponent, View_VideoComponent_Host_0, { video: "video", poster: "poster" }, {}, []);
export { VideoComponentNgFactory as VideoComponentNgFactory };
