import {Component, ElementRef, HostListener, OnInit, ViewChild, ViewChildren} from "@angular/core";
import {DeviceSelectorService} from "./device-selector.service";
import {WindowRef} from "@p2p/utils/window-ref";

@Component({
    selector: "app-device-selector",
    templateUrl: "./device-selector.component.html",
    styleUrls: ["./device-selector.component.scss"]
})
export class DeviceSelectorComponent implements OnInit {
    @ViewChild("deviceWrapper", {static: false}) private deviceWrapper: ElementRef;
    @ViewChildren("deviceSelector") private deviceSelector: any;
    private element: any;

    constructor(
        private elementRef: ElementRef,
        private deviceSelectorService: DeviceSelectorService,
        private winRef: WindowRef
    ) {
        this.element = elementRef.nativeElement;
    }

    @HostListener("window:resize", ["$event"])
    onResize(): any {
      this.update();
    }

    ngOnInit(): void {
        this.update();
    }

    update(): void {
        if (this.deviceWrapper !== undefined) {
            this.deviceSelector = this.deviceWrapper.nativeElement.children;
            if (this.winRef.nativeWindow) {
                const device = [].filter.call(this.deviceSelector, (el: any) =>  {
                    return this.winRef.nativeWindow.getComputedStyle(el).display !== "none";
                })[0].getAttribute("data-devicetype");

                this.deviceSelectorService.setDevice(device);
            }
        }
    }
}
