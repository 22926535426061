// TODO(Mark): Remove this class when implementing proper pre-rendering
export class LocalStorage {
    static getItem(key: string): string | null {
        try {
            return localStorage.getItem(key);
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    static setItem(key: string, value: string): void {
        try {
            return localStorage.setItem(key, value);
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    static removeItem(key: string): void {
        try {
            localStorage.removeItem(key);
        } catch (err) {
            console.error(err);
        }
    }

    static getJsonItem<T>(key: string): T | null {
        return JSON.parse(this.getItem(key));
    }

    static setJsonItem<T>(key: string, value: T): void {
        this.setItem(key, JSON.stringify(value));
    }
}
