import { Salutation } from "./customer";
import { API_ROOT_URL } from "./env";
import { Http } from "./http";

export type StartingType = {
	startingType: "EARLIEST_POSSIBLE_DATE" | "DESIRED_DATE" | "TERMINATED_IN_PERSON" | "RELOCATION_AT";
	date?: string; // empty if EARLIEST_POSSIBLE_DATE is selected
};

export type Payment = null /*Bank Transfer*/ | {
	bankAccount: {
		iban: string;
		owner: string;
	};
};

export type AlternativeBillingAddress = {
	city: string;
	contact: {
		companyName: string | null;
		firstname: string;
		lastname: string;
		salutation: Salutation;
	};
	houseNumber: string;
	street: string;
	zipcode: string;
};

export type Order = {
	address: {
		city: string;
		houseNumber: string;
		street: string;
		zipcode: string;
	};
	adsMail: boolean;
	adsPhone: boolean;
	adsPost: boolean;
	alternativeBillingAddress?: AlternativeBillingAddress;
	consumption: number;
	contact: {
		dateOfBirth: string; // Must be at least 18 years old
		companyName: string | null;
		email: string;
		firstname: string;
		lastname: string;
		phone: string;
		salutation: Salutation;
	};
	deviceNumber: string;
	payment: Payment;
	previousProviderBdewId: string;
	productCode: string;
	regionId: string;
	startingType: StartingType;
	referralBillboards: boolean;
	referralFriends: boolean;
	referralInternet: boolean;
	referralNewspaper: boolean;
	referralOthers: boolean;
	referralOthersText: string;
	unknownAddress: boolean;
	recommendationCode: string | null;
	tosAccepted: boolean;
};

export type OrderResponse = "OK" | "UNKNOWN_ERROR" | "ORDER_CUSTOMER_EXISTING_NOT_IDENTICAL" | "ACCEPTED" | { invalidFields: string[] };

export class OrderApi {
	static async post(order: Order): Promise<OrderResponse> {
		try {
			let res = await fetch(`${API_ROOT_URL}/api/ova/order`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					data: {
						type: "order",
						attributes: order
					}
				})
			});
			if (res.status === Http.CREATED) {
				return "OK";
			}
			if (res.status === Http.ACCEPTED) {
				return "ACCEPTED";
			}
			if (res.status === Http.BAD_REQUEST) {
				let body = await res.json();
				let invalidFields: string[] = [];
				for (let error of body.errors) {
					// Test only for specific errors which will actually be handled by the UI
					if (error.code === "GENERAL_FIELD_CONTENT" && error.source === "email") {
						invalidFields.push("email");
					}
					if (error.code === "GENERAL_FIELD_FORMAT" && error.source === "iban") {
						invalidFields.push("iban");
					}
					if (error.code === "ORDER_CUSTOMER_EXISTING_NOT_IDENTICAL") {
						return error.code;
					}
				}
				if (invalidFields.length > 0) {
					return { invalidFields };
				}
			}
			return "UNKNOWN_ERROR";
		} catch (err) {
			// TODO: Differentiate between network errors and API errors, etc.
			console.error(err);
			return "UNKNOWN_ERROR";
		}
	}
}
