<div class="container-wrapper">
	<div class="container">
		<ato-loading-spinner [isRelative]="true" [loadingReady]="isLoaded"></ato-loading-spinner>
		<div class="row" *ngIf="isLoaded">
			<div class="col-xs-4 col-md-6 col-md-push-1 col-lg-4 col-xl-6 content-carpet">
				<div class="content-wrapper">
					<form [formGroup]="billingDataForm">
						<h2>Rechnungsadresse</h2>
						<div class="form-content">
							<div class="btn-wrapper">
								<button type="button" *ngIf="!billingDataEditMode" class="btn edit" (click)="editBillingData()">
									Bearbeiten
								</button>
								<button
									type="button"
									*ngIf="billingDataEditMode"
									class="btn save"
									[disabled]="!validateBillingData()"
									(click)="saveBillingData()"
								>
									Speichern
								</button>
								<button type="button" class="btn cancel" [disabled]="!billingDataEditMode" (click)="cancelBillingData()">
									Abbrechen
								</button>
							</div>
							<h3>Kontakt</h3>
							<div class="item">
								<label for="salutation">Anrede</label>
								<select id="salutation" formControlName="salutation">
									<option value="MISS">Frau</option>
									<option value="MISTER">Herr</option>
									<option value="FAMILY">Familie</option>
									<option value="BUSINESS">Firma</option>
								</select>
							</div>
							<div class="item" *ngIf="billingDataForm.get('companyName').value">
								<label for="companyName">Firma</label>
								<p class="static" id="companyName">{{ billingDataForm.get("companyName").value }}</p>
							</div>
							<div class="group">
								<div class="item col-xs-4 col-md-4 col-xl-6">
									<label for="firstname" class="required">Vorname</label>
									<input
										type="text"
										id="firstname"
										formControlName="firstname"
										class="immediate-validation"
										placeholder="Vorname"
									/>
								</div>
								<div class="item col-xs-4 col-md-4 col-xl-6">
									<label for="lastname" class="required">Nachname</label>
									<input
										type="text"
										id="lastname"
										formControlName="lastname"
										class="immediate-validation"
										placeholder="Nachname"
									/>
								</div>
							</div>
							<div
								class="group error group-close"
								*ngIf="billingDataForm.get('firstname').invalid || billingDataForm.get('lastname').invalid"
							>
								<div class="item col-xs-4 col-md-4 col-xl-6">
									<div *ngIf="billingDataForm.get('firstname').invalid">
										{{ errorMessages.validation.firstName }}
									</div>
								</div>
								<div class="item col-xs-4 col-md-4 col-xl-6">
									<div *ngIf="billingDataForm.get('lastname').invalid">
										{{ errorMessages.validation.lastName }}
									</div>
								</div>
							</div>
							<div class="item">
								<label for="email">E-Mail-Adresse</label>
								<input
									type="email"
									id="email"
									formControlName="email"
									class="immediate-validation"
									placeholder="E-Mail-Adresse"
								/>
							</div>
							<div class="group error group-close" *ngIf="billingDataForm.get('email').invalid">
								{{ errorMessages.validation.email }}
							</div>
							<div class="item">
								<label for="phone">Telefonnummer</label>
								<input type="tel" id="phone" formControlName="phone" class="immediate-validation" placeholder="Telefonnummer" />
							</div>
							<div class="group error group-close" *ngIf="billingDataForm.get('phone').invalid">
								{{ errorMessages.validation.phone }}
							</div>
							<h3>Adresse</h3>
							<div class="group">
								<div class="item col-xs-4 col-md-4 col-xl-6">
									<label for="zipcode" class="required">Postleitzahl</label>
									<input
										type="number"
										inputmode="numeric"
										id="zipcode"
										formControlName="zipcode"
										required
										class="immediate-validation"
										placeholder="Postleitzahl"
									/>
								</div>
								<div *ngIf="billingDataEditMode" class="item col-xs-4 col-md-4 col-xl-6">
									<label for="city" class="required">Stadt</label>
									<select id="city" name="city" formControlName="cityname" required>
										<option *ngIf="citynames.length === 0" value="">(nicht verfügbar)</option>
										<option *ngIf="citynames.length > 1" value="">Bitte auswählen …</option>
										<option *ngFor="let cityname of this.citynames" value="{{ cityname }}"> {{ cityname }}</option>
									</select>
								</div>
								<div *ngIf="!billingDataEditMode" class="item col-xs-4 col-md-4 col-xl-6">
									<label for="city" class="required">Stadt</label>
									<input type="text" id="city" value="{{ storedCityname }}" disabled placeholder="Stadt" />
								</div>
							</div>
							<div class="group error group-close" *ngIf="billingDataEditMode && citynames.length === 0">
								<div class="item col-xs-4 col-md-4 col-xl-6">
									Bitte geben Sie eine vorhandene PLZ ein.
								</div>
							</div>
							<div class="group">
								<div *ngIf="billingDataEditMode" class="item col-xs-4 col-md-4 col-xl-6">
									<label for="street" class="required">Straße</label>
									<select id="street" name="street" formControlName="street" required>
										<option *ngIf="streets.length === 0" value="">(nicht verfügbar)</option>
										<option *ngIf="streets.length > 1" value="">Bitte auswählen …</option>
										<option *ngFor="let street of streets" value="{{ street }}"> {{ street }}</option>
									</select>
								</div>
								<div *ngIf="!billingDataEditMode" class="item col-xs-4 col-md-4 col-xl-6">
									<label for="street" class="required">Straße</label>
									<input type="text" id="street" value="{{ storedStreet }}" disabled placeholder="Straße" />
								</div>
								<div class="item col-xs-4 col-md-4 col-xl-6">
									<label for="houseNumber" class="required">Hausnummer</label>
									<input
										type="text"
										id="houseNumber"
										formControlName="houseNumber"
										required
										class="immediate-validation"
										placeholder="Hausnummer"
									/>
								</div>
							</div>
							<div class="group error group-close" *ngIf="showMissingStreetsErrorMessage()">
								Leider können unter der angegebenen Postleitzahl keine Straßen gefunden werden. Wenn Sie diese Daten dennoch
								bearbeiten möchten, kontaktieren Sie bitte unseren
								<a [routerLink]="['/' + region, 'kontaktformular']" [queryParams]="{ fromCsc: '1' }">Kundenservice</a>.
							</div>
							<!-- show this error only if we've streets (in which case we will have the long error message above) -->
							<div class="group error group-close" *ngIf="showMissingHousenumberErrorMessage()">
								<div class="item col-xs-4 col-md-4 col-xl-6"></div>
								<div class="item col-xs-4 col-md-4 col-xl-6">
									Bitte geben Sie eine gültige Hausnummer ein.
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="col-xs-4 col-md-6 col-md-push-1 col-lg-4 col-xl-6 content-carpet">
				<div class="content-wrapper">
					<form [formGroup]="paymentDataForm">
						<h2>Zahlungsmethode</h2>
						<div class="form-content">
							<div class="btn-wrapper">
								<button type="button" *ngIf="!paymentDataEditMode" class="btn edit" (click)="editPaymentData()">
									Bearbeiten
								</button>
								<button
									type="button"
									*ngIf="paymentDataEditMode"
									class="btn save"
									[disabled]="!validatePaymentData()"
									(click)="savePaymentData()"
								>
									Speichern
								</button>
								<button type="button" class="btn cancel" (click)="cancelPaymentData()" [disabled]="!paymentDataEditMode">
									Abbrechen
								</button>
							</div>
							<div class="item">
								<label for="paymentMethod">Zahlungsart</label>
								<select id="paymentMethod" formControlName="paymentMethod" (change)="clearPaymentData()">
									<option value="DIRECT_DEBIT">Lastschrift</option>
									<option value="BANK_TRANSFER">Banküberweisung</option>
								</select>
							</div>
							<div *ngIf="paymentDataForm.get('paymentMethod').value === 'DIRECT_DEBIT'">
								<div class="item">
									<label for="owner" class="required">KontoinhaberIn</label>
									<input
										type="text"
										id="owner"
										formControlName="owner"
										class="immediate-validation"
										placeholder="KontoinhaberIn"
									/>
									<div class="error" *ngIf="paymentDataForm.get('owner').invalid">
										{{ errorMessages.validation.owner }}
									</div>
								</div>
								<div class="item">
									<label for="iban" class="required">IBAN</label>
									<input
										type="text"
										id="iban"
										formControlName="iban"
										(click)="clearPaymentData()"
										class="immediate-validation"
										placeholder="IBAN"
									/>
									<br *ngIf="paymentDataForm.get('iban').invalid" />
									<div class="error" *ngIf="paymentDataForm.get('iban').invalid">
										{{ errorMessages.validation.iban }}
									</div>
								</div>
								<div class="item">
									<label for="bic">BIC</label>
									<p class="static" id="bic">{{ paymentDataForm.get("bic").value }}</p>
								</div>
								<div class="item">
									<label for="bank">Bank</label>
									<p class="static" id="bank">{{ paymentDataForm.get("bankname").value }}</p>
								</div>
								<div class="item" *ngIf="paymentDataEditMode">
									<div class="checkbox-wrapper">
										<input
											type="checkbox"
											id="allowDirectDebit"
											formControlName="allowDirectDebit"
											class="immediate-validation"
										/>
										<label class="allowDirectDebit" for="allowDirectDebit"
											>Ich mache es mir einfach: Ich ermächtige die Bayernwerk Regio Energie GmbH (Gläubiger-Id: DE71 ZZZ0 0001
											5072 35), Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut
											an, die von der Bayernwerk Regio Energie GmbH auf mein Konto gezogenen Lastschriften einzulösen. Die
											Referenznummer für das SEPA-Mandat wird mir separat mitgeteilt.<br />
											<br />
											Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten
											Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.</label
										>
									</div>
								</div>
							</div>
							<p *ngIf="paymentDataForm.get('paymentMethod').value === 'BANK_TRANSFER'" class="hint">
								Sie haben sich für die Zahlungsart <q>Überweisung</q> entschieden. Über Ihre monatlich zu zahlenden
								Abschlagsbeträge informieren wir Sie in Ihrer Vertragsbestätigung.
							</p>
						</div>
					</form>
				</div>
			</div>
			<div class="col-xs-4 col-md-8 col-xl-12">
				<div class="content-wrapper">
					<h2>Rechnungen</h2>
					<div *ngIf="contract.invoices.length === 0">
						<p>Es sind keine Rechnungen vorhanden.</p>
						<br />
					</div>
					<div class="invoices" *ngIf="contract.invoices.length > 0">
						<div class="invoice" *ngFor="let invoice of contract.invoices; index as i">
							<div class="invoice-meta">
								<div class="invoice-date">
									<label for="time-{{ i }}">Zeitraum</label>
									<span id="time-{{ i }}" class="data"
										>{{ invoice.startDate.format("DD.MM.YYYY") }}- {{ invoice.endDate.format("DD.MM.YYYY") }}</span
									>
								</div>
								<div class="invoice-amount">
									<label for="amount-{{ i }}">Betrag</label>
									<span id="amount-{{ i }}" class="data">{{ invoice.value }} €</span>
								</div>
								<div class="invoice-status">
									<label for="status-{{ i }}">Status</label>
									<span id="status-{{ i }}" [class]="(invoice.isPaid ? 'paid' : 'unpaid') + ' data status'">{{
										invoice.isPaid ? "Bezahlt" : "Offen"
									}}</span>
								</div>
							</div>
							<div class="invoice-download">
								<button
									class="btn"
									type="button"
									(click)="downloadInvoice('Rechnung', invoice.invoiceNumber, contract.id, invoice.endDate)"
								>
									Rechnung herunterladen
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<mol-overlay
	*ngIf="formSuccess"
	[type]="'success'"
	[headline]="'Änderungen gespeichert'"
	(onCallToAction)="formSuccess = false"
></mol-overlay>
<mol-overlay
	*ngIf="formError"
	[type]="'error'"
	[headline]="'Änderungen nicht gespeichert'"
	[subHeadline]="'Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut'"
	(onCallToAction)="formError = false"
></mol-overlay>
