<div *ngIf="!loadingReady" class="LoadingSpinner" [ngClass]="{ 'is-relative': isRelative }">
    <div class="LoadingSpinner__spinner">
        <!-- TODO: put spinner icon as SVG definition on top of the document and refer here with 'use': -->
        <div class="LoadingSpinner__spinner__icon">
            <svg version="1.1" viewBox="0 0 42 42" width="42" height="42" xmlns="http://www.w3.org/2000/svg">
                <g fill="none">
                    <path d="m21 9.33c-6.45 0-11.7 5.23-11.7 11.7s5.23 11.7 11.7 11.7 11.7-5.23 11.7-11.7-5.23-11.7-11.7-11.7z"/>
                    <g fill="#252420">
                        <path d="m19.5 35.5h2.92v6.47h-2.92z" opacity=".5"/>
                        <path d="m35.5 22.5v-2.92h6.47v2.92z" opacity=".75"/>
                        <path d="M22.46 6.471h-2.92V0h2.92z"/>
                        <path d="M6.471 19.54v2.92H0v-2.92z" opacity=".25"/>
                        <path d="m9.7 30.2 2.06 2.06-4.58 4.58-2.06-2.06z" opacity=".38"/>
                        <path d="m30.2 32.3 2.06-2.06 4.58 4.58-2.06 2.06z" opacity=".63"/>
                        <path d="m32.3 11.8-2.06-2.06 4.58-4.58 2.06 2.06z" opacity=".875"/>
                        <path d="m11.8 9.7-2.06 2.06-4.58-4.58 2.06-2.06z" opacity=".13"/>
                    </g>
                </g>
            </svg>
        </div>
    </div>
</div>
