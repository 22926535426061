import { Component, OnInit } from "@angular/core";
import { WIDGETS_ROOT_URL } from "@rem/env";
import { Region } from "@rem/region";
import "leaflet";

@Component({
	selector: "org-producers-map",
	templateUrl: "./producers-map.component.html",
	styleUrls: ["./producers-map.component.scss"]
})
export class ProducersMapComponent implements OnInit {
	ngOnInit() {
		const head = document.head;
		const pm = "producersmap";

		const widgetContainer = document.getElementById(pm);
		widgetContainer.dataset.region = Region.getCurrent();

		if (!head.querySelector(`#${pm}-css`)) {
			const link = document.createElement("link");
			link.id = `${pm}-css`;
			link.href = `${WIDGETS_ROOT_URL}/${pm}.css`;
			link.type = "text/css";
			link.rel = "stylesheet";
			head.appendChild(link);
		}

		// TODO: Load script only before first render
		// Re-mount component from existing script on subsequent render
		const script = document.createElement("script");
		script.id = `${pm}-js`;
		script.src = `${WIDGETS_ROOT_URL}/${pm}.js`;
		script.defer = true;
		head.appendChild(script);
	}
}
