/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contract-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../contract-data-general-data/contract-data-general-data.component.ngfactory";
import * as i5 from "../contract-data-general-data/contract-data-general-data.component";
import * as i6 from "../contract-data-invoice-data/contract-data-invoice-data.component.ngfactory";
import * as i7 from "../contract-data-invoice-data/contract-data-invoice-data.component";
import * as i8 from "../contract-data-meter-data/contract-data-meter-data.component.ngfactory";
import * as i9 from "../contract-data-meter-data/contract-data-meter-data.component";
import * as i10 from "../../../atoms/loading-spinner/loading-spinner.component.ngfactory";
import * as i11 from "../../../atoms/loading-spinner/loading-spinner.component";
import * as i12 from "./contract-data.component";
import * as i13 from "@angular/router";
var styles_ContractDataComponent = [i0.styles];
var RenderType_ContractDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContractDataComponent, data: {} });
export { RenderType_ContractDataComponent as RenderType_ContractDataComponent };
function View_ContractDataComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], [[1, "value", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], null, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], null, null), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.description; _ck(_v, 3, 0, currVal_1); }); }
function View_ContractDataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "contract-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["for", "contract-selector"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vertrag: "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "select", [["class", "container__select"], ["id", "contract-selector"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.setContract($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractDataComponent_3)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contracts; _ck(_v, 5, 0, currVal_0); }, null); }
function View_ContractDataComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-contract-data-general-data", [], null, null, null, i4.View_ContractDataGeneralDataComponent_0, i4.RenderType_ContractDataGeneralDataComponent)), i1.ɵdid(1, 638976, null, 0, i5.ContractDataGeneralDataComponent, [], { whitelabel: [0, "whitelabel"], errorMessages: [1, "errorMessages"], contractId: [2, "contractId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.whitelabel; var currVal_1 = _co.errorMessages; var currVal_2 = _co.contractId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ContractDataComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-contract-data-invoice-data", [], null, null, null, i6.View_ContractDataInvoiceDataComponent_0, i6.RenderType_ContractDataInvoiceDataComponent)), i1.ɵdid(1, 638976, null, 0, i7.ContractDataInvoiceDataComponent, [], { whitelabel: [0, "whitelabel"], errorMessages: [1, "errorMessages"], contractId: [2, "contractId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.whitelabel; var currVal_1 = _co.errorMessages; var currVal_2 = _co.contractId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ContractDataComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-contract-data-meter-data", [], null, null, null, i8.View_ContractDataMeterDataComponent_0, i8.RenderType_ContractDataMeterDataComponent)), i1.ɵdid(1, 573440, null, 0, i9.ContractDataMeterDataComponent, [], { whitelabel: [0, "whitelabel"], errorMessages: [1, "errorMessages"], contractId: [2, "contractId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.whitelabel; var currVal_1 = _co.errorMessages; var currVal_2 = _co.contractId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ContractDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 47, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractDataComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 44, "div", [["class", "subtabs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 15, "div", [["class", "container tabs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "input", [["class", "tablet"], ["id", "subtab1_tablet"], ["name", "subtabs-tablet"], ["type", "radio"]], [[8, "checked", 0], [2, "default", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeTab("allgemeine-daten") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "label", [["class", "tablet"], ["for", "subtab1_tablet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Allgemeine Daten"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "input", [["class", "tablet"], ["id", "subtab2_tablet"], ["name", "subtabs-tablet"], ["type", "radio"]], [[8, "checked", 0], [2, "default", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeTab("rechnung-und-zahlungsdaten") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "label", [["class", "tablet"], ["for", "subtab2_tablet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Rechnungs- und Zahlungsdaten"])), (_l()(), i1.ɵeld(15, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "input", [["class", "tablet"], ["id", "subtab3_tablet"], ["name", "subtabs-tablet"], ["type", "radio"]], [[8, "checked", 0], [2, "default", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeTab("z\u00E4hlerdaten") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "label", [["class", "tablet"], ["for", "subtab3_tablet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Z\u00E4hlerdaten"])), (_l()(), i1.ɵeld(20, 0, null, null, 27, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "input", [["class", "mobile"], ["id", "subtab1_mobile"], ["name", "subtabs"], ["type", "radio"]], [[8, "checked", 0], [2, "default", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeTab("allgemeine-daten") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 3, "label", [["class", "mobile"], ["for", "subtab1_mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Allgemeine Daten"])), (_l()(), i1.ɵeld(26, 0, null, null, 0, "span", [["class", "toggle-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractDataComponent_4)), i1.ɵdid(29, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 0, "input", [["class", "mobile"], ["id", "subtab2_mobile"], ["name", "subtabs"], ["type", "radio"]], [[8, "checked", 0], [2, "default", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeTab("rechnung-und-zahlungsdaten") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 3, "label", [["class", "mobile"], ["for", "subtab2_mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Rechnungs- und Zahlungsdaten"])), (_l()(), i1.ɵeld(35, 0, null, null, 0, "span", [["class", "toggle-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractDataComponent_5)), i1.ɵdid(38, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(39, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 0, "input", [["class", "mobile"], ["id", "subtab3_mobile"], ["name", "subtabs"], ["type", "radio"]], [[8, "checked", 0], [2, "default", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeTab("z\u00E4hlerdaten") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 3, "label", [["class", "mobile"], ["for", "subtab3_mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Z\u00E4hlerdaten"])), (_l()(), i1.ɵeld(44, 0, null, null, 0, "span", [["class", "toggle-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractDataComponent_6)), i1.ɵdid(47, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contracts.length > 1); _ck(_v, 2, 0, currVal_0); var currVal_9 = (_co.subsection == "allgemeine-daten"); _ck(_v, 29, 0, currVal_9); var currVal_12 = (_co.subsection == "rechnung-und-zahlungsdaten"); _ck(_v, 38, 0, currVal_12); var currVal_15 = (_co.subsection == "z\u00E4hlerdaten"); _ck(_v, 47, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.subsection == "allgemeine-daten"); var currVal_2 = !_co.subsection; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = (_co.subsection == "rechnung-und-zahlungsdaten"); var currVal_4 = !_co.subsection; _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_5 = (_co.subsection == "z\u00E4hlerdaten"); var currVal_6 = !_co.subsection; _ck(_v, 16, 0, currVal_5, currVal_6); var currVal_7 = (_co.subsection == "allgemeine-daten"); var currVal_8 = !_co.subsection; _ck(_v, 22, 0, currVal_7, currVal_8); var currVal_10 = (_co.subsection == "rechnung-und-zahlungsdaten"); var currVal_11 = !_co.subsection; _ck(_v, 31, 0, currVal_10, currVal_11); var currVal_13 = (_co.subsection == "z\u00E4hlerdaten"); var currVal_14 = !_co.subsection; _ck(_v, 40, 0, currVal_13, currVal_14); }); }
export function View_ContractDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ato-loading-spinner", [], null, null, null, i10.View_LoadingSpinnerComponent_0, i10.RenderType_LoadingSpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i11.LoadingSpinnerComponent, [], { loadingReady: [0, "loadingReady"], isRelative: [1, "isRelative"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractDataComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoaded; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.isLoaded; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_ContractDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-contract-data", [], null, null, null, View_ContractDataComponent_0, RenderType_ContractDataComponent)), i1.ɵdid(1, 245760, null, 0, i12.ContractDataComponent, [i13.ActivatedRoute, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContractDataComponentNgFactory = i1.ɵccf("org-contract-data", i12.ContractDataComponent, View_ContractDataComponent_Host_0, { whitelabel: "whitelabel", errorMessages: "errorMessages", globalData: "globalData" }, {}, []);
export { ContractDataComponentNgFactory as ContractDataComponentNgFactory };
