import { Component, OnInit } from "@angular/core";
import { CurrencyService } from "@p2p/services/currency.service";
import { ProductsApi, Product } from "@rem/products";
import { Region } from "@rem/region";
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: "mol-contract-view",
	templateUrl: "./contract-view.component.html",
	styleUrls: ["./contract-view.component.scss"]
})
export class ContractViewComponent implements OnInit {
	product: Product;
	consumption: number;

	constructor(public currencyService: CurrencyService, public activatedRoute: ActivatedRoute) {}

	async ngOnInit() {
		let { consumption, product: productId } = this.activatedRoute.snapshot.queryParams;
		this.consumption = parseInt(consumption);
		let products = await ProductsApi.get(Region.getCurrent(), this.consumption);
		this.product = products.find(p => p.id === productId);
	}

	splitMonthlyDeposit(i: number) {
		return this.product.depositPerMonth.toFixed(2).split(".")[i];
	}
}
