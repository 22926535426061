import {Component, Input} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RegExpConstants} from "@p2p/constants/constants";
import {IamApi} from "@rem/iam";

@Component({
    selector: "org-password-reset",
    templateUrl: "./password-reset.component.html",
    styleUrls: ["./password-reset.component.scss"]
})
export class PasswordResetComponent {
    @Input() public passwordResetData: any;
    @Input() public errorMessages: any;
    @Input() public whitelabel: string;
    public passwordResetSuccess = false;
    public passwordResetErrorText: string;
    public value: object;

    // Form Group and Validators
    public passwordResetFormGroup: FormGroup = new FormGroup({
        passwordResetEmail: new FormControl("", [
            Validators.required,
            Validators.pattern(RegExpConstants.EMAIL_REGEXP)
        ])
    });

    async onSubmit(): Promise<void> {
        // Only submit form if client validation succeeded
        if (this.passwordResetFormGroup.get("passwordResetEmail").invalid) {
            this.passwordResetFormGroup.get("passwordResetEmail").markAsTouched();
            return;
        }

        try {
            let email = this.passwordResetFormGroup.get("passwordResetEmail").value;
            await IamApi.resetPassword(this.whitelabel, email);
            this.passwordResetSuccess = true;
        } catch (err) {
            console.error(err);
            this.passwordResetErrorText = err.message;
        }
    }
}
