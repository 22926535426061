import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {DataApi} from "@rem/data";

@Component({
    selector: "pag-disclaimer-alt",
    templateUrl: "./disclaimer-page-alt.component.html",
    styleUrls: ["./disclaimer-page-alt.component.scss"],
})

export class DisclaimerPageAltComponent implements OnInit, OnDestroy {

    public showTemplate = true;
    public editorialData: any;
    public whitelabel: string;

    private routeParams_: Subscription;

    constructor(
        private route: ActivatedRoute,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.routeParams_ = this.route.params.subscribe(async params => {
            this.whitelabel = params["region"];

            this.editorialData = await DataApi.getRegionData(this.whitelabel);
            this.showTemplate = true;
        });
    }

    ngOnDestroy(): void {
        this.routeParams_.unsubscribe();
    }
}
