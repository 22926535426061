<div class="container">
	<div class="row">
		<ol class="progress-bar">
			<li
				*ngFor="let label of stepLabels; let i = index"
				[ngClass]="{
					past: i + 1 < currentStep,
					present: i + 1 === currentStep,
					future: i + 1 > currentStep
				}"
			>
				<span class="step">{{ i + 1 }}</span>
				<span class="step-description" [innerHTML]="label"></span>
			</li>
		</ol>
	</div>
</div>
