import { API_ROOT_URL } from "./env";
import { Http } from "./http";

export interface Provider {
    id: string;
    name: string;
    bdewId: string;
}

export class ProvidersApi {
    static async getAll(): Promise<Provider[]> {
        let res = await fetch(`${API_ROOT_URL}/api/ova/providers`, {
            headers: { Accept: "application/vnd.api+json" }
        });
        if (res.status !== Http.OKAY) {
            // TODO(Mark): Provide more specific error handling
            throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
        }
        let body = await res.json();
        return body.data.map(p => ({
            id: p.id,
            name: p.attributes.name,
            bdewId: p.attributes.bdewId
        }));
    }
}
