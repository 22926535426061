import { API_ROOT_URL } from './env';
import { Http } from './http';
import { IamService } from './iam';

export type EditableCustomer = {
	emailPrivate: string;
	phone: string;
	title: string;
	adsPhone: boolean;
	adsMail: boolean;
	adsPost: boolean;
};

export type Salutation = "MISTER" | "MISS" | "FAMILY" | "BUSINESS" | "UNKNOWN";

export type Customer = EditableCustomer & {
	id: string;
	dateOfBirth: string;
	firstname: string;
	salutation: Salutation;
	lastname: string;
	personalRecommendationCode: string;
};

export class CustomerApi {
	static async get(logoutIfUnauthorized = true): Promise<Customer> {
		let res = await fetch(`${API_ROOT_URL}/api/csc/customer`, {
			headers: {
				Accept: "application/vnd.api+json",
				Authorization: `Bearer ${IamService.getToken()}`
			}
		});
		if (res.status === Http.UNAUTHORIZED) {
			if (logoutIfUnauthorized) IamService.logout();
			return {} as Customer; // TODO(Mark): This will throw an error that will be swallowd since properties of the object will be accessed in the template.
		}
		if (res.status !== Http.OKAY) {
			// TODO(Mark): Provide more specific error handling
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}
		let resBody: ResBody = await res.json();
		return {
			id: resBody.data.id,
			...resBody.data.attributes
		};
	}

	static async patch(id: string, customer: Partial<EditableCustomer>): Promise<Customer> {
		let reqBody: ReqBody = {
			data: {
				id: id,
				type: "customer",
				attributes: customer
			}
		};
		let res = await fetch(`${API_ROOT_URL}/api/csc/customer`, {
			method: "PATCH",
			headers: {
				Accept: "application/vnd.api+json",
				Authorization: `Bearer ${IamService.getToken()}`,
				"Content-Type": "application/vnd.api+json"
			},
			body: JSON.stringify(reqBody)
		});
		if (res.status === Http.UNAUTHORIZED) {
			IamService.logout();
			return {} as Customer;
		}
		if (res.status !== Http.OKAY) {
			// TODO(Mark): Provide more specific error handling
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}
		// TODO(Mark): The response needs to be put back into the fields in the UI
		// so customers can see whether stuff was actually saved without having to
		// refresh the UI
		let resBody: ResBody = await res.json();
		return {
			id: resBody.data.id,
			...resBody.data.attributes
		};
	}
}

type ResBody = {
	data: {
		id: string;
		type: "customer";
		attributes: Customer;
	};
};

type ReqBody = {
	data: {
		id: string;
		type: "customer";
		attributes: Partial<EditableCustomer>;
	};
};

type FailedReqBody = {
	errors: {
		code: string;
		detail: string;
		status: number;
	}[];
};
