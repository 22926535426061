import { API_ROOT_URL } from "./env";
import { Http } from "./http";
import { IamService } from "./iam";

export type ReadingType =
	| "METER_REMOVAL"
	| "METER_INSTALLATION"
	| "MOVEIN"
	| "MOVEOUT"
	| "IN_BETWEEN"
	| "CUSTOMER_READING"
	| "REGULAR"
	| "HELPER_INVOICE"
	| "TECHNICAL";

export type ReadingOrigin = "CUSTOMER" | "DSO" | "THIRD_PARTY" | "PREVIOUS_SUPPLIER";
export type ReadingRegisterType = "HT" | "NT";
export type ReadingQuality = "READING" | "ESTIMATION" | "UNUSABLE" | "UNKNOWN";

export type Reading = {
	id: string;
	deviceNumber: string;
	origin: ReadingOrigin;
	quality: ReadingQuality;
	readAt: string;
	readingValues: {
		registerType: ReadingRegisterType;
		value: number;
	}[];
	type: ReadingType;
};

// See: https://confluence.dev.eon.com/display/P2P/Error-Handling
type ReadingError =
	| "READING_NO_VALID_DEVICE"
	| "READING_PREVIOUS_READING_WITH_HIGHER_VALUE"
	| "READING_PREVIOUS_READING_WITH_SMALLER_VALUE"
	| "READING_VALUE_IMPLAUSIBLE"
	| "READING_NO_VALID_CONTRACTSTATUS"
	| "READING_PERIOD_BILLED";

interface ResBody {
	data?: Reading[];
	errors?: {
		code: string;
		detail: string;
		status: number;
	}[];
}

export class ReadingApi {
	static async post(
		contractId: string,
		data: Partial<Reading>,
		forceImplausibleSaving = false
	): Promise<"SUCCESS" | "ERROR" | ReadingError> {
		try {
			let res = await fetch(
				`${API_ROOT_URL}/api/csc/readings?contractId=${contractId}&forceImplausibleSaving=${forceImplausibleSaving}`,
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${IamService.getToken()}`,
						"Content-Type": "application/vnd.api+json"
					},
					body: JSON.stringify({
						data: {
							type: "reading",
							attributes: data
						}
					})
				}
			);

			if (res.status === Http.BAD_REQUEST) {
				let body: ResBody = await res.json();
				let code = body.errors[0].code;
				// We expect to get a ReadingError here, but it could be any other kind of API error.
				// For example, submitting a reading with a future date will return 400 "GENERAL_FIELD_CONTENT".
				// Thus, all cases that we have not explicitly covered, will return the generic "ERROR";
				switch (code as ReadingError) {
					case "READING_NO_VALID_CONTRACTSTATUS":
					case "READING_NO_VALID_DEVICE":
					case "READING_PERIOD_BILLED":
					case "READING_PREVIOUS_READING_WITH_HIGHER_VALUE":
					case "READING_PREVIOUS_READING_WITH_SMALLER_VALUE":
					case "READING_VALUE_IMPLAUSIBLE":
						return code as ReadingError;
					default:
						return "ERROR";
				}
			}

			if (res.status === Http.UNAUTHORIZED) {
				IamService.logout();
			}

			return res.status === Http.OKAY ? "SUCCESS" : "ERROR"; // Unexpected status code (e.g. 500)
		} catch (err) {
			console.error(err);
			return "ERROR"; // Network error, timeout, or coding error in try-block
		}
	}
}
