// Unfortunately, the webpack.DefinePlugin does not work with Angular's build process
// so we can't inject any environment variables at build time. This is a work-around
// to determine them at runtime, depending on the `location.hostname`.

function hostname() {
	// Quick-fix for pre-render failing in CI
	try {
		return location.hostname;
	} catch {
		return "";
	}
}

function get_env() {
	switch (hostname()) {
		case "www.bayernwerk-regio-energie.de":
			return "prod";
		case "qas.www.bayernwerk-regio-energie.de":
			return "qa";
		default:
			return "dev";
	}
}

export const ENV = get_env();

const api_root_url = (() => {
	switch (ENV) {
		case "prod":
			return "https://api.rem.bayernwerk-regio-energie.de";
		case "qa":
			return "https://api.qas.rem.bayernwerk-regio-energie.de";
		case "dev":
			return "https://api.dev.rem.bayernwerk-regio-energie.de";
	}
})();

export const API_ROOT_URL = api_root_url;

const widgets_root_url = (() => {
	switch (ENV) {
		case "prod":
			return "https://remwidgetsprod.z6.web.core.windows.net";
		case "qa":
			return "https://remwidgetsqas.z6.web.core.windows.net";
		case "dev":
			return "https://remwidgetsdev.z6.web.core.windows.net";
	}
})();

export const WIDGETS_ROOT_URL = widgets_root_url;
