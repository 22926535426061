export const RegExpConstants = {
	EMAIL_REGEXP: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	EMAIL_OVA_REGEXP: /^.+@[^.]+\..*?[^.]{2,}$/,
	JUST_INT_REGEXP: /^[0-9]+$/,
	SIMPLE_TEXT_REGEXP: /^[ A-Za-zÀ-ÖØ-öø-ÿ]+$/,
	ZIPCODE_REGEXP: /^[0-9]{5}$/,
	PHONE_NUMBER_REGEXP: /^[+0-9-_.\()/\s]{6,}$/,
	IBAN_REGEXP: /^[a-zA-Z]{2}\d{2}\s?([0-9a-zA-Z]{4}\s?){4}[0-9a-zA-Z]{2}$/,
	INT_COMMATA_REGEXP: /^[+-]?[0-9]+(?:,[0-9]+)*(?:[.][0-9]+)?$/,
	// at least 8 characters with min. 1 digit, uppercase/lowercase letter and special character
	PASSWORD_REGEXP: /^(?=\S*[a-zäöü])(?=\S*[A-ZÄÖÜ])(?=\S*\d)(?=\S*[#$^+=!*(){}\[\]@%&§"'´`_,.\-])\S{8,}$/
};

export const CookieConstants = {
	// GTM will check if this cookie exists. If not GTM will not fire the analytics stuff.
	COOKIE_DISCLAIMER_NAME: "cookie-disclaimer",
	DO_NOT_TRACK_COOKIE_NAME: "donottrack",
	EXPIRY_DURATION_IN_DAYS: 30,
	GA_REGEXP: /_ga/,
	GAT_REGEXP: /_gat/,
	GID_REGEXP: /_gid/,
	COOKIE_DISCLAIMER_REGEXP: /cookie/
};
