<div class="container-wrapper">
	<div class="container">
		<ato-loading-spinner [isRelative]="true" [loadingReady]="isLoaded"></ato-loading-spinner>
		<div class="row" *ngIf="isLoaded">
			<div class="col-xs-4 col-md-6 col-md-push-1 col-lg-4 col-xl-5">
				<div class="content-wrapper">
					<h2>Meine Vertragsdaten</h2>
					<dl>
						<dt>Vertragsbeginn</dt>
						<dd>{{contract.tariff.activeFrom.format("DD.MM.YYYY")}}</dd>
						<dt>Kündigungsfrist</dt>
						<dd>{{formatContractCancellationPeriod(contract)}}</dd>
						<dt>Vertragsnummer</dt>
						<dd>{{contract.id}}</dd>
						<dt>Vertragsstatus</dt>
						<dd>{{formatContractStatus(contract)}}</dd>
					</dl>
				</div>

				<div class="content-wrapper">
					<h2>Lieferadresse</h2>
					<dl>
						<dt *ngIf="(contract.deliveryAddress && contract.deliveryAddress.contact && contract.deliveryAddress.contact.firstname) || (contract.deliveryAddress && contract.deliveryAddress.contact && contract.deliveryAddress.contact.lastname)">Name</dt>
						<dd>{{contract.deliveryAddress?.contact?.firstname}} {{contract.deliveryAddress?.contact?.lastname}}</dd>
						<dt>Adresse</dt>
						<dd>
							{{contract.deliveryAddress?.street}} {{contract.deliveryAddress?.houseNumber}}<br/>
							{{contract.deliveryAddress?.zipcode}} {{contract.deliveryAddress?.city}}
						</dd>
					</dl>
				</div>

			</div>

			<div class="col-xs-4 col-md-6 col-md-push-1 col-lg-4 col-xl-7">
				<div class="content-wrapper">
					<h2>Meine Preisdaten & Abschlag</h2>
					<div class="princing">
						<div class="payment">
							<div class="hint">Mein Abschlag</div>
							<div class="monthly-payment">
								{{ (contract.deposit?.grossAmountPerMonth | number:'1.0-0') || '--' }}
								<div class="monthly-payment__cent">
									,{{ (contract.deposit?.grossAmountPerMonth.toString().split(".")[1]) || '00' }}
									<div class="monthly-payment__cent__monthly">€ / Monat</div>
								</div>
							</div>
						</div>
						<div class="contract-details">
							<dl>
								<dt>Tarif</dt>
								<dd>{{contract.tariff?.product}}</dd>
								<dt>Verbrauch</dt>
								<dd>{{contract.usage | number:'1.0-2'}} kWh / Jahr</dd>
								<dt>Grundpreis</dt>
								<dd>{{contract.tariff?.basePrice | number:'1.2-2'}} € / Jahr</dd>
								<dt>Arbeitspreis</dt>
								<dd>{{contract.tariff?.workingPrice | number:'1.2-2'}} Ct / kWh</dd>
							</dl>
							<br class="clearfix">
							<div class="hint">Preise inkl. aller Steuern und Umlagen</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>
