/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-form-v2-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../templates/contact-form-v2-template/contact-form-v2-template.component.ngfactory";
import * as i3 from "../../templates/contact-form-v2-template/contact-form-v2-template.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./contact-form-v2-page.component";
var styles_ContactFormV2PageComponent = [i0.styles];
var RenderType_ContactFormV2PageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactFormV2PageComponent, data: {} });
export { RenderType_ContactFormV2PageComponent as RenderType_ContactFormV2PageComponent };
function View_ContactFormV2PageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tpl-contact-form-v2", [], null, null, null, i2.View_ContactFormV2TemplateComponent_0, i2.RenderType_ContactFormV2TemplateComponent)), i1.ɵdid(1, 114688, null, 0, i3.ContactFormV2TemplateComponent, [i4.ActivatedRoute], { globalData: [0, "globalData"], editorialData: [1, "editorialData"], whitelabel: [2, "whitelabel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.globalData; var currVal_1 = _co.editorialData; var currVal_2 = _co.whitelabel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ContactFormV2PageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "contactFormV2Page"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactFormV2PageComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showTemplate && _co.showTemplateEditorial); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ContactFormV2PageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pag-contact-form-v2", [], null, null, null, View_ContactFormV2PageComponent_0, RenderType_ContactFormV2PageComponent)), i1.ɵdid(1, 245760, null, 0, i6.ContactFormV2PageComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactFormV2PageComponentNgFactory = i1.ɵccf("pag-contact-form-v2", i6.ContactFormV2PageComponent, View_ContactFormV2PageComponent_Host_0, {}, {}, []);
export { ContactFormV2PageComponentNgFactory as ContactFormV2PageComponentNgFactory };
