import {Component, Input, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: "tpl-contact-form-v2",
    templateUrl: "./contact-form-v2-template.component.html",
    styleUrls: ["./contact-form-v2-template.component.scss"],
})

export class ContactFormV2TemplateComponent implements OnInit {
    @Input() public globalData: any;
    @Input() public editorialData: any;
    @Input() public whitelabel: string;

    private routeParams_: Subscription;
    public fromCsc = false;

    constructor (
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.routeParams_ = this.route.queryParams.subscribe(params => {
            this.fromCsc = params["fromCsc"];
        });
    }
}
