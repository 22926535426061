<video #videoContainer
    preload
    playsinline
    [autoplay]="true"
    [muted]="true"
    oncanplay="this.play()"
    onloadedmetadata="this.muted = true"
    loop
    [poster]="poster"
    class="video">
</video>
