export class ApiError implements Error {
    code: string;
    message: string;
    name: string;

    constructor(error: any) {
        this.name = "ApiError";
        this.code = error.code || "UNKNOWN";
    }
}

export class ApiErrorFactory {
    static createError(error: any) {
        if (error.code) {
            return new ApiError(error);
        }
        return new Error("Unknown API error.");
    }
}
