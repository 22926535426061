/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./redesign.component";
import * as i2 from "@angular/platform-browser";
var styles_RedesignComponent = ["iframe[_ngcontent-%COMP%] {\n\t\t\t\tposition: fixed;\n\t\t\t\ttop: 0;\n\t\t\t\tleft: 0;\n\t\t\t\tbottom: 0;\n\t\t\t\tright: 0;\n\t\t\t\twidth: 100%;\n\t\t\t\theight: 100%;\n\t\t\t\tborder: none;\n\t\t\t\tmargin: 0;\n\t\t\t\tpadding: 0;\n\t\t\t\tz-index: 999;\n\t\t\t}"];
var RenderType_RedesignComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_RedesignComponent, data: {} });
export { RenderType_RedesignComponent as RenderType_RedesignComponent };
export function View_RedesignComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "iframe", [], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.frame_src; _ck(_v, 0, 0, currVal_0); }); }
export function View_RedesignComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "redesign", [], null, null, null, View_RedesignComponent_0, RenderType_RedesignComponent)), i0.ɵdid(1, 49152, null, 0, i1.RedesignComponent, [i2.DomSanitizer], null, null)], null, null); }
var RedesignComponentNgFactory = i0.ɵccf("redesign", i1.RedesignComponent, View_RedesignComponent_Host_0, {}, {}, []);
export { RedesignComponentNgFactory as RedesignComponentNgFactory };
