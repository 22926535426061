<div class="container-wrapper">
	<div class="container">
		<ato-loading-spinner [isRelative]="true" [loadingReady]="isLoaded"></ato-loading-spinner>
		<div class="row" *ngIf="isLoaded">
			<!-- Toggle col- classes depending on contract type -->
			<div class="col-xs-4 col-md-6 col-md-push-1 col-lg-4 col-xl-6 content-carpet">
				<div class="content-wrapper">
					<form [formGroup]="customerDataForm">
						<h2>Meine persönlichen Daten</h2>
						<div class="form-content">
							<div class="btn-wrapper">
								<button *ngIf="!editMode" class="btn edit" (click)="editCustomerData()">Bearbeiten</button>
								<button *ngIf="editMode" class="btn save" [disabled]="!validateCustomerData()" (click)="saveCustomerData()">
									Speichern
								</button>
								<button class="btn cancel" [disabled]="!editMode" (click)="cancelCustomerData()">Abbrechen</button>
							</div>
							<div class="group columns">
								<div class="item" *ngIf="(salutationList[customer.salutation] || '').length">
									<label for="salutation">Anrede</label>
									<p class="static immutable_field" id="salutation">{{ salutationList[customer.salutation] }}</p>
								</div>
								<div class="item">
									<label for="rem_title">Titel</label>
									<input list="rem_titles" formControlName="rem_title" id="rem_title" placeholder="Titel (optional)" />
									<datalist id="rem_titles">
										<option value="Dr."> </option>
										<option value="Prof."> </option>
										<option value="Prof. Dr."> </option>
										<option value="Dr. Dr."> </option>
									</datalist>
								</div>
							</div>
							<div class="item">
								<label for="firstName">Vorname</label>
								<p class="static immutable_field" id="firstName">{{ customer.firstname }}</p>
							</div>
							<div class="item">
								<label for="lastName">Nachname</label>
								<p class="static immutable_field" id="lastName">{{ customer.lastname }}</p>
							</div>
							<div class="item">
								<label for="dateOfBirth">Geburtsdatum</label>
								<p class="static immutable_field" id="dateOfBirth">{{ customer.dateOfBirth }}</p>
							</div>
							<div class="item">
								<label for="phone">Telefonnummer</label>
								<input
									type="text"
									id="phone"
									formControlName="phone"
									class="immediate-validation"
									placeholder="Telefonnummer"
								/>
							</div>
							<div class="error" *ngIf="customerDataForm.get('phone').invalid">{{ errorMessages.validation.phone }}</div>
						</div>
					</form>
				</div>
				<div class="content-wrapper">
					<form>
						<h2>Freunde werben</h2>
						<p class="description">
							Hier finden Sie Ihren persönlichen Werbecode. Geben Sie diesen Werbecode an Freunde nach Belieben weiter.
							Kopieren Sie sich den Code per Klick in Ihre Zwischenablage oder geben sie ihn gleich per E-Mail oder per
							WhatsApp weiter.
						</p>
						<div class="form-content">
							<div class="tellafriendcode">
								<label for="personalRecommendationCode">Ihr persönlicher Werbecode:</label>
								<input type="text" id="personalRecommendationCode" readonly value="{{ customer.personalRecommendationCode }}" />
								<a
									href="#copytoclipboard"
									[class]="'copy-' + copyToClipboardStatus"
									(click)="copyPersonalRecommendationCodeToClipboard(); $event.preventDefault()"
								>
									{{
										copyToClipboardStatus === "default"
											? "In die Zwischenablage kopieren"
											: copyToClipboardStatus === "success"
											? "Erfolgreich kopiert"
											: "Kopieren fehlgeschlagen"
									}}
								</a>
							</div>
							<ul class="share-options">
								<li>
									<a
										class="sharebutton email"
										[href]="mailRecommendationLink()"
										>Per E-Mail versenden …</a
									>
								</li>
								<li>
									<a
										class="sharebutton whatsapp"
										[href]="whatsAppRecommendationLink()"
										data-action="share/whatsapp/share"
										>Mit WhatsApp teilen …</a
									>
								</li>
							</ul>
						</div>
					</form>
				</div>
			</div>
			<div class="col-xs-4 col-md-6 col-md-push-1 col-lg-4 col-xl-6 content-carpet">
				<div class="content-wrapper">
					<form [formGroup]="emailForm">
						<h2>Meine E-Mail-Adresse</h2>
						<div class="form-content">
							<div class="btn-wrapper">
								<button *ngIf="!editModeEmail" class="btn edit" (click)="editEmail()">Bearbeiten</button>
								<button *ngIf="editModeEmail" [disabled]="!emailSaveButtonEnabled()" class="btn save" (click)="saveEmail()">
									Speichern
								</button>
								<button class="btn cancel" [disabled]="!editModeEmail" (click)="cancelEmail()">Abbrechen</button>
							</div>
							<div class="item">
								<label for="email" class="required">E-Mail-Adresse</label>
								<input
									type="text"
									id="email"
									formControlName="email"
									class="immediate-validation"
									placeholer="E-Mail-Adresse"
								/>
							</div>
						</div>
					</form>
				</div>
				<div class="content-wrapper">
					<form [formGroup]="passwordDataForm">
						<h2>Mein Passwort ändern</h2>
						<div class="form-content">
							<div class="btn-wrapper">
								<button class="btn save" [disabled]="!validatePassword()" (click)="savePassword()">
									Speichern
								</button>
							</div>
							<div class="item">
								<label for="password" class="required">Aktuelles Passwort</label>
								<input type="password" id="oldPassword" formControlName="oldPassword" placeholder="Aktuelles Passwort" />
								<div
									class="error"
									*ngIf="
										passwordDataForm.get('oldPassword').value.length > 0 &&
										passwordDataForm.get('oldPassword').invalid &&
										passwordDataForm.get('oldPassword').touched
									"
								>
									Bitte geben Sie Ihr aktuelles Passwort ein.
								</div>
							</div>
							<div class="item">
								<label for="password" class="required">Neues Passwort</label>
								<input type="password" id="password" formControlName="password" placeholder="Neues Passwort" />
								<div
									class="error"
									*ngIf="
										passwordDataForm.get('password').value.length > 0 &&
										passwordDataForm.get('password').invalid &&
										passwordDataForm.get('password').touched
									"
								>
									Das Passwort entspricht nicht den Anforderungen.
								</div>
							</div>
							<div class="item">
								<label for="passwordRepeat" class="required">Neues Passwort wiederholen</label>
								<input
									type="password"
									id="passwordRepeat"
									formControlName="passwordRepeat"
									placeholder="Neues Passwort wiederholen"
								/>
								<div
									class="error"
									*ngIf="
										passwordDataForm.get('passwordRepeat').value.length > 0 &&
										passwordDataForm.get('password').value !== passwordDataForm.get('passwordRepeat').value &&
										passwordDataForm.get('passwordRepeat').touched
									"
								>
									Die Passwörter stimmen nicht überein.
								</div>
								<br />
								<div [innerHtml]="errorMessages.validation.password" class="hint"></div>
							</div>
						</div>
					</form>
				</div>
				<div class="content-wrapper">
					<form [formGroup]="adDataForm">
						<h2>Werbeeinwilligung</h2>
						<div class="form-content">
							<p class="info">Wählen Sie einfach aus, ob und wie Sie von uns informiert werden möchten.</p>
							<div class="btn-wrapper">
								<button class="btn save" (click)="saveAds()">Speichern</button>
							</div>
							<div class="item">
								<div class="checkbox-wrapper">
									<input type="checkbox" id="allowAdsEMail" formControlName="emailAds" />
									<label for="allowAdsEMail">per E-Mail</label>
								</div>
								<div class="checkbox-wrapper">
									<input type="checkbox" id="allowAdsMail" formControlName="mailAds" />
									<label for="allowAdsMail">per Post</label>
								</div>
								<div class="checkbox-wrapper">
									<input type="checkbox" id="allowAdsTelephone" formControlName="phoneAds" />
									<label for="allowAdsTelephone">per Telefon</label>
								</div>
							</div>
							<p class="info hint">
								Mit der Auswahl einer oder mehrerer Optionen willige ich ein, dass mich die Bayernwerk Regio Energie GmbH zum
								Zwecke einer besseren Beratung und Produktoptimierung in Form einer Kundenzufriedenheitsumfrage und / oder zur
								Information über Produkte und Dienstleistungen kontaktieren darf.
								<br />
								<br />
								Sie können Ihre Einwilligung jederzeit und ohne Angaben von Gründen gegenüber der Bayernwerk Regio Energie GmbH,
								Luitpoldplatz 5, 95444 Bayreuth, oder per E-Mail unter
								<a href="mailto:kundenservice@bayernwerk-regio-energie.de">kundenservice@bayernwerk-regio-energie.de</a> mit
								Wirkung für die Zukunft widerrufen. Dies hat zur Folge, dass Sie von der Bayernwerk Regio Energie GmbH über den
								jeweiligen Kommunikationsweg nicht mehr zu Werbezwecken kontaktiert werden.
							</p>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
<mol-overlay
	*ngIf="formSuccess"
	[type]="'success'"
	[headline]="'Änderungen gespeichert'"
	(onCallToAction)="formSuccess = false"
></mol-overlay>
<mol-overlay
	*ngIf="formError"
	[type]="'error'"
	[headline]="'Änderungen nicht gespeichert'"
	[subHeadline]="this.errorSubHeadline"
	(onCallToAction)="formError = false; resetErrorSubHeadline()"
></mol-overlay>
