/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contract-data-meter-data-history.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../molecules/overlay/overlay.component.ngfactory";
import * as i3 from "../../../molecules/overlay/overlay.component";
import * as i4 from "../../../atoms/loading-spinner/loading-spinner.component.ngfactory";
import * as i5 from "../../../atoms/loading-spinner/loading-spinner.component";
import * as i6 from "@angular/common";
import * as i7 from "./contract-data-meter-data-history.component";
import * as i8 from "@angular/router";
var styles_ContractDataMeterDataHistoryComponent = [i0.styles];
var RenderType_ContractDataMeterDataHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContractDataMeterDataHistoryComponent, data: {} });
export { RenderType_ContractDataMeterDataHistoryComponent as RenderType_ContractDataMeterDataHistoryComponent };
function View_ContractDataMeterDataHistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "header-extension"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ihre Vorjahresverbrauchskurve wird anhand Ihres Vorjahresverbrauchs und dem Standardlastprofil f\u00FCr private Haushalte ermittelt. Ihren Monatsverbrauch ermitteln wir aus den von Ihnen angegebenen Z\u00E4hlerst\u00E4nden und dem Standardlastprofil. "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["id", "barchart"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "legend-extension"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, ":svg:svg", [["height", "11"], ["viewBox", "0 0 16 11"], ["width", "16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:path", [["d", "M7.5,0 L15,11 L0,11 Z"], ["fill", "#A0332D"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ihre eigenen Erfassungen "]))], null, null); }
function View_ContractDataMeterDataHistoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mol-overlay", [], null, [[null, "onCallToAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onCallToAction" === en)) {
        var pd_0 = ((_co.validationError = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_OverlayComponent_0, i2.RenderType_OverlayComponent)), i1.ɵdid(1, 245760, null, 0, i3.OverlayComponent, [i1.ElementRef, i1.PLATFORM_ID], { type: [0, "type"], headline: [1, "headline"], subHeadline: [2, "subHeadline"] }, { onCallToAction: "onCallToAction" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "error"; var currVal_1 = "Die Verbrauchs\u00FCbersicht kann nicht erstellt werden"; var currVal_2 = _co.validationError.subHeadline; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ContractDataMeterDataHistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ato-loading-spinner", [], null, null, null, i4.View_LoadingSpinnerComponent_0, i4.RenderType_LoadingSpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i5.LoadingSpinnerComponent, [], { loadingReady: [0, "loadingReady"], isRelative: [1, "isRelative"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractDataMeterDataHistoryComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractDataMeterDataHistoryComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoaded; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.isLoaded; _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.validationError !== null); _ck(_v, 6, 0, currVal_3); }, null); }
export function View_ContractDataMeterDataHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-contract-data-meter-data-history", [], null, null, null, View_ContractDataMeterDataHistoryComponent_0, RenderType_ContractDataMeterDataHistoryComponent)), i1.ɵdid(1, 704512, null, 0, i7.ContractDataMeterDataHistoryComponent, [i8.ActivatedRoute, i8.Router, i1.NgZone], null, null)], null, null); }
var ContractDataMeterDataHistoryComponentNgFactory = i1.ɵccf("org-contract-data-meter-data-history", i7.ContractDataMeterDataHistoryComponent, View_ContractDataMeterDataHistoryComponent_Host_0, { whitelabel: "whitelabel", errorMessages: "errorMessages", globalData: "globalData", contractId: "contractId" }, {}, []);
export { ContractDataMeterDataHistoryComponentNgFactory as ContractDataMeterDataHistoryComponentNgFactory };
