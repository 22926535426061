import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { RegExpConstants } from "@p2p/constants/constants";
import { DataApi } from "@rem/data";
import { ContactApi } from "@rem/contact";
import { Region } from "@rem/region";
import { CustomerApi } from "@rem/customer";
import { IamService } from "@rem/iam";

@Component({
	selector: "org-contact-form-v2",
	templateUrl: "./contact-form-v2.component.html",
	styleUrls: ["./contact-form-v2.component.scss"]
})
export class ContactFormV2Component implements OnInit {
	@Input() whitelabel: string;
	@Input() editorialData: any;
	globalData: any;
	showTemplateGlobal = false;
	showTemplateEditorial = false;
	formData: object;
	errorMessages: object;
	contactCreated = false;
	contactError = false;
	fromCsc = false;
	isWaiting = false;

	public contactForm: FormGroup = new FormGroup({
		salutation: new FormControl("", Validators.required),
		firstname: new FormControl("", [Validators.required, Validators.pattern(RegExpConstants.SIMPLE_TEXT_REGEXP)]),
		lastname: new FormControl("", [Validators.required, Validators.pattern(RegExpConstants.SIMPLE_TEXT_REGEXP)]),
		company: new FormControl("", []),
		message: new FormControl("", [Validators.required]),
		phone: new FormControl("", [Validators.pattern(RegExpConstants.PHONE_NUMBER_REGEXP)]),
		email: new FormControl("", [Validators.required, Validators.pattern(RegExpConstants.EMAIL_REGEXP)]),
		topic: new FormControl("", [Validators.required]),
		tosAccepted: new FormControl("", [Validators.requiredTrue])
	});

	constructor(private router: Router, private activatedRoute: ActivatedRoute) {
		this.activatedRoute.queryParams.subscribe(evt => {
			if (evt.fromCsc) {
				this.fromCsc = true;
			}
		});
	}

	async ngOnInit(): Promise<void> {
		this.globalData = await DataApi.getGlobalData();

		try {
			if (IamService.isLoggedIn()) {
				let customer = await CustomerApi.get(false);
				this.contactForm.patchValue({
					// TODO(Mark): Inconsistent validation for firstname, lastname, (no number allowed here, e.g. "Peterchen 3")
					// TODO(Mark): Inconsistent phone number, sometimes 1 field, sometimes 2
					salutation: customer.salutation,
					firstname: customer.firstname,
					lastname: customer.lastname,
					email: customer.emailPrivate,
					phone: customer.phone
				});
			}
		} catch {
			// Ignore failing to autofill contact form
		}
		this.formData = this.globalData.ovaForm;
		this.errorMessages = this.globalData.errorMessages;
		this.showTemplateGlobal = true;

		this.editorialData = await DataApi.getRegionData(this.whitelabel);
		this.showTemplateEditorial = true;
	}

	async onSubmit(): Promise<void> {
		this.isWaiting = true;
		try {
			await ContactApi.post({
				region: Region.getCurrent(),
				...this.contactForm.value
			});
			this.contactCreated = true;
		} catch (err) {
			this.contactError = true;
		}
		this.isWaiting = false;
	}

	navigateBack(): void {
		if (this.fromCsc) {
			this.router.navigateByUrl("/" + this.whitelabel + "/kundenportal?section=meine-ansprechpartner");
		} else {
			this.router.navigateByUrl("/" + this.whitelabel);
		}
	}
}
