import {Component, Input} from "@angular/core";

@Component({
    selector: "mol-contact-info",
    templateUrl: "./contact-info.component.html",
    styleUrls: ["./contact-info.component.scss"],
})

export class ContactInfoComponent {
    @Input() public contactInfoData: any;
    @Input() public whitelabel: string;
    @Input() public queryParams: string;
    @Input() public showContactFormLink = true;

    public showImage = false;
}

