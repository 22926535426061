import {Http} from "./http";

export class DataApi {
    private static cache: {} = {};

    static async getRegionData(region: string): Promise<any> {
        return this.get(`./assets/editorial/${region}/data/data.json`);
    }

    static async getGlobalData(): Promise<any> {
        return this.get(`./assets/globals/data/data.json`);
    }

    private static async get(path: string): Promise<any> {
        if (DataApi.cache[path]) {
            return DataApi.cache[path];
        }
        DataApi.cache[path] = new Promise(async resolve => {
            let res = await fetch(path, {
                headers: {
                    Accept: "application/json",
                },
            });
            if (res.status !== Http.OKAY) {
                // TODO(Mark): Provide more specific error handling
                throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
            }
            resolve(res.json());
        });
        return DataApi.cache[path];
    }
}
