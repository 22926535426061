/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./progress-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./progress-bar.component";
var styles_ProgressBarComponent = [i0.styles];
var RenderType_ProgressBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgressBarComponent, data: {} });
export { RenderType_ProgressBarComponent as RenderType_ProgressBarComponent };
function View_ProgressBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { past: 0, present: 1, future: 2 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "step"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "step-description"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((_v.context.index + 1) < _co.currentStep), ((_v.context.index + 1) === _co.currentStep), ((_v.context.index + 1) > _co.currentStep)); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = (_v.context.index + 1); _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 6, 0, currVal_2); }); }
export function View_ProgressBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ol", [["class", "progress-bar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressBarComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stepLabels; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_ProgressBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mol-progress-bar", [], null, null, null, View_ProgressBarComponent_0, RenderType_ProgressBarComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProgressBarComponent, [], null, null)], null, null); }
var ProgressBarComponentNgFactory = i1.ɵccf("mol-progress-bar", i3.ProgressBarComponent, View_ProgressBarComponent_Host_0, { currentStep: "currentStep", stepLabels: "stepLabels" }, {}, []);
export { ProgressBarComponentNgFactory as ProgressBarComponentNgFactory };
