import { Moment } from 'moment';

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegExpConstants } from '@p2p/constants/constants';
import { City, CityApi, CityWithStreets } from '@rem/city';
import { Contract, ContractApi, PaymentMethod } from '@rem/contract';
import { Region } from '@rem/region';

@Component({
	selector: "org-contract-data-invoice-data",
	templateUrl: "./contract-data-invoice-data.component.html",
	styleUrls: ["./contract-data-invoice-data.component.scss"]
})
export class ContractDataInvoiceDataComponent implements OnInit, OnChanges {
	@Input() public whitelabel: string;
	@Input() public errorMessages: any;
	@Input() public globalData: any;

	isLoaded = false;
	contract: Contract;
	@Input() contractId: string;
	region = Region.getCurrent();

	public cities: City[] = [];
	public citynames: string[] = [];
	public streets: string[] = [];
	public storedCityname: string = "";
	public storedStreet: string = "";

	public billingDataEditMode = false;
	public paymentDataEditMode = false;
	public formSuccess = false;
	public formError = false;

	public billingDataForm: FormGroup = new FormGroup({
		salutation: new FormControl({ value: "", disabled: true }),
		firstname: new FormControl({ value: "", disabled: true }, [Validators.required]),
		lastname: new FormControl({ value: "", disabled: true }, [Validators.required]),
		companyName: new FormControl({ value: "", disabled: true }),
		email: new FormControl({ value: "", disabled: true }, [Validators.pattern(RegExpConstants.EMAIL_REGEXP)]),
		phone: new FormControl({ value: "", disabled: true }, [Validators.pattern(RegExpConstants.PHONE_NUMBER_REGEXP)]),
		street: new FormControl({ value: "", disabled: true }, [Validators.required]),
		houseNumber: new FormControl({ value: "", disabled: true }, [Validators.required]),
		zipcode: new FormControl({ value: "", disabled: true }, [
			Validators.required,
			Validators.pattern(RegExpConstants.ZIPCODE_REGEXP)
		]),
		cityname: new FormControl({ value: "", disabled: true }, [Validators.required])
	});

	public paymentDataForm: FormGroup = new FormGroup({
		paymentMethod: new FormControl({ value: "", disabled: true }),
		owner: new FormControl({ value: "", disabled: true }, [Validators.required]),
		iban: new FormControl({ value: "", disabled: true }, [
			Validators.required,
			Validators.pattern(RegExpConstants.IBAN_REGEXP)
		]),
		bic: new FormControl({ value: "", disabled: true }),
		bankname: new FormControl({ value: "", disabled: true }),
		allowDirectDebit: new FormControl({ value: true, disabled: false }, [Validators.requiredTrue])
	});

	async ngOnInit(): Promise<void> {
		this.onBillingAddressChanges();
		await this.loadData();
	}

	// TODO debuggen, ob wir das wirklich brauchen
	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.contractId.isFirstChange()) {
			return;
		}
		await this.loadData();
	}

	private async loadData() {
		this.isLoaded = false;
		this.contract = await ContractApi.get(this.contractId);
		await this.postProcessLoadData();
		this.isLoaded = true;
	}

	private async postProcessLoadData() {
		this.billingDataForm.setValue({
			salutation: this.contract.billingAddress.contact.salutation,
			firstname: this.contract.billingAddress.contact.firstname,
			lastname: this.contract.billingAddress.contact.lastname,
			companyName: this.contract.billingAddress.contact.companyName,
			email: this.contract.billingAddress.contact.email,
			phone: this.contract.billingAddress.contact.phone,
			street: this.contract.billingAddress.street,
			houseNumber: this.contract.billingAddress.houseNumber,
			zipcode: this.contract.billingAddress.zipcode,
			cityname: this.contract.billingAddress.city
		});
		this.storedCityname = this.contract.billingAddress.city;
		this.storedStreet = this.contract.billingAddress.street;

		this.paymentDataForm.setValue({
			paymentMethod: this.contract.payment.paymentMethod,
			owner: this.contract.payment.bankAccount.owner,
			iban: this.contract.payment.bankAccount.iban,
			bic: this.contract.payment.bankAccount.bic,
			bankname: this.contract.payment.bankAccount.bankname,
			allowDirectDebit: this.contract.payment.paymentMethod === "DIRECT_DEBIT" ? true : false
		});
	}

	editBillingData(): void {
		this.setBillingAddressEditMode(true);
		this.validateBillingData();
	}

	private setBillingAddressEditMode(mode: boolean = true) {
		if (mode) {
			this.billingDataForm.enable();
		} else {
			this.billingDataForm.disable();
		}
		this.billingDataEditMode = mode;
	}

	async saveBillingData(): Promise<void> {
		try {
			const controls = this.billingDataForm;
			const patchData: any = {
				contact: {
					companyName: controls.get("companyName").value,
					salutation: controls.get("salutation").value,
					firstname: controls.get("firstname").value,
					lastname: controls.get("lastname").value
				},
				street: controls.get("street").value,
				houseNumber: controls.get("houseNumber").value,
				zipcode: controls.get("zipcode").value,
				city: controls.get("cityname").value
			};

			if (controls.get("email").value && controls.get("email").value.length > 0) {
				patchData.contact.email = controls.get("email").value;
			}
			if (controls.get("phone").value && controls.get("phone").value.length > 0) {
				patchData.contact.phone = controls.get("phone").value;
			}

			await ContractApi.patchBillingAddress(this.contract.id, patchData);
			this.formSuccess = true;
		} catch (error) {
			this.formSuccess = false;
			this.formError = true;
		} finally {
			this.setBillingAddressEditMode(false);
			await this.loadData();
		}
	}

	async cancelBillingData(): Promise<void> {
		this.billingDataForm.reset({
			salutation: this.contract.billingAddress.contact.salutation,
			firstname: this.contract.billingAddress.contact.firstname,
			lastname: this.contract.billingAddress.contact.lastname,
			companyName: this.contract.billingAddress.contact.companyName,
			email: this.contract.billingAddress.contact.email,
			phone: this.contract.billingAddress.contact.phone,
			street: this.contract.billingAddress.street,
			houseNumber: this.contract.billingAddress.houseNumber,
			zipcode: this.contract.billingAddress.zipcode,
			cityname: this.contract.billingAddress.city
		});
		this.setBillingAddressEditMode(false);
	}

	validateBillingData(): boolean {
		const controls = Object.keys(this.billingDataForm.controls).map(controlName => this.billingDataForm.get(controlName));
		const isAnyDirty = controls.some(control => control.dirty);
		const isAnyInvalid = controls.some(control => control.invalid);
		return isAnyDirty && !isAnyInvalid;
	}

	editPaymentData(): void {
		this.setPaymentDataEditMode(true);
	}

	private setPaymentDataEditMode(mode: boolean = true) {
		if (mode) {
			this.paymentDataForm.enable();
		} else {
			this.paymentDataForm.disable();
		}
		this.paymentDataEditMode = mode;
	}

	async savePaymentData(): Promise<void> {
		try {
			const controls = this.paymentDataForm;

			const paymentMethod: PaymentMethod = controls.get("paymentMethod").value;
			// TODO refactoring
			await ContractApi.patchPayment(
				this.contract.id,
				Object.assign(
					{
						paymentMethod: paymentMethod
					},
					paymentMethod === "DIRECT_DEBIT"
						? {
								bankAccount: {
									owner: controls.get("owner").value,
									iban: controls.get("iban").value
								}
						  }
						: {}
				),
				controls.get("allowDirectDebit").value
			);

			this.formSuccess = true;
		} catch (error) {
			this.formSuccess = false;
			this.formError = true;
		} finally {
			this.setPaymentDataEditMode(false);
			await this.loadData();
		}
	}

	async cancelPaymentData(): Promise<void> {
		this.paymentDataForm.reset({
			paymentMethod: this.contract.payment.paymentMethod,
			owner: this.contract.payment.bankAccount.owner,
			iban: this.contract.payment.bankAccount.iban,
			bic: this.contract.payment.bankAccount.bic,
			bankname: this.contract.payment.bankAccount.bankname,
			allowDirectDebit: this.contract.payment.paymentMethod === "DIRECT_DEBIT" ? true : false
		});
		this.setPaymentDataEditMode(false);
	}

	clearPaymentData() {
		this.paymentDataForm.patchValue({
			iban: "",
			bic: "",
			bankname: "",
			allowDirectDebit: false
		});
	}

	validatePaymentData(): boolean {
		if (this.paymentDataForm.get("paymentMethod").value === "BANK_TRANSFER") {
			return true;
		}
		const controls = Object.keys(this.paymentDataForm.controls).map(controlName => this.paymentDataForm.get(controlName));
		const isAnyDirty = controls.some(control => control.dirty);
		const isAnyInvalid = controls.some(control => control.invalid);
		return isAnyDirty && !isAnyInvalid;
	}

	async downloadInvoice(name: string, invoiceNumber: string, contractId: string, date: Moment) {
		const blob = await ContractApi.getInvoice(contractId, invoiceNumber);
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = `${name}-${contractId}-${date.format("YYYY-MM-DD")}.pdf`;
		// use dispatchEvent() instead of click() to make it work in ie and older ff versions
		link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
	}

	showMissingStreetsErrorMessage(): boolean {
		return this.billingDataEditMode && this.billingDataForm.controls.cityname.valid && this.streets.length === 0;
	}

	showMissingHousenumberErrorMessage(): boolean {
		// for layout reasons show the housenumber error only if there isn't an error in street selection
		return this.billingDataForm.controls.houseNumber.invalid && !this.showMissingStreetsErrorMessage();
	}

	async onBillingAddressChanges() {
		this.billingDataForm.controls.zipcode.valueChanges.subscribe(async newZipcode => {
			// Don't hammer API with invalid zipcodes.
			if (this.billingDataForm.controls.zipcode.value.length === 5) {
				this.cities = await CityApi.getAll(newZipcode);
				this.citynames = this.cities.map(city => city.name);

				// check for non-existing zipcode to show the error on zipcode and not on city field
				if (this.cities.length === 0) {
					this.billingDataForm.controls.zipcode.setErrors({ nonExisting: true });
				} else {
					this.billingDataForm.controls.zipcode.setErrors(null);
				}

				// set the selection to the proper default value (without we wouldn't reload the streets)
				if (this.cities.length === 1) {
					this.billingDataForm.controls.cityname.patchValue(this.cities[0].name);
				} else {
					// reset selected value, if not present anymore
					if (!this.citynames.includes(this.billingDataForm.controls.cityname.value)) {
						this.billingDataForm.controls.cityname.patchValue("");
					}
				}
			}
		});

		this.billingDataForm.controls.cityname.valueChanges.subscribe(async newCityname => {
			// find the cities to the given cityname
			const newCity = this.cities.find(city => city.name === newCityname);
			if (newCity) {
				const cityWithStreets = await CityApi.getOne(newCity.id);
				this.streets = cityWithStreets.streets;

				// set the selection to the proper default value
				if (this.streets.length === 1) {
					this.billingDataForm.controls.street.patchValue(this.streets[0]);
				} else {
					// reset selected value, if not present anymore
					if (!this.streets.includes(this.billingDataForm.controls.street.value)) {
						this.billingDataForm.controls.street.patchValue("");
					}
				}
			}
		});
	}
}
