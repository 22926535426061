<div class="container">
    <ato-loading-spinner [isRelative]="true" [loadingReady]="isLoaded"></ato-loading-spinner>
    <div class="row" *ngIf="isLoaded">
        <!-- Toggle col- classes depending on contract type -->
        <div class="contract-selector" *ngIf="contracts.length > 1">
            <label for="contract-selector">Vertrag: </label>
            <select class="container__select" id="contract-selector" (change)="setContract($event.target.value)">
                <option *ngFor="let contract of contracts" [attr.value]="contract.id">{{ contract.description }}
                </option>
            </select>
        </div>
        <div class="subtabs">
            <div class="container tabs">
                <ng-container>
                    <input class="tablet" id="subtab1_tablet" type="radio" name="subtabs-tablet"
                        [checked]="subsection == 'verlauf'" [class.default]="!subsection"
                        (change)="changeTab('verlauf')" />
                    <label class="tablet" for="subtab1_tablet"> <span class="text">Verlauf</span> </label>
                </ng-container>
                <ng-container>
                    <input class="tablet" id="subtab3_tablet" type="radio" name="subtabs-tablet"
                        [checked]="subsection == 'zählerdaten'" [class.default]="!subsection"
                        (change)="changeTab('zählerdaten')" />
                    <label class="tablet" for="subtab3_tablet"> <span class="text">Zählerstand erfassen</span> </label>
                </ng-container>
            </div>
            <div class="container">
                <ng-container>
                    <input class="mobile" id="subtab1_mobile" type="radio" name="subtabs"
                        [checked]="subsection == 'verlauf'" [class.default]="!subsection"
                        (change)="changeTab('verlauf')" />
                    <label class="mobile" for="subtab1_mobile">
                        <span class="text">Verlauf</span> <span class="toggle-icon"></span>
                    </label>
                    <section>
                        <org-contract-data-meter-data-history *ngIf="subsection == 'verlauf'" [whitelabel]="whitelabel"
                            [errorMessages]="errorMessages" [contractId]="contractId">
                        </org-contract-data-meter-data-history>
                    </section>
                </ng-container>
                <ng-container>
                    <input class="mobile" id="subtab3_mobile" type="radio" name="subtabs"
                        [checked]="subsection == 'zählerdaten'" [class.default]="!subsection"
                        (change)="changeTab('zählerdaten')" />
                    <label class="mobile" for="subtab3_mobile">
                        <span class="text">Zählerstand erfassen</span> <span class="toggle-icon"></span>
                    </label>
                    <section>
                        <org-contract-data-meter-data *ngIf="subsection == 'zählerdaten'" [whitelabel]="whitelabel"
                            [errorMessages]="errorMessages" [contractId]="contractId"></org-contract-data-meter-data>
                    </section>
                </ng-container>
            </div>
        </div>
    </div>
</div>
