<div class="container-wrapper">
	<div class="container">
		<ato-loading-spinner [isRelative]="true" [loadingReady]="isLoaded"></ato-loading-spinner>
		<div class="row" *ngIf="isLoaded">
			<div class="content-wrapper">
				<form [formGroup]="readingDataForm">
					<h2>Zählerstand abgeben</h2>
					<div class="form-content">
						<div class="item">
							<label for="reading-meter">Zählernummer</label>
							<p class="static" id="reading-meter">{{ contract.device.deviceNumber }}</p>
						</div>
						<div class="grouped">
							<div class="item" *ngFor="let register of contract.device.registers">
								<label for="reading-value">Zählerstand {{ register.description }}<sup>*</sup> (ohne Nachkommastellen)</label>
								<input
									type="text"
									inputmode="numeric"
									id="reading-value"
									formControlName="readingValue{{ register.description }}"
									placeholder="Zählerstand"
								/>
								<div class="error" *ngIf="formItemInvalid(readingDataForm, 'readingValue' + register.description)">
									Bitte einen gültigen Zählerstand eingeben.
								</div>
							</div>
							<div class="item">
								<label for="reading-date">Ablesedatum<sup>*</sup></label>
								<input
									type="date"
									[max]="todayAsYYYYminusMMminusDD()"
									id="reading-date"
									formControlName="readingDate"
									placeholder="TT.MM.JJJJ"
									pattern="^\d{1,2}\.\d{1,2}\.\d{4}|\d{4}-\d{2}-\d{2}$"
								/>
								<div
									class="error"
									*ngIf="
										readingDataForm.get('readingDate').touched &&
										(readingDataForm.get('readingDate').errors?.required ||
											readingDataForm.get('readingDate').errors?.mustBeValidDate)
									"
								>
									Es ist ein gültiges Ablesedatum erforderlich.
								</div>
								<div class="error" *ngIf="readingDataForm.get('readingDate').errors?.mustNotBeInFuture">
									Das Ablesedatum darf nicht in der Zukunft liegen.
								</div>
							</div>
						</div>
						<div class="btn-wrapper">
							<button
								type="button"
								class="btn save"
								[disabled]="!validateReadingData() || isSubmitting"
								(click)="saveReadingData()"
							>
								speichern
							</button>
						</div>
					</div>
				</form>
			</div>
			<div class="content-wrapper">
				<h2>Zählerstandshistorie</h2>
				<div class="meter-readings">
					<div *ngIf="contract.readings?.length === 0">
						<p>Es sind keine historischen Zählerstände vorhanden.</p>
						<br />
					</div>
					<table class="meter_readings">
						<thead>
							<tr>
								<th>Datum</th>
								<th>Zählernummer</th>
								<th>Ablesung</th>
								<th class="number">Zählerstand</th>
							</tr>
						</thead>
						<tfoot></tfoot>
						<tbody>
							<tr *ngFor="let reading of contract.readings; index as i">
								<td data-label="Datum: ">{{ reading.readAt.format("DD.MM.YYYY") }}</td>
								<td data-label="Zählernummer: " class="identity">{{ reading.deviceNumber }}</td>
								<td data-label="Ablesung: " class="reading">
									{{ METER_READ_TYPE[reading.type] }}.<br />
									{{ METER_READ_QUALITY[reading.quality] }} durch {{ METER_READ_ORIGIN[reading.origin] }}.
								</td>
								<td data-label="Zählerstand: " class="number">
									{{ reading.readingValues[0]?.value | number: "1.0-2" }}
									<span class="tariff">{{ reading.readingValues[0]?.registerType }}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- TODO: Refactor overlays / error message mapping -->

<!-- Success -->
<mol-overlay
	*ngIf="formValidationError === 'SUCCESS'"
	[type]="'success'"
	[headline]="'Zählerstand gespeichert'"
	(onCallToAction)="formValidationError = null; loadData()"
></mol-overlay>

<!-- Errors -->
<mol-overlay
	*ngIf="formValidationError === 'ERROR'"
	[type]="'error'"
	[headline]="'Zählerstand nicht gespeichert'"
	[subHeadline]="'Bitte prüfen Sie Ihre Eingabe oder versuchen Sie es später erneut'"
	(onCallToAction)="formValidationError = null"
></mol-overlay>
<!-- TODO: Add `earliestPossibleReadingDate` to error message -->
<mol-overlay
	*ngIf="formValidationError === 'READING_NO_VALID_DEVICE'"
	[type]="'error'"
	[headline]="'Zähler noch nicht aktiv'"
	[subHeadline]="
		'Der von Ihnen genannte Zähler kann erst in Zukunft verwendet werden. Bitte versuchen Sie es zukünftig erneut.'
	"
	(onCallToAction)="formValidationError = null"
></mol-overlay>
<mol-overlay
	*ngIf="formValidationError === 'READING_NO_VALID_CONTRACTSTATUS'"
	[type]="'error'"
	[headline]="'Ablesedatum außerhalb der Vertragsbelieferung'"
	[subHeadline]="
		'Der Vertrag ist derzeit nicht in Belieferung. Bitte warten Sie mit Ihrer Zählerstanderfassung bis zum Lieferbeginn. Sollte Ihr Vertrag gekündigt worden sein, können Sie keine neuen Zählerstände mehr erfassen.'
	"
	(onCallToAction)="formValidationError = null"
></mol-overlay>
<mol-overlay
	*ngIf="formValidationError === 'READING_PERIOD_BILLED'"
	[type]="'error'"
	[headline]="'Ablesedatum befindet sich zu weit in der Vergangenheit'"
	[subHeadline]="'Bitte geben Sie einen aktuelleren Zählerstand ein.'"
	(onCallToAction)="formValidationError = null"
>
</mol-overlay>

<!-- Warnings -->
<mol-overlay
	*ngIf="formValidationError === 'READING_PREVIOUS_READING_WITH_HIGHER_VALUE'"
	[type]="'information'"
	[headline]="'Zählerstand niedriger als erwartet'"
	[subHeadline]="
		'Der von Ihnen erfasste Zählerstand ist niedriger als ein zuvor erfasster Wert. Bitte überprüfen Sie Ihre Angaben.'
	"
	[callToAction]="'Dennoch speichern'"
	[callToAction2]="'Eingaben prüfen'"
	(onCallToAction)="saveReadingData(true)"
	(onCallToAction2)="formValidationError = null"
></mol-overlay>
<mol-overlay
	*ngIf="formValidationError === 'READING_PREVIOUS_READING_WITH_SMALLER_VALUE'"
	[type]="'information'"
	[headline]="'Es existiert bereits ein niedrigerer Zählerstand'"
	[subHeadline]="
		'Für den Zählerstand, den Sie eingeben möchten, gibt es bereits einen Zählerstand mit einem niedrigeren Wert zu einem späteren Ablesedatum. Bitte überprüfen Sie Ihre Angaben.'
	"
	[callToAction]="'Dennoch speichern'"
	[callToAction2]="'Eingaben prüfen'"
	(onCallToAction)="saveReadingData(true)"
	(onCallToAction2)="formValidationError = null"
></mol-overlay>
<mol-overlay
	*ngIf="formValidationError === 'READING_VALUE_IMPLAUSIBLE'"
	[type]="'information'"
	[headline]="'Zählerstand ungültig'"
	[subHeadline]="'Der von Ihnen erfasste Zählerstand scheint ungültig zu sein. Bitte überprüfen Sie Ihre Angaben.'"
	[callToAction]="'Dennoch speichern'"
	[callToAction2]="'Eingaben prüfen'"
	(onCallToAction)="saveReadingData(true)"
	(onCallToAction2)="formValidationError = null"
></mol-overlay>
