<ng-container *ngIf="showTemplate">
    <org-header></org-header>
    <div class="disclaimerPage">
        <h1 class="disclaimerPage__heading">Impressum</h1>
        <div class="disclaimerPage__section">
            <div class="container">
                <div class="row">
                    <div class="col-xs-4 col-xl-8 col-xl-push-2">
                        <div class="disclaimerPage__content">
                            <div class="disclaimerPage__contactDetails">
                                <address>
                                    <div class="disclaimerPage__contactDetails--address">
                                        <span>Lechwerke AG</span><br>
                                        Schaezlerstraße 3<br>
                                        86150 Augsburg<br>
                                        Deutschland
                                    </div>
                                    <div class="disclaimerPage__contactDetails--tel">
                                        <span>Tel.</span> 0821 328-0<br>
                                        Fax. 0821 328-1170
                                    </div>
                                    <div class="disclaimerPage__contactDetails--email">
                                        <a href="mailto:kontakt@lew.de">kontakt@lew.de </a>
                                    </div>
                                    <div>
                                        <br>Postanschrift: 86136 Augsburg
                                    </div>
                                </address>
                            </div>
                            <p><strong>Vorsitzender des Aufsichtsrats:</strong></p>
                            <p>Bernd Böddeling</p>
                            <p><strong>Vorstand:</strong></p>
                            <p>Dr. Markus Litpher<br>
                                Norbert Schürmann<br>
                                Sitz der Gesellschaft: Augsburg<br>
                                Handelsregister: HRB 6164, Amtsgericht Augsburg<br>
                                Umsatzsteueridentifikations-Nr.: DE 1274 70 129
                            </p>
                            <p><strong>Rechte von Haushaltskunden - Information gemäß § 40 Abs. 2 Ziffer 8 aus dem Energiewirtschaftsgesetz vom 04.08.2011 -  Fragen oder Beschwerden im Zusammenhang mit ihrer Energielieferung</strong> können von Verbrauchern an unseren Kundenservice per Post (Lechwerke AG, 86136 Augsburg), telefonisch (0800 539 539 1 kostenfrei) oder per E-Mail (service@lew.de) gerichtet werden.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <org-footer></org-footer>
</ng-container>
