<header>
	<section class="toolbar">
		<p class="logo">
			<a href="/oberguenzburg">
				<img class="region_logo" src="/assets/editorial/oberguenzburg/media/logo-kommune.svg" alt="Obergünzburg" />
			</a>
		</p>
		<ul class="toolbar_items">
			<li class="item-account">
				<a
					title="Wenn Sie bereits Kunde sind, schauen Sie hier in Ihr Kundenkonto"
					href="/oberguenzburg/login"
					class="account"
				>
					<span class="label">Kundenkonto</span>
				</a>
			</li>
		</ul>
	</section>
</header>
