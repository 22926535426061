var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { API_ROOT_URL } from './env';
import { Http } from './http';
import { LocalStorage } from './localstorage';
import { Region } from './region';
var IamApi = /** @class */ (function () {
    function IamApi() {
    }
    IamApi.login = function (email, password) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(API_ROOT_URL + "/api/iam/login", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                userId: email,
                                password: password,
                                tenantId: "lew"
                            })
                        })];
                    case 1:
                        res = _a.sent();
                        if (res.status !== Http.OKAY) {
                            // TODO(Mark): Provide more specific error handling
                            throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
                        }
                        return [2 /*return*/, res.json()];
                }
            });
        });
    };
    IamApi.changePassword = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(API_ROOT_URL + "/api/iam/changepassword", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/vnd.api+json",
                                Authorization: "Bearer " + IamService.getToken()
                            },
                            body: JSON.stringify({
                                newPassword: options.newPassword,
                                oldPassword: options.oldPassword
                            })
                        })];
                    case 1:
                        res = _a.sent();
                        if (res.status !== Http.CREATED && res.status !== Http.OKAY) {
                            // TODO(Mark): Provide more specific error handling
                            throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
                        }
                        return [2 /*return*/, res.json()];
                }
            });
        });
    };
    IamApi.resetPassword = function (region, email) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(API_ROOT_URL + "/api/iam/public/requestnewpassword", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                userId: email,
                                context: region,
                                tenantId: "lew"
                            })
                        })];
                    case 1:
                        res = _a.sent();
                        if (res.status !== Http.ACCEPTED) {
                            // TODO(Mark): Provide more specific error handling
                            throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    IamApi.setPasswort = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(API_ROOT_URL + "/api/iam/public/setnewpassword", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                userId: options.email,
                                password: options.password,
                                hash: options.hash,
                                tenantId: "lew"
                            })
                        })];
                    case 1:
                        res = _a.sent();
                        if (res.status !== Http.CREATED) {
                            // TODO(Mark): Provide more specific error handling
                            throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    IamApi.changeUserId = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            var res, failedReqBody, _i, _a, error, resBody;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, fetch(API_ROOT_URL + "/api/iam/changeuserid", {
                            method: "POST",
                            headers: {
                                Accept: "application/vnd.api+json",
                                Authorization: "Bearer " + IamService.getToken(),
                                "Content-Type": "application/vnd.api+json"
                            },
                            body: JSON.stringify({ email: email })
                        })];
                    case 1:
                        res = _b.sent();
                        if (!(res.status === Http.BAD_REQUEST)) return [3 /*break*/, 3];
                        return [4 /*yield*/, res.json()];
                    case 2:
                        failedReqBody = _b.sent();
                        for (_i = 0, _a = failedReqBody.errors; _i < _a.length; _i++) {
                            error = _a[_i];
                            if (error.code === "EMAIL_ALREADY_TAKEN") {
                                throw new Error("Diese Emailadresse existiert in unserem System schon!");
                            }
                        }
                        _b.label = 3;
                    case 3:
                        if (res.status !== Http.OKAY) {
                            throw new Error("Beim Ändern Ihrer Emailadresse ist ein Fehler aufgetreten. Das tut uns leid.");
                        }
                        return [4 /*yield*/, res.json()];
                    case 4:
                        resBody = _b.sent();
                        // After Emailchange, a new token is returned by the api and needs to be put in LocalStorage
                        IamService.login(resBody.token);
                        return [2 /*return*/];
                }
            });
        });
    };
    return IamApi;
}());
export { IamApi };
var IamService = /** @class */ (function () {
    function IamService() {
    }
    IamService.login = function (token) {
        LocalStorage.setItem("jwt_token", token);
        LocalStorage.setItem("jwt_token_time", Date.now().toString());
    };
    IamService.isLoggedIn = function () {
        var time = LocalStorage.getItem("jwt_token_time");
        if (time === null)
            return false;
        var diffMs = Date.now() - parseInt(time);
        var twoHoursMs = 1000 * 60 * 60 * 2;
        if (diffMs >= twoHoursMs)
            return false;
        return LocalStorage.getItem("jwt_token") !== null;
    };
    IamService.logout = function (hard) {
        if (hard === void 0) { hard = true; }
        LocalStorage.removeItem("jwt_token");
        LocalStorage.removeItem("jwt_token_time");
        // TODO(Mark): Cleanup
        if (hard) {
            location.href = location.protocol + "//" + location.host + "/" + Region.getCurrent();
        }
        else {
            // This only works sometimes (for example, when clicking the Logout button,
            // but not when an API call returns Unauthorized)
            history.pushState({}, "", "/" + Region.getCurrent());
        }
    };
    IamService.getToken = function () {
        return LocalStorage.getItem("jwt_token");
    };
    return IamService;
}());
export { IamService };
