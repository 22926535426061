/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./image.component";
var styles_ImageComponent = [i0.styles];
var RenderType_ImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageComponent, data: {} });
export { RenderType_ImageComponent as RenderType_ImageComponent };
function View_ImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "picture", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "source", [["media", "(min-width: 1400px)"]], [[8, "srcset", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "source", [["media", "(min-width: 1024px)"]], [[8, "srcset", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "source", [["media", "(min-width: 768px)"]], [[8, "srcset", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "source", [["media", "(min-width: 0px)"]], [[8, "srcset", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [], [[8, "className", 0], [8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.image == null) ? null : _co.image.xl); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.image == null) ? null : _co.image.lg); _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.image == null) ? null : _co.image.md); _ck(_v, 3, 0, currVal_2); var currVal_3 = ((_co.image == null) ? null : _co.image.xs); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_co.shadow ? "image shadow" : "image"); var currVal_5 = ((_co.image == null) ? null : _co.image.xs); var currVal_6 = ((_co.image == null) ? null : _co.image.alt); _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_ImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ato-image", [], null, null, null, View_ImageComponent_0, RenderType_ImageComponent)), i1.ɵdid(1, 49152, null, 0, i3.ImageComponent, [], null, null)], null, null); }
var ImageComponentNgFactory = i1.ɵccf("ato-image", i3.ImageComponent, View_ImageComponent_Host_0, { image: "image", shadow: "shadow" }, {}, []);
export { ImageComponentNgFactory as ImageComponentNgFactory };
