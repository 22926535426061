<div class="container" *ngIf="showTemplate">
    <div class="faq-section">
        <div class="row">
            <div class="col-xs-4 col-xl-2 col-xl-push-2">
                <h2 [innerHTML]="faqSection.headline" [ngClass]="{'active':(fragment && fragment.startsWith(faqSection.fragment))}"></h2>
            </div>
            <div class="col-xs-4 col-xl-6 col-xl-push-2">
                <div class="accordion" *ngFor="let faqTopic of faqSection.topics">
                    <input type="checkbox" name="accordion" [id]="faqTopic.fragment" [attr.checked]="fragment == faqTopic.fragment" (click)="changeTab(faqTopic.fragment)">
                    <label [for]="faqTopic.fragment"><span class="text" [innerHTML]="faqTopic.headline"></span>
                        <span class="toggle-icon"></span></label>
                    <section>
                        <p [innerHTML]="faqTopic.text"></p>
                    </section>
                </div>
            </div>
        </div>
        <div class="faq-section__border" [ngClass]="{'active':(fragment && fragment.startsWith(faqSection.fragment))}"></div>
    </div>
</div>
