import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {DataApi} from "@rem/data";

@Component({
    selector: "pag-client-portal",
    templateUrl: "./client-portal-page.component.html",
    styleUrls: ["./client-portal-page.component.scss"],
})

export class ClientPortalPageComponent implements OnInit, OnDestroy {
    public globalData: object;
    public editorialData: object;
    public showTemplateEditorial = false;
    public showTemplateGlobal = false;
    public whitelabel: string;

    private routeParams_: Subscription;

    constructor(
        private route: ActivatedRoute,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.routeParams_ = this.route.params.subscribe(async params => {
            this.whitelabel = params["region"];

            this.editorialData = await DataApi.getRegionData(this.whitelabel);
            this.showTemplateEditorial = true;
        });

        this.globalData = await DataApi.getGlobalData();
        this.showTemplateGlobal = true;
    }

    ngOnDestroy(): void {
        this.routeParams_.unsubscribe();
    }
}
