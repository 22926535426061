<ng-container *ngIf="showTemplate">
	<header *ngIf="whitelabel">
		<org-header></org-header>
	</header>

	<div class="agbPage">

		<section>
			<div class="container">
				<div class="row">
					<div class="col-xs-4 col-xl-8 col-xl-push-2">
						<div class="agbPage__content">
							<h1>AGB</h1>
							<h3>Allgemeine Nutzungsbedingungen für <span *ngIf="editorialData" [innerHTML]="editorialData.cityName"></span></h3>
							<p>
								Die Lechwerke AG bemüht sich im Rahmen des Zumutbaren, auf dieser Website richtige und
								vollständige Informationen zur Verfügung zu stellen. Die Lechwerke AG übernimmt jedoch keine
								Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der auf dieser Website
								bereitgestellten Informationen. Dies gilt auch für alle Verbindungen (<q>Links</q>), auf die diese Website direkt oder indirekt verweist. Die Lechwerke AG ist für den Inhalt einer Seite, die mit einem solchen Link erreicht wird, nicht verantwortlich.
							</p>
							<p>
								Die Lechwerke AG behält sich das Recht vor, ohne vorherige Ankündigung Änderungen oder
								Ergänzungen der bereitgestellten Informationen vorzunehmen.
							</p>
							<p>
								Der Inhalt dieser Website ist urheberrechtlich geschützt. Die Lechwerke AG gewährt Ihnen
								jedoch das Recht, den auf dieser Website bereitgestellten Text ganz oder ausschnittsweise zu speichern und zu
								vervielfältigen. Aus Gründen des Urheberrechts ist allerdings die Speicherung und Vervielfältigung von
								Bildmaterial oder Grafiken aus dieser Website nicht gestattet.
							</p>
							<p>
								Die Informationen auf dieser Website sind weder eine Aufforderung zum Kauf noch gelten sie als Aufforderung zum	sonstigen Handel mit Aktien.
							</p>
							<p>
								Die Lechwerke AG haftet nicht für direkte oder indirekte Schäden, einschließlich entgangenen
								Gewinns, die aufgrund von oder sonst wie in Verbindung mit Informationen entstehen, die auf dieser Website
								bereitgehalten werden.
							</p>
							<h3>Die folgenden Bedingungen sind ebenfalls Teil der regulären Vertragsvereinbarungen …</h3>
							<ul>
								<li *ngIf="this.currentRegion !== 'abensberg'">
									<a href="./assets/editorial/{{ this.currentRegion }}/media/downloads/Preis-und-Lieferbedingungen.pdf" title="Preis-und Lieferbedingungen">Preis-und Lieferbedingungen (PDF)</a>
								</li>
								<li *ngIf="this.currentRegion === 'abensberg'">
									<a href="./assets/editorial/abensberg/media/downloads/Ihr_Auftrag_Naturstrom_FIX.pdf" title="Preis-und Lieferbedingungen">Preis-und Lieferbedingungen: Naturstrom FIX (PDF)</a>
								</li>
								<li *ngIf="this.currentRegion === 'abensberg'">
									<a href="./assets/editorial/abensberg/media/downloads/Ihr_Auftrag_Naturstrom_FLEX.pdf" title="Preis-und Lieferbedingungen">Preis-und Lieferbedingungen: Naturstrom FLEX (PDF)</a>
								</li>
								<li>
									<a href="/assets/editorial/{{ this.currentRegion }}/media/downloads/Allgemeine-Stromlieferbedingungen-AGB.pdf" title="Allgemeine Stromlieferbedingungen">Allgemeine Stromlieferbedingungen (PDF)</a>
								</li>
								<li>
									<a href="./assets/editorial/{{ this.currentRegion }}/media/downloads/Datenschutzhinweise.pdf" title="Datenschutzhinweise">Datenschutzhinweise (PDF)</a>
								</li>
								<li>
									<a href="/assets/editorial/{{ this.currentRegion }}/media/downloads/Widerrufsbelehrung.pdf" title="Widerrufsbelehrung">Widerrufsbelehrung (PDF)</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
	<org-footer></org-footer>
</ng-container>
