/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../templates/login-template/login-template.component.ngfactory";
import * as i3 from "../../templates/login-template/login-template.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./login-page.component";
import * as i7 from "../../../services/seo.service";
var styles_LoginPageComponent = [i0.styles];
var RenderType_LoginPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginPageComponent, data: {} });
export { RenderType_LoginPageComponent as RenderType_LoginPageComponent };
function View_LoginPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tpl-login", [], null, null, null, i2.View_LoginTemplateComponent_0, i2.RenderType_LoginTemplateComponent)), i1.ɵdid(1, 245760, null, 0, i3.LoginTemplateComponent, [i4.ActivatedRoute], { globalData: [0, "globalData"], whitelabel: [1, "whitelabel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.globalData; var currVal_1 = _co.whitelabel; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_LoginPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loginPage"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginPageComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showTemplate && _co.showTemplateEditorial); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LoginPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pag-login", [], null, null, null, View_LoginPageComponent_0, RenderType_LoginPageComponent)), i1.ɵdid(1, 245760, null, 0, i6.LoginPageComponent, [i4.ActivatedRoute, i7.SeoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginPageComponentNgFactory = i1.ɵccf("pag-login", i6.LoginPageComponent, View_LoginPageComponent_Host_0, {}, {}, []);
export { LoginPageComponentNgFactory as LoginPageComponentNgFactory };
