<div class="regionalContent">
    <div class="regionalContent__background">
        <ato-video *ngIf="videoAvailable" [video]="data?.video" [poster]="data?.poster"></ato-video>

        <ato-image *ngIf="!videoAvailable" [image]="data?.image"></ato-image>

    </div>
    <div class="regionalContent__infobox" *ngIf="headlineVisible">
        <div class="regionalContent__infobox__headlineWrapper">
            <h1 class="regionalContent__infobox__headlineWrapper__title" [innerHTML]="stageHeader"></h1>
        </div>
    </div>
</div>
