<div class="container">
	<ato-loading-spinner [isRelative]="true" [loadingReady]="isLoaded"></ato-loading-spinner>
	<div class="row" *ngIf="isLoaded">
		<div class="header-extension">
			<p>
				Ihre Vorjahresverbrauchskurve wird anhand Ihres Vorjahresverbrauchs und dem Standardlastprofil für private Haushalte
				ermittelt. Ihren Monatsverbrauch ermitteln wir aus den von Ihnen angegebenen Zählerständen und dem
				Standardlastprofil.
			</p>
		</div>
		<div id="barchart"></div>
		<div class="legend-extension">
			<p>
				<svg viewBox="0 0 16 11" width="16" height="11">
					<g>
						<path d="M7.5,0 L15,11 L0,11 Z" fill="#A0332D"></path>
					</g>
				</svg>
				Ihre eigenen Erfassungen
			</p>
		</div>
	</div>
</div>

<mol-overlay
	*ngIf="validationError !== null"
	[type]="'error'"
	[headline]="'Die Verbrauchsübersicht kann nicht erstellt werden'"
	[subHeadline]="validationError.subHeadline"
	(onCallToAction)="validationError = null"
>
</mol-overlay>
