<div class="contractView" *ngIf="product">
    <h2 class="contractView__headline">{{ product.productName }}</h2>
    <div class="contractView__row">
        <div class="contractView__priceSection">
            <div class="contractView__monthlyPrice">
                {{ splitMonthlyDeposit(0) }}
                <div class="contractView__monthlyPrice__cent">
                    , {{ splitMonthlyDeposit(1) }}
                    <div class="contractView__monthlyPrice__cent__monthly">
                        € / Monat
                    </div>
                </div>
            </div>
            <div class="contractView__usageSummary">
                <dl class="contractView__usageSummary__list">
                    <dt class="contractView__usageSummary__listitem">Verbrauch</dt>
                    <dd class="contractView__usageSummary__listitem__definition">
                        <strong>{{ consumption }} kWh / Jahr</strong></dd>
                    <dt class="contractView__usageSummary__listitem">Grundpreis</dt>
                    <dd class="contractView__usageSummary__listitem__definition">{{ currencyService.format(product.basePricePerMonth) }}
                        / Monat</dd>
                    <dt class="contractView__usageSummary__listitem">Arbeitspreis</dt>
                    <dd class="contractView__usageSummary__listitem__definition">
                        {{ currencyService.roundTwoDecPlaces(product.workingPricePerKWh * 100) }} Ct / kWh</dd>
                </dl>
                <p class="contractView__usageSummary__explanationText">Preis inkl. aller Steuern und Umlagen</p>
            </div>
        </div>
        <div class="contractView__factSummary">
            <ul class="contractView__factSummary__list" *ngFor="let feature of this.product.productFeatures">
                <li class="contractView__factSummary__listitem">
                    <svg class="contractView__factSummary__listitem__checkmark">
                        <use xlink:href="#listcheckmark" />
                    </svg>
                    <span [innerHTML]="feature"></span>
                </li>
            </ul>
        </div>
    </div>
    <div class="contractView__badgeArea">
        <ul class="contractView__badgeArea__list">
            <li class="contractView__badgeArea__listitem">
                <svg class="contractView__badgeArea__svg" xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                    viewBox="0 0 36 36">
                    <path fill-rule="nonzero"
                        d="M29.912 18.936l-3.087.961.293 3.223-3.222-.294-.962 3.087-2.863-1.502-2.07 2.485-2.072-2.486-2.864 1.502-.962-3.086-3.222.294.293-3.223-3.087-.961L7.59 16.07 5.105 14l2.484-2.07-1.502-2.865 3.087-.962-.293-3.222 3.222.293.962-3.087 2.865 1.502L18 1.105l2.071 2.484 2.863-1.502.962 3.087 3.222-.293-.293 3.222 3.087.962-1.501 2.865L30.896 14l-2.485 2.071 1.501 2.865zm-2.885-1.196l-1.138-2.17L27.77 14l-1.882-1.567 1.138-2.172-2.34-.729.222-2.442-2.442.222-.728-2.34-2.17 1.138L18 4.228 16.431 6.11l-2.17-1.138-.729 2.34-2.442-.222.222 2.442-2.34.729 1.138 2.172L8.23 14l1.881 1.568-1.138 2.17 2.34.73-.222 2.44 2.442-.222.729 2.34 2.17-1.138 1.57 1.883 1.568-1.883 2.17 1.138.728-2.34 2.442.223-.222-2.441 2.34-.73zm.72 4.27l1.732-1 5.568 9.64-6.408-1.085-2.22 6.096-4.62-7.801 1.721-1.02 2.466 4.165 1.343-3.69 3.86.653-3.442-5.957zM.954 30.65l5.565-9.64 1.732 1-3.44 5.958 3.86-.653 1.343 3.69 2.466-4.164 1.72 1.019-4.62 7.801-2.218-6.096L.954 30.65zm16.075-14.644l4.58-6.134 1.602 1.196-5.962 7.986-4.366-4.366 1.414-1.414 2.732 2.732z" />
                </svg>
                <p class="contractView__badgeArea__text">
                    100% aus regenerativen Quellen</p>
            </li>
            <li class="contractView__badgeArea__listitem">
                <svg class="contractView__badgeArea__svg" xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                    viewBox="0 0 36 36">
                    <path fill-rule="nonzero"
                        d="M22.126 15.286l-2.952 2.952h2.92v2h-4.92l-2.951 2.952h4.776v2h-6.776l-2.724 2.725c8.169 4.733 13.767.349 17.563-10.353.981-2.768 1.745-5.743 2.315-8.72.184-.963.336-1.862.457-2.677-.815.121-1.714.273-2.677.457-2.977.57-5.952 1.334-8.721 2.315C7.732 12.733 3.348 18.331 8.084 26.501l2.725-2.725V17h2v4.776l2.952-2.953v-4.918h2v2.918l2.953-2.953v-2.441h2v1.857h1.857v2h-2.445zM8.04 29.374l-1.183 1.183-1.414-1.414 1.182-1.183C.637 18.183 5.848 11.28 17.767 7.052c2.874-1.019 5.945-1.807 9.014-2.394a66.728 66.728 0 0 1 2.967-.501 46.474 46.474 0 0 1 1.142-.151l1.24-.136-.137 1.24-.036.305c-.03.242-.069.522-.114.837-.13.896-.296 1.893-.502 2.966-.587 3.07-1.375 6.14-2.394 9.013C24.72 30.148 17.817 35.359 8.04 29.374z" />
                </svg>
                <p class="contractView__badgeArea__text">
                    <span>{{ product.savedCO2Kgs }} kg </span>CO<sub>2</sub>-Einsparung</p>
            </li>
            <li class="contractView__badgeArea__listitem">
                <svg class="contractView__badgeArea__svg" xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                    viewBox="0 0 36 36">
                    <path fill-rule="nonzero"
                        d="M18 17.082c1.657 0 3-1.351 3-3.018a3.009 3.009 0 0 0-3-3.017c-1.657 0-3 1.35-3 3.017a3.009 3.009 0 0 0 3 3.018zm0 2.012c-2.761 0-5-2.252-5-5.03 0-2.777 2.239-5.029 5-5.029s5 2.252 5 5.03c0 2.777-2.239 5.029-5 5.029zm8.99-4.607c.007-.14.01-.281.01-.423 0-5-4.03-9.052-9-9.052s-9 4.053-9 9.052c0 .142.003.283.01.424.14 3.079 3.135 8.594 8.99 16.249 5.856-7.656 8.85-13.171 8.99-16.25zM18 34C10.853 24.968 7.19 18.495 7.012 14.58A11.317 11.317 0 0 1 7 14.064C7 7.954 11.925 3 18 3s11 4.954 11 11.064c0 .173-.004.344-.012.515C28.81 18.493 25.148 24.967 18 34z" />
                </svg>
                <p class="contractView__badgeArea__text">
                    Lokal erzeugt und lokal verbraucht</p>
            </li>
            <li class="contractView__badgeArea__listitem">
                <svg class="contractView__badgeArea__svg" xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                    viewBox="0 0 36 36">
                    <path fill-rule="nonzero"
                        d="M5.746 19.74l3.415-4.195 1.603 1.348-5.758 7.072L0 16.319l1.732-1.171 1.911 2.92c.31-6.4 4.838-12.155 11.145-13.681 5.057-1.228 10.28.526 13.66 4.443l-1.564 1.393C24.007 6.89 19.569 5.4 15.271 6.443 9.362 7.873 5.265 13.666 5.746 19.74zm26.282-.147c-.577 5.91-4.95 11.188-10.82 12.61-4.726 1.146-9.617-.31-13.014-3.742l1.466-1.5c2.892 2.923 7.048 4.16 11.066 3.185 5.213-1.262 9.06-6.134 9.276-11.412l-3.163 3.884-1.603-1.348 5.76-7.071L36 21.845l-1.732 1.17-2.24-3.422zm-10.276 1.381l.752 1.47c-.381.41-.755.724-1.12.94-.695.41-1.47.616-2.324.616-1.504 0-2.661-.578-3.47-1.735a5.391 5.391 0 0 1-.804-1.77 7.282 7.282 0 0 1-.188-1.153H13v-1.017h1.59v-.957H13V16.35h1.7a5.41 5.41 0 0 1 .915-2.47c.86-1.253 2.106-1.88 3.735-1.88.781 0 1.49.197 2.129.59.307.188.658.504 1.05.948l-.785 1.59c-.4-.478-.738-.795-1.018-.949a2.628 2.628 0 0 0-1.316-.333c-.769 0-1.384.274-1.846.82-.37.44-.587 1-.65 1.684h4.428l-.436 1.018h-4.103v.957h3.753l-.41 1.017h-3.257c.125 1.31.584 2.17 1.376 2.581.308.16.68.24 1.12.24.478 0 .903-.097 1.273-.291.274-.143.638-.442 1.094-.898z" />
                </svg>
                <p class="contractView__badgeArea__text">
                    Wertschöpfung in der Region</p>
            </li>
        </ul>
    </div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <defs>
        <path id="listcheckmark" fill-rule="nonzero"
            d="M27.639 10L29 11.347 14.11 26 7 19.033l1.358-1.35 5.75 5.634z" />
    </defs>
</svg>
