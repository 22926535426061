import {ActivatedRoute} from "@angular/router";
import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RegExpConstants} from "@p2p/constants/constants";
import {Subscription} from "rxjs";
import {IamApi} from "@rem/iam";

@Component({
    selector: "org-password-reset-set",
    templateUrl: "./password-reset-set.component.html",
    styleUrls: ["./password-reset-set.component.scss"],
})

export class PasswordResetSetComponent implements OnInit, OnDestroy {
    @Input() public passwordResetSetData: any;
    @Input() public errorMessages: any;
    @Input() public whitelabel: string;
    public passwordResetSetSuccess = false;
    public passwordResetSetErrorText: string;
    public value: object;

    // Form Group and Validators
    public passwordResetSetFormGroup: FormGroup = new FormGroup({
        passwordResetEmail: new FormControl("", [
            Validators.required,
            Validators.email,
        ]),
        passwordResetToken: new FormControl("", [
            Validators.required,
        ]),
        passwordResetSetPassword: new FormControl("", [
            Validators.required,
            Validators.pattern(RegExpConstants.PASSWORD_REGEXP),
        ]),
        passwordResetSetPassword2: new FormControl("", [
            Validators.required,
        ]),
    });

    private routeQuery_: Subscription;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.routeQuery_ = this.route.queryParams.subscribe(query => {
            let email = decodeURIComponent(query.userId);
            this.passwordResetSetFormGroup.get("passwordResetEmail").setValue(email);
            this.passwordResetSetFormGroup.get("passwordResetToken").setValue(query.hash);
        });
    }

    ngOnDestroy(): void {
        this.routeQuery_.unsubscribe();
    }

    validatePassword(): boolean {
        return !(this.passwordResetSetFormGroup.get("passwordResetSetPassword").invalid ||
            this.passwordResetSetFormGroup.get("passwordResetSetPassword2").invalid ||
            this.passwordResetSetFormGroup.get("passwordResetSetPassword").value !== this.passwordResetSetFormGroup.get("passwordResetSetPassword2").value);
    }

    async onSubmit(): Promise<void> {
        if (!this.validatePassword()) {
            return;
        }

        try {
            await IamApi.setPasswort({
                email: this.passwordResetSetFormGroup.get("passwordResetEmail").value,
                password: this.passwordResetSetFormGroup.get("passwordResetSetPassword").value,
                hash: this.passwordResetSetFormGroup.get("passwordResetToken").value,
            });
            this.passwordResetSetSuccess = true;
        } catch(err) {
            console.error(err);
            this.passwordResetSetErrorText = err.message;
        }
    }
}
