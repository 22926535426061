<div class="contactFormV2" *ngIf="showTemplateGlobal && showTemplateEditorial">
	<div class="container">
		<div class="row">
			<div class="col-xs-4 col-xl-12">
				<form class="contactFormV2__form" [formGroup]="contactForm" (ngSubmit)="onSubmit()" #multiStepForm="ngForm">
					<div class="row">
						<div class="col-xs-4 col-sm-4 col-md-8 col-lg-4 col-xl-5">
							<div class="contactFormV2__form__item">
								<label class="contactFormV2__form__label required" [innerHTML]="formData.salutation.label"></label>
								<div class="contactFormV2__form__radio">
									<input type="radio" value="MISS" id="salutation_ms" name="salutation" formControlName="salutation" />
									<span class="contactFormV2__form__radio__icon"></span>
									<label
										class="contactFormV2__form__radio__label"
										for="salutation_ms"
										[innerHTML]="formData.salutation.female"
									></label>
								</div>
								<div class="contactFormV2__form__radio">
									<input type="radio" value="MISTER" id="salutation_mr" name="salutation" formControlName="salutation" />
									<span class="contactFormV2__form__radio__icon"></span>
									<label
										class="contactFormV2__form__radio__label"
										for="salutation_mr"
										[innerHTML]="formData.salutation.male"
									></label>
								</div>
								<div
									class="contactFormV2__form__error"
									*ngIf="contactForm.get('salutation').invalid && contactForm.get('salutation').touched"
								>
									{{ errorMessages.validation.salutation }}
								</div>
							</div>
							<div class="contactFormV2__form__item">
								<label
									class="contactFormV2__form__label required"
									for="firstname"
									[innerHTML]="formData.name.firstName"
								></label>
								<input type="text" id="firstname" formControlName="firstname" [placeholder]="formData.name.firstName" />
								<div
									class="contactFormV2__form__error"
									*ngIf="contactForm.get('firstname').invalid && contactForm.get('firstname').touched"
								>
									{{ errorMessages.validation.firstName }}
								</div>
							</div>
							<div class="contactFormV2__form__item">
								<label class="contactFormV2__form__label required" for="lastname" [innerHTML]="formData.name.lastName"></label>
								<input type="text" id="lastname" formControlName="lastname" [placeholder]="formData.name.lastName" />
								<div
									class="contactFormV2__form__error"
									*ngIf="contactForm.get('lastname').invalid && contactForm.get('lastname').touched"
								>
									{{ errorMessages.validation.lastName }}
								</div>
							</div>
							<div class="contactFormV2__form__item">
								<label class="contactFormV2__form__label" for="company">Firma</label>
								<input type="text" id="company" formControlName="company" placeholder="Firma" />
							</div>
							<div class="contactFormV2__form__item">
								<label class="contactFormV2__form__label required" for="email" [innerHTML]="formData.contact.email"></label>
								<input type="email" id="email" formControlName="email" [placeholder]="formData.contact.email" />
								<div
									class="contactFormV2__form__error"
									*ngIf="contactForm.get('email').invalid && contactForm.get('email').touched"
								>
									{{ errorMessages.validation.email }}
								</div>
							</div>
							<div class="contactFormV2__form__item">
								<label class="contactFormV2__form__label" for="tel" [innerHTML]="formData.contact.phone"></label>
								<input type="tel" id="tel" formControlName="phone" [placeholder]="formData.contact.phone" />
								<div
									class="contactFormV2__form__error"
									*ngIf="contactForm.get('phone').invalid && contactForm.get('phone').touched"
								>
									{{ errorMessages.validation.phone }}
								</div>
								<div class="contactFormV2__form__input-hint" [innerHTML]="globalData.ovaForm.contact.phoneText"></div>
							</div>
						</div>
						<div class="col-xs-4 col-sm-4 col-md-8 col-lg-4 col-xl-6 col-xl-push-1 column-spacing">
							<div class="contactFormV2__form__item">
								<label class="contactFormV2__form__label required" for="productiontype">Worum geht es?</label>
								<div class="contactFormV2__form__select">
									<select name="productiontype" id="productiontype" formControlName="topic">
										<option value="" disabled>Bitte auswählen …</option>
										<option *ngFor="let option of globalData.contactFormOptions" [value]="option.value">{{
											option.value
										}}</option>
									</select>
								</div>
								<div
									class="contactFormV2__form__error"
									*ngIf="contactForm.get('topic').invalid && contactForm.get('topic').touched"
								>
									{{ errorMessages.validation.topic }}
								</div>
							</div>
							<div class="contactFormV2__form__item">
								<label class="contactFormV2__form__label required" for="message">Ihre Nachricht an uns</label>
								<textarea name="message" id="message" cols="30" rows="10" formControlName="message"></textarea>
								<div
									class="contactFormV2__form__error"
									*ngIf="contactForm.get('message').invalid && contactForm.get('message').touched"
								>
									{{ errorMessages.validation.message }}
								</div>
							</div>
							<div class="contactFormV2__form__item">
								<span class="contactFormV2__form__checkbox">
									<label
										class="contactFormV2__form__checkbox__label required"
										for="dataprotection"
										[innerHTML]="editorialData.contactForm.legalDisclaimer"
									></label>
									<input
										type="checkbox"
										formControlName="tosAccepted"
										name="tosAccepted"
										id="dataprotection"
										class="contactFormV2__form__accordion__opener"
									/>
									<span class="contactFormV2__form__checkbox__icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" focusable="false">
											<path fill-rule="nonzero" d="M27.639 10L29 11.347 14.11 26 7 19.033l1.358-1.35 5.75 5.634z" />
										</svg>
									</span>
								</span>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-4 col-sm-4 col-md-8 col-lg-8 col-xl-12">
							<div class="contactFormV2__form__center formactions">
								<button *ngIf="!this.isWaiting" class="button preferred" type="submit" [disabled]="contactForm.invalid">
									{{ globalData.contactForm.sendBtn }}
								</button>
								<button *ngIf="this.isWaiting" class="button preferred waiting" type="submit" [disabled]="contactForm.invalid">
									{{ globalData.contactForm.sendBtn }}
								</button>
							</div>
							<div class="contactFormV2__form__hint">
								<p [innerHTML]="globalData.contactForm.requiredHint"></p>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
<div class="info-wrapper">
	<mol-contact-info [contactInfoData]="fromCsc ? editorialData.csc.contact : editorialData.landingPage.contact" [whitelabel]="whitelabel" [showContactFormLink]="false"></mol-contact-info>
</div>
<mol-overlay
	*ngIf="contactCreated"
	[type]="'success'"
	[headline]="'Vielen Dank!'"
	[subHeadline]="'Wir haben Ihre Nachricht erhalten und melden uns umgehend bei Ihnen.'"
	(onCallToAction)="navigateBack()"
></mol-overlay>
<mol-overlay
	*ngIf="contactError"
	[type]="'error'"
	[headline]="'Oh je, etwas ist schief gelaufen.'"
	[subHeadline]="
		'Es ist ein Problem aufgetreten. Bitte versuchen Sie es noch einmal, oder kontaktieren Sie uns telefonisch, wir helfen Ihnen gerne weiter.'
	"
	(onCallToAction)="contactError = false"
></mol-overlay>
