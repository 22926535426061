/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./producers-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./producers-map.component";
var styles_ProducersMapComponent = [i0.styles];
var RenderType_ProducersMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProducersMapComponent, data: {} });
export { RenderType_ProducersMapComponent as RenderType_ProducersMapComponent };
export function View_ProducersMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "section", [["class", "producers-map"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["id", "producersmap"]], null, null, null, null, null))], null, null); }
export function View_ProducersMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-producers-map", [], null, null, null, View_ProducersMapComponent_0, RenderType_ProducersMapComponent)), i1.ɵdid(1, 114688, null, 0, i2.ProducersMapComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProducersMapComponentNgFactory = i1.ɵccf("org-producers-map", i2.ProducersMapComponent, View_ProducersMapComponent_Host_0, {}, {}, []);
export { ProducersMapComponentNgFactory as ProducersMapComponentNgFactory };
