<div class="ovaSuccess">
    <div class="ovaSuccess__grid">
        <div class="container">
            <div class="row">
                <div class="col-xs-4 col-md-8 col-lg-8 col-xl-12 ovaSuccess__grid__header">
                    <h2 class="ovaSuccess__grid__header__headline" [innerHTML]="editorialData.ovaSuccess.headline"></h2>
                    <p  *ngIf="!noconfirmation" class="ovaSuccess__grid__header__subline" [innerHTML]="editorialData.ovaSuccess.subline"></p>
                    <p  *ngIf="noconfirmation" class="ovaSuccess__grid__header__subline" [innerHTML]="editorialData.ovaSuccess.sublineNoConfirmation"></p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-md-8 col-lg-8 col-xl-12 ovaSuccess__grid__content">
                    <h3 class="ovaSuccess__grid__content__title" [innerHTML]="editorialData.ovaSuccess.steps.title"></h3>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" class="icon">
                        <g fill="none" fill-rule="evenodd" transform="translate(2 2)">
                            <path d="M24.333 39.653v-2.94l5.298-.222.068-12.253.274-.872.376-.58-.325-.223-.479.769-.324.308-4.94 2.82L23 23.76 31.118 18h3.025a49.137 49.137 0 0 0-.24 4.546V36.49l4.46.222v2.94h-14.03z"/>
                            <circle cx="30.45" cy="30.45" r="30.45" fill-rule="nonzero" stroke-width="3.5"/>
                        </g>
                    </svg>
                    <p [innerHTML]="editorialData.ovaSuccess.steps.step1"></p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="66" viewBox="0 0 65 66" class="icon">
                        <g fill="none" fill-rule="evenodd" transform="translate(2 2.667)">
                            <path d="M38.08 40H23.296v-2.769c1.572-1.162 2.865-2.227 3.879-3.195 3.953-3.783 5.93-6.853 5.93-9.212 0-.797-.205-1.43-.615-1.897-.49-.558-1.156-.837-2-.837-1.72 0-3.304.866-4.75 2.598l-2.103-2.359c.923-1.242 1.755-2.125 2.496-2.649 1.446-1.014 3.133-1.52 5.058-1.52 2.94 0 4.876 1.218 5.81 3.656.297.798.445 1.641.445 2.53 0 1.538-.439 3.116-1.316 4.734-1.07 1.982-2.643 3.833-4.717 5.554-.08.057-.575.461-1.487 1.213l-.803.445-.615.222.103.307.683-.239.786-.137 7.998.171V40z"/>
                            <circle cx="30.45" cy="30.45" r="30.45" fill-rule="nonzero" stroke-width="3.5"/>
                        </g>
                    </svg>
                    <p [innerHTML]="editorialData.ovaSuccess.steps.step2"></p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" class="icon">
                        <g fill="none" fill-rule="evenodd" transform="translate(2 2.333)">
                            <path d="M24.578 41.401l-.82-3.332 1.196.034c2.37.068 4.41-.313 6.119-1.145 1.446-.706 2.17-1.675 2.17-2.905 0-1.994-1.63-2.991-4.888-2.991-.58 0-1.475.04-2.683.12v-3.52l1.35.016c2.917.034 4.802-.66 5.657-2.085.25-.433.376-.894.376-1.384 0-1.094-.467-1.8-1.401-2.12-.354-.113-.82-.17-1.402-.17-1.048 0-2.09.177-3.127.53-.684.228-1.533.65-2.547 1.264l-1.11-3.076c1.116-.74 2.13-1.276 3.042-1.606 1.56-.57 3.138-.855 4.733-.855 2.746 0 4.546.991 5.4 2.974.308.718.462 1.453.462 2.204 0 1.368-.455 2.558-1.367 3.572-.775.866-1.846 1.498-3.213 1.897h-.7v.325h.7c1.595.262 2.797.746 3.606 1.453 1.048.911 1.572 2.102 1.572 3.571 0 2.051-.934 3.732-2.802 5.042-2.074 1.458-5.116 2.187-9.126 2.187h-1.197z"/>
                            <circle cx="30.45" cy="30.45" r="30.45" fill-rule="nonzero" stroke-width="3.5"/>
                        </g>
                    </svg>
                    <p [innerHTML]="editorialData.ovaSuccess.steps.step3"></p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" class="icon">
                        <g fill="none" fill-rule="evenodd" transform="translate(2 2)">
                            <path d="M36.054 40h-4.016v-3.88h-8.92V33.49l6.51-15.142h4.324l-6.17 13.911-.58.684-.342.205.17.256.428-.273.77-.188h3.844l1.282-9.468h2.7v9.468h2.154v3.179h-2.154z"/>
                            <circle cx="30.45" cy="30.45" r="30.45" stroke-width="3.5"/>
                        </g>
                    </svg>
                    <h4 [innerHTML]="editorialData.ovaSuccess.steps.step4"></h4>
                    <img [alt]="editorialData.ovaSuccess.image.alt" [src]="editorialData.ovaSuccess.image.src" [srcset]="editorialData.ovaSuccess.image.srcset">
                    <p>
                        <a [routerLink]="['/' + whiteLabel]" class="ovaSuccess__button">
                            {{ editorialData.ovaSuccess.button }}
                            <svg class="ovaSuccess__button__icon" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                <path fill-rule="nonzero" d="M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"/>
                            </svg>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
