/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subheader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./subheader.component";
var styles_SubheaderComponent = [i0.styles];
var RenderType_SubheaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubheaderComponent, data: {} });
export { RenderType_SubheaderComponent as RenderType_SubheaderComponent };
export function View_SubheaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "subheader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "h1", [["class", "subheader__title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isLoggedIn ? _co.subheaderData.kundenportal : _co.subheaderData.login); _ck(_v, 1, 0, currVal_0); }); }
export function View_SubheaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-subheader", [], null, null, null, View_SubheaderComponent_0, RenderType_SubheaderComponent)), i1.ɵdid(1, 49152, null, 0, i2.SubheaderComponent, [], null, null)], null, null); }
var SubheaderComponentNgFactory = i1.ɵccf("org-subheader", i2.SubheaderComponent, View_SubheaderComponent_Host_0, { subheaderData: "subheaderData" }, {}, []);
export { SubheaderComponentNgFactory as SubheaderComponentNgFactory };
