<div class="passwordResetForm">
	<form
		*ngIf="!passwordResetSuccess"
		class="passwordResetForm__form"
		[formGroup]="passwordResetFormGroup"
		(ngSubmit)="onSubmit()"
		#passwordResetForm="ngForm"
	>
		<fieldset class="passwordResetForm__form__fieldset">
			<h3 class="passwordResetForm__headline">{{ passwordResetData.headline }}</h3>
			<div class="passwordResetForm__info">
				<p>{{ passwordResetData.info }}</p>
			</div>
			<div class="formfield flyinglabel">
				<input
					type="email"
					id="passwordResetEmail"
					name="passwordResetEmail"
					formControlName="passwordResetEmail"
					placeholder="E-Mail-Adresse"
					autocomplete="username"
				/>
				<label
					class="passwordResetForm__form__label required"
					for="email"
					[innerHTML]="passwordResetData.labelEmail"
				></label>
			</div>
			<div
				class="passwordResetForm__form__error"
				*ngIf="
					(passwordResetFormGroup.get('passwordResetEmail').invalid &&
						passwordResetFormGroup.get('passwordResetEmail').touched) ||
					passwordResetErrorText
				"
			>
				{{ errorMessages.validation.email }}
			</div>
			<p>
				<button class="passwordResetForm__form__button" type="submit">{{ passwordResetData.submitButton }}</button>
			</p>
			<div class="passwordResetForm__help">
				<p>
					{{ passwordResetData.help }}
				</p>
			</div>
		</fieldset>
	</form>

	<div *ngIf="passwordResetSuccess" class="passwordResetForm__password-reset-success" id="password-reset-success">
		<div class="success-check">
			<svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58">
				<g fill="none" fill-rule="nonzero" transform="translate(2 2)">
					<circle cx="27" cy="27" r="27" stroke="#86A449" stroke-width="3" />
					<path fill="#86A449" d="M41.458 15l2.042 2.02L21.165 39 10.5 28.55l2.037-2.024 8.624 8.45z" />
				</g>
			</svg>
		</div>
		<h2>{{ passwordResetData.titleEmailSent }}</h2>
		<p>
			{{ passwordResetData.labelEmailSent1 }}
			<span>{{ passwordResetFormGroup.get("passwordResetEmail").value }}</span>
			{{ passwordResetData.labelEmailSent2 }}
		</p>
		<p>
			<button
				[routerLink]="['/' + whitelabel, 'login']"
				routerLinkActive="active"
				class="passwordResetForm__form__button"
				type="button"
			>
				{{ passwordResetData.ok }}
			</button>
		</p>
	</div>
</div>
