import {Router} from "@angular/router";
import {Component, Input, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RegExpConstants} from "@p2p/constants/constants";
import {IamApi, IamService} from "@rem/iam";

@Component({
    selector: "org-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    @Input() loginData: any;
    @Input() errorMessages: any;
    @Input() whitelabel: string;
    loginErrorText: string;
    value: object;
    isLoaded: boolean = true;

    // Form Group and Validators
    public loginFormGroup: FormGroup = new FormGroup({
        email: new FormControl("", [Validators.required, Validators.pattern(RegExpConstants.EMAIL_REGEXP)]),
        password: new FormControl("", [Validators.required]),
    });

    constructor(private router: Router) {
    }

    async ngOnInit(): Promise<void> {
        // TODO(Mark): Check whether token still valid / still logged in, and if so, redirect to portal
    }

    async onSubmit(): Promise<void> {
        try {
            this.isLoaded = false;
            let email = this.loginFormGroup.value.email;
            let password = this.loginFormGroup.value.password;
            let {token} = await IamApi.login(email, password);
            IamService.login(token);
            this.router.navigateByUrl(`/${this.whitelabel}/kundenportal`);
        } catch (err) {
            this.loginErrorText = err.message;
            this.isLoaded = true;
        }
    }
}
