export type RegionName = ReturnType<typeof Region.getCurrent>;

export class Region {
	static getCurrent() {
		let region = location.pathname.split("/")[1];
		switch (region) {
			case "oberguenzburg":
			// case "landkreis-bamberg":
			// case "pfettrachtal":
			// case "bemo":
			// case "calenberger-land":
			// case "marienbachtal":
			// case "viechtach":
				return region;
		}
	}
}
