import {Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID} from "@angular/core";
import {isPlatformServer} from "@angular/common";

@Component({
    selector: "mol-overlay",
    templateUrl: "./overlay.component.html",
    styleUrls: ["./overlay.component.scss"],
})
export class OverlayComponent implements OnInit, OnDestroy {
    @Input() public type: "success" | "error" | "information";
    @Input() public headline: string;
    @Input() public subHeadline: string;
    @Input() public brandingColor: string;

    @Input() public callToAction = "Alles klar";
    @Input() public callToAction2 = undefined;
    @Input() public showCloseButton = false;

    @Input() public absolute = false;

    @Output() public onCallToAction: EventEmitter<any> = new EventEmitter();
    @Output() public onCallToAction2: EventEmitter<any> = new EventEmitter();
    @Output() public onCloseAction: EventEmitter<any> = new EventEmitter();
    private element: any;
    public isMac = false;

    constructor(
        private elementRef: ElementRef,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {
        this.element = elementRef.nativeElement;
    }

    ngOnInit(): void {
        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        if (!this.absolute) {
            document.body.appendChild(this.element);
        }
        // modify position of the close button
        if (!isPlatformServer(this.platformId)) {
            if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
                this.isMac = true;
            }
        }
    }

    ngOnDestroy(): void {
        // remove self from modal service when directive is destroyed
        this.element.outerHTML = "";
    }
}
