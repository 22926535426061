/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./google-opt-out-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./google-opt-out-button.component";
import * as i3 from "ngx-cookie-service";
var styles_GoogleOptOutButtonComponent = [i0.styles];
var RenderType_GoogleOptOutButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GoogleOptOutButtonComponent, data: {} });
export { RenderType_GoogleOptOutButtonComponent as RenderType_GoogleOptOutButtonComponent };
export function View_GoogleOptOutButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["optOutButton", 1]], null, 1, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleOptOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.optOutNeeded; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.optOutNeeded ? ((_co.globalData == null) ? null : ((_co.globalData.optOutButton == null) ? null : _co.globalData.optOutButton.defaultLabel)) : ((_co.globalData == null) ? null : ((_co.globalData.optOutButton == null) ? null : _co.globalData.optOutButton.deactivatedLabel))); _ck(_v, 1, 0, currVal_1); }); }
export function View_GoogleOptOutButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ato-google-opt-out-button", [], null, null, null, View_GoogleOptOutButtonComponent_0, RenderType_GoogleOptOutButtonComponent)), i1.ɵdid(1, 114688, null, 0, i2.GoogleOptOutButtonComponent, [i3.CookieService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GoogleOptOutButtonComponentNgFactory = i1.ɵccf("ato-google-opt-out-button", i2.GoogleOptOutButtonComponent, View_GoogleOptOutButtonComponent_Host_0, {}, {}, []);
export { GoogleOptOutButtonComponentNgFactory as GoogleOptOutButtonComponentNgFactory };
