import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {SeoService} from "@p2p/services/seo.service";
import {DataApi} from "@rem/data";

@Component({
    selector: "pag-ova",
    templateUrl: "./ova-page.component.html",
    styleUrls: ["./ova-page.component.scss"],
})
export class OvaPageComponent implements OnInit, OnDestroy {

    public globalData: any;
    public editorialData: any;
    public showTemplateGlobal = false;
    public showTemplateEditorial = false;
    public whitelabel: string;

    private routeParams_: Subscription;

    constructor (
        public route: ActivatedRoute,
        private seoService: SeoService
    ) { }

    async ngOnInit(): Promise<void> {
        this.routeParams_ = this.route.params.subscribe(async params => {
            this.whitelabel = params["region"];

            this.editorialData = await DataApi.getRegionData(this.whitelabel);
            if (this.editorialData && this.editorialData.pageMetas) {
                this.seoService.setTitle(this.editorialData.pageMetas.default.title);
                this.seoService.updateMetaTags(this.editorialData.pageMetas.default.metaData);
            }

            this.showTemplateEditorial = true;
        });

        this.globalData = await DataApi.getGlobalData();
        this.showTemplateGlobal = true;
    }

    ngOnDestroy(): void {
        this.routeParams_.unsubscribe();
    }
}
