var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from "@angular/core";
import { ProvidersApi } from "@rem/provider";
import { RegionsApi } from "@rem/regions";
import { Region as CurrentRegion } from "@rem/region";
import { CityApi } from "@rem/city";
import { initInputs, initDivs, initSelects, initFieldsets, initSections } from "./ova-form.elements";
import { OrderApi } from "@rem/order";
import { Router, ActivatedRoute } from "@angular/router";
import * as iban from "iban";
import { CustomerService } from "@rem/customer-service";
import { API_ROOT_URL } from "@rem/env";
var OvaFormComponent = /** @class */ (function () {
    function OvaFormComponent(router, activatedRoute) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.isWaiting = false;
        this.currentStep = 1;
        this.pathname = [location.pathname];
        this.currentRegion = CurrentRegion.getCurrent();
        this.validationMessages = [];
        this.unhandledError = null;
        this.invalidFieldsFromApi = [];
        this.prevCode = "";
        this.params = this.activatedRoute.snapshot.queryParams;
    }
    OvaFormComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var providerPromise, regionPromise, _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.inputs = initInputs();
                        this.divs = initDivs();
                        this.selects = initSelects();
                        this.fieldsets = initFieldsets();
                        this.sections = initSections();
                        this.initCitiesAndStreets();
                        this.loadFormState();
                        this.onHashChange();
                        window.addEventListener("hashchange", this.onHashChange.bind(this));
                        providerPromise = ProvidersApi.getAll();
                        regionPromise = RegionsApi.get(this.currentRegion);
                        _a = this;
                        return [4 /*yield*/, providerPromise];
                    case 1:
                        _a.providers = _c.sent();
                        _b = this;
                        return [4 /*yield*/, regionPromise];
                    case 2:
                        _b.region = _c.sent();
                        if (this.params.code) {
                            this.checkCode();
                        }
                        // Re-load form state once we know providers and region so the
                        // respective select boxes (which include them as dynamic options)
                        // will be selected properly.
                        setTimeout(function () {
                            _this.loadFormState();
                        }, 0);
                        return [2 /*return*/];
                }
            });
        });
    };
    OvaFormComponent.prototype.ngOnDestroy = function () {
        window.removeEventListener("hashchange", this.onHashChange);
    };
    OvaFormComponent.prototype.onHashChange = function () {
        var hash = location.hash;
        if (!hash) {
            // Default to step 1 if no step can be determined.
            return this.goToStep(1);
        }
        // Hash should look like `#step-1`. We're only interested
        // in the last character, as a number.
        var step = parseInt(location.hash.slice(-1));
        this.goToStep(step, true);
    };
    OvaFormComponent.prototype.initCitiesAndStreets = function () {
        this.cities = this.loadSessionJson("cities");
        if (this.cities) {
            this.selects.city.disabled = false;
        }
        this.city = this.loadSessionJson("city");
        if (this.city) {
            this.selects.city.disabled = false;
            this.selects.street.disabled = false;
        }
        this.alternativeCities = this.loadSessionJson("alternative_cities");
        if (this.alternativeCities) {
            this.selects.alternativeCity.disabled = false;
        }
        this.alternativeCity = this.loadSessionJson("alternative_city");
        if (this.alternativeCity) {
            this.selects.alternativeCity.disabled = false;
            this.selects.alternativeStreet.disabled = false;
        }
    };
    OvaFormComponent.prototype.loadSessionJson = function (key) {
        var item = sessionStorage.getItem("ova_" + key);
        return item ? JSON.parse(item) : null;
    };
    OvaFormComponent.prototype.saveSessionJson = function (key, item) {
        // This is not urgent work so queue for better responsiveness
        setTimeout(function () {
            sessionStorage.setItem("ova_" + key, JSON.stringify(item));
        }, 0);
    };
    /**
     * Requirements:
     * - Once a zipcode has been entered, the cities that belong to the specified zipcode should be suggested.
     * - Once a city has been entered, the streets that belong to the city should be suggested.
     * - The above points apply to the alternative billing address as well.
     */
    OvaFormComponent.prototype.onZipcodeChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var zipcode, cities;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        zipcode = this.selects.zipcode.value;
                        if (zipcode.length !== 5) {
                            // Don't hammer API with invalid zipcodes.
                            return [2 /*return*/];
                        }
                        if (!(zipcode !== "")) return [3 /*break*/, 2];
                        return [4 /*yield*/, CityApi.getAll(zipcode)];
                    case 1:
                        cities = _a.sent();
                        // Uncomment the next line to test multiple cities being returned.
                        // cities.push({ id: "fake", name: "Klein-Kuhkackerohde", zipCode: zipcode });
                        this.cities = cities;
                        this.saveSessionJson("cities", cities);
                        this.selects.city.disabled = false;
                        if (cities.length === 1) {
                            // Field will be filled automatically so no "city input" interaction
                            // will be triggered by user, so we have to trigger this manually here.
                            // Since this will need to trigger another round of change detection,
                            // set a timeout...
                            setTimeout(function () {
                                _this.onCityChange();
                                _this.validateSelect(_this.selects.city);
                            }, 0);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    OvaFormComponent.prototype.onCityChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cityName, selectedCity, city;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cityName = this.selects.city.value;
                        if (!(cityName !== "" && this.cities !== undefined)) return [3 /*break*/, 2];
                        selectedCity = this.cities.find(function (c) { return c.name === cityName; });
                        if (!(selectedCity !== undefined)) return [3 /*break*/, 2];
                        return [4 /*yield*/, CityApi.getOne(selectedCity.id)];
                    case 1:
                        city = _a.sent();
                        // Uncomment the next line to test no streets being returned:
                        // if (city.name === "Abensberg") city.streets = [];
                        this.city = city;
                        this.saveSessionJson("city", city);
                        if (city.streets.length === 0) {
                            this.divs.streetContainer.style.display = "none";
                            this.divs.streetFallbackContainer.style.display = "block";
                        }
                        else {
                            this.divs.streetContainer.style.display = "block";
                            this.divs.streetFallbackContainer.style.display = "none";
                            this.selects.street.disabled = false;
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    OvaFormComponent.prototype.onAlternativeZipcodeInput = function () {
        return __awaiter(this, void 0, void 0, function () {
            var zipcode, alternativeCities;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        zipcode = this.inputs.alternativeZipcode.value;
                        if (zipcode.length !== 5) {
                            // Don't hammer API with invalid zipcodes.
                            return [2 /*return*/];
                        }
                        if (!(zipcode !== "")) return [3 /*break*/, 2];
                        return [4 /*yield*/, CityApi.getAll(zipcode)];
                    case 1:
                        alternativeCities = _a.sent();
                        // Uncomment the next line to test multiple cities being returned.
                        // cities.push({ id: "fake", name: "Klein-Kuhkackerohde", zipCode: zipcode });
                        this.alternativeCities = alternativeCities;
                        this.saveSessionJson("alternative_cities", alternativeCities);
                        this.selects.alternativeCity.disabled = false;
                        if (alternativeCities.length === 1) {
                            // Field will be filled automatically so no "city input" interaction
                            // will be triggered by user, so we have to trigger this manually here.
                            // Since this will need to trigger another round of change detection,
                            // set a timeout...
                            setTimeout(function () {
                                _this.onAlternativeCityChange();
                                _this.validateSelect(_this.selects.alternativeCity);
                            }, 0);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    OvaFormComponent.prototype.onAlternativeCityChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cityName, selectedCity, alternativeCity;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cityName = this.selects.alternativeCity.value;
                        if (!(cityName !== "" && this.alternativeCities !== undefined)) return [3 /*break*/, 2];
                        selectedCity = this.alternativeCities.find(function (c) { return c.name === cityName; });
                        if (!(selectedCity !== undefined)) return [3 /*break*/, 2];
                        return [4 /*yield*/, CityApi.getOne(selectedCity.id)];
                    case 1:
                        alternativeCity = _a.sent();
                        // Uncomment the next line to test no streets being returned:
                        // if (alternativeCity.name === "Abensberg") alternativeCity.streets = [];
                        this.alternativeCity = alternativeCity;
                        this.saveSessionJson("alternative_city", alternativeCity);
                        if (alternativeCity.streets.length === 0) {
                            this.divs.alternativeStreetContainer.style.display = "none";
                            this.divs.alternativeStreetFallbackContainer.style.display = "block";
                        }
                        else {
                            this.divs.alternativeStreetContainer.style.display = "block";
                            this.divs.alternativeStreetFallbackContainer.style.display = "none";
                            this.selects.alternativeStreet.disabled = false;
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Requirements:
     * - The customer has recently moved or will move soon. If so, we ask for the relocation date.
     *   The relocation date is assumed to be the delivery date, and we do not need to ask about the
     *   cancellation status of his previous contract.
     * - If the customer has not moved recently or will not move soon, we need to know whether the contract
     *   has already been cancelled or whether we need to cancel for the customer.
     * - If the contract has already been cancelled, the cancellation date is assumed to be the delivery date.
     * - If we need to cancel for the customer, we ask the customer to provide a delivery date. The delivery
     *   date can be either "as soon as possible" or a specific date.
     * - In summary, we always have a delivery date. It can be in the form of the relocation date, the
     *   cancellation date, the desired delivery date, or "as soon as possible". (It can never be multiple
     *   of these at the same time.)
     */
    OvaFormComponent.prototype.onRelocationChange = function () {
        if (this.inputs.RECENTLY_RELOCATED.checked || this.inputs.WILL_RELOCATE.checked) {
            this.divs.relocationDateContainer.style.display = "block";
            this.divs.cancellationContainer.style.display = "none";
            this.sections.deliveryDateSection.style.display = "none";
            this.inputs.relocationDate.focus();
            this.validateInput(this.inputs.relocationDate);
        }
        else {
            this.divs.relocationDateContainer.style.display = "none";
            this.divs.cancellationContainer.style.display = "block";
            // Only show delivery date selection if not already cancelled.
            if (!this.inputs.ALREADY_CANCELLED.checked) {
                this.sections.deliveryDateSection.style.display = "block";
            }
        }
    };
    OvaFormComponent.prototype.onCancellationChange = function () {
        if (this.inputs.ALREADY_CANCELLED.checked) {
            this.divs.cancellationDateContainer.style.display = "block";
            this.sections.deliveryDateSection.style.display = "none";
            this.divs.cancellationDisclaimer.style.display = "none";
        }
        else {
            this.divs.cancellationDateContainer.style.display = "none";
            this.sections.deliveryDateSection.style.display = "block";
            this.divs.cancellationDisclaimer.style.display = "block";
        }
    };
    OvaFormComponent.prototype.onDeliveryChange = function () {
        this.divs.desiredDateContainer.style.display =
            this.inputs.DELIVERY_ASAP.checked ? "none" : "block";
        if (this.inputs.DELIVERY_AT_DATE.checked) {
            this.inputs.desiredDate.focus();
        }
    };
    /**
     * Requirements:
     * - An alternative billing address may be entered optionally.
     */
    OvaFormComponent.prototype.onAlternativeBillingAddressChange = function () {
        this.divs.alternativeBillingAddressContainer.style.display =
            this.inputs.alternativeBillingAddress.checked ? "block" : "none";
    };
    /**
     * Requirements:
     * - Customers may choose direct debit or bank transfer as a payment method.
     */
    OvaFormComponent.prototype.onPaymentChange = function () {
        if (this.selects.paymentType.value === "DIRECT_DEBIT") {
            this.divs.directDebitContainer.style.display = "block";
            this.divs.bankTransferContainer.style.display = "none";
        }
        else {
            this.divs.directDebitContainer.style.display = "none";
            this.divs.bankTransferContainer.style.display = "block";
        }
    };
    OvaFormComponent.prototype.checkCode = function () {
        return __awaiter(this, void 0, void 0, function () {
            var anchor, input, code, res, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        anchor = document.getElementById("gotostep2");
                        input = this.inputs.recruitFriends;
                        code = input.value.trim().toUpperCase();
                        if (this.prevCode === code) {
                            return [2 /*return*/];
                        }
                        this.prevCode = code;
                        input.disabled = true;
                        anchor.classList.add("disabled");
                        anchor.classList.add("loading");
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetch(API_ROOT_URL + "/api/ova/recommendation", {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({ code: code, tenantId: "lew" })
                            })];
                    case 2:
                        res = _b.sent();
                        if (!res.ok) {
                            input.dataset.apiValidationResult = "invalid";
                            input.parentElement.classList.remove("validation-success");
                        }
                        else {
                            input.dataset.apiValidationResult = "valid";
                            input.parentElement.classList.add("validation-success");
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        input.disabled = false;
                        anchor.classList.remove("disabled");
                        anchor.classList.remove("loading");
                        this.validateInput(input);
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Requirements:
     * - Just input validation... Inputs are validated once an input element has lost
     *   focus rather than while typing.
     */
    OvaFormComponent.prototype.onFormFocusOut = function (event) {
        var el = event.target;
        if (el instanceof HTMLInputElement) {
            // Radio buttons don't need to be validated - they always have a default selection.
            if (el.type !== "radio") {
                this.validateInput(el);
                this.saveFormState();
            }
            this.checkDirtyInput(el);
        }
    };
    OvaFormComponent.prototype.onFormChange = function (event) {
        var el = event.target;
        if (el instanceof HTMLInputElement) {
            if (el.type === "radio") {
                // Radio buttons don't need to be validated.
                this.saveFormState();
            }
            if (el.type === "checkbox") {
                // Checkboxes need to be validated
                this.validateInput(el);
                this.saveFormState();
            }
            this.checkDirtyInput(el);
        }
        else if (el instanceof HTMLSelectElement) {
            this.validateSelect(el);
            this.saveFormState();
        }
    };
    OvaFormComponent.prototype.checkDirtyInput = function (el) {
        if (el.value !== "") {
            el.classList.add("dirty");
        }
        else {
            el.classList.remove("dirty");
        }
    };
    OvaFormComponent.prototype.validateInput = function (input) {
        var isRequired = input.hasAttribute("required");
        var hasPattern = input.hasAttribute("pattern");
        var valid = true;
        var message = "";
        // If there is no `required` or `pattern` attribute, the input does not need
        // to be validated.
        //
        // :/
        if (!isRequired && !hasPattern && input.id !== "recruitFriends") {
            return { valid: valid };
        }
        if (isRequired) {
            if (input.type === "checkbox") {
                // Make sure checkbox is checked
                if (!input.checked) {
                    valid = false;
                    message = input.dataset.requiredMessage;
                }
            }
            else {
                // Make sure input contains text
                if (input.value === "") {
                    valid = false;
                    message = input.dataset.requiredMessage;
                }
            }
        }
        if (hasPattern) {
            // There are fields that are not required, but if they are filled, they need
            // to match a certain pattern (e.g. phone number). So only validate the pattern
            // if the field is not empty.
            if (input.value !== "") {
                var regex = new RegExp(input.getAttribute("pattern"));
                if (!regex.test(input.value)) {
                    valid = false;
                    message = input.dataset.patternMessage;
                }
            }
        }
        if (valid) {
            if (input.id === "dateOfBirth") {
                var dateOfBirth = new Date(this.sanitizeDate(input.value));
                var eighteenthBirthday = new Date(dateOfBirth.getFullYear() + 18, dateOfBirth.getMonth(), dateOfBirth.getDate());
                if (eighteenthBirthday > new Date()) {
                    valid = false;
                    message = input.dataset.customMessage;
                }
            }
            if (input.id === "email") {
                // There may be differences between FE and MW email validation.
                // For example, the FE regex allows "mark.tiedemann@eon.notcom" while
                // the MW checks that the TLD actually exists.
                if (this.invalidFieldsFromApi.includes("email")) {
                    valid = false;
                    message = input.dataset.patternMessage;
                }
            }
            if (input.id === "iban") {
                // There may be differences between FE and MW IBAN validation.
                // For example, iban.js considers "GB2LABBY09012857201707" a valid IBAN
                // which it is not - it has an invalid checksum structure.
                // See https://www.iban.com/testibans for test IBANs.
                if (!iban.isValid(input.value) || this.invalidFieldsFromApi.includes("iban")) {
                    valid = false;
                    message = input.dataset.customMessage;
                }
            }
            if (input.id === "relocationDate") {
                var relocationDate = new Date(this.sanitizeDate(input.value));
                var now = new Date();
                if (this.inputs.RECENTLY_RELOCATED.checked) {
                    var fourWeeksInMs = 1000 /*ms->s*/ * 60 /*s->m*/ * 60 /*m->h*/ * 24 /*h->d*/ * 7 /*d->w*/ * 4;
                    var fourWeeeksAgo = new Date().getTime() - fourWeeksInMs;
                    if (relocationDate.getTime() < fourWeeeksAgo || relocationDate > now) {
                        valid = false;
                        message = input.dataset.customMessageRecentlyRelocated;
                    }
                }
                if (this.inputs.WILL_RELOCATE.checked) {
                    if (relocationDate < now) {
                        valid = false;
                        message = input.dataset.customMessageWillRelocate;
                    }
                }
            }
            // :(
            if (input.id === "recruitFriends") {
                var invalid = input.dataset.apiValidationResult === "invalid";
                if (invalid) {
                    valid = false;
                    message = input.dataset.customMessage;
                }
                else {
                    if (input.parentElement.classList.contains("validation-success")) {
                        message = "Ihr Werbecode ist gültig!";
                    }
                }
            }
        }
        // TODO: Is order of validation error messages correct? Should more specific messages,
        // such as, messages regarding the pattern always overwrite the less specific messages,
        // such as, the "is required" messages?
        var messageContainer = // ...Oh dear.
         input.type === "checkbox" ? input.nextElementSibling.nextElementSibling : input.nextElementSibling;
        if (!valid) {
            input.parentElement.classList.add("validation-error");
            input.classList.remove("valid");
            input.classList.add("invalid");
            messageContainer.textContent = message;
            return { valid: valid, message: message };
        }
        else {
            input.parentElement.classList.remove("validation-error");
            input.classList.remove("invalid");
            input.classList.add("valid");
            messageContainer.textContent = message;
            return { valid: valid };
        }
    };
    OvaFormComponent.prototype.validateMinAge = function (input) {
        var now = new Date();
        var dateOfBirth = new Date(this.sanitizeDate(input.value));
        var eighteenthBirthday = new Date(dateOfBirth.getFullYear() + 18, dateOfBirth.getMonth(), dateOfBirth.getDate());
        return eighteenthBirthday <= now;
    };
    OvaFormComponent.prototype.validateSelect = function (select) {
        if (select.value === "") {
            var message = select.dataset.requiredMessage;
            select.parentElement.classList.add("validation-error");
            select.classList.remove("valid");
            select.classList.add("invalid");
            select.nextElementSibling.textContent = message;
            return { valid: false, message: message };
        }
        else {
            select.parentElement.classList.remove("validation-error");
            select.classList.remove("invalid");
            select.classList.add("valid");
            select.nextElementSibling.textContent = "";
            return { valid: true };
        }
    };
    /**
     * Requirements:
     * - As a developer, I don't want to see an empty page whenever I hit save in my editor..
     */
    OvaFormComponent.prototype.saveFormState = function () {
        var _this = this;
        // TODO: Replace `setTimeout` with `requestIdleCallback` once it has better browser support.
        setTimeout(function () {
            var state = {
                inputs: {},
                selects: {}
            };
            for (var _i = 0, _a = Object.entries(_this.inputs); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], input = _b[1];
                if (input.type === "radio" || input.type === "checkbox") {
                    state.inputs[key] = input.checked ? "checked" : "";
                }
                else {
                    state.inputs[key] = input.value;
                }
            }
            for (var _c = 0, _d = Object.entries(_this.selects); _c < _d.length; _c++) {
                var _e = _d[_c], key = _e[0], input = _e[1];
                state.selects[key] = input.value;
            }
            sessionStorage.setItem("ova_form_state", JSON.stringify(state));
        }, 0);
    };
    OvaFormComponent.prototype.loadFormState = function () {
        var item = sessionStorage.getItem("ova_form_state");
        if (!item) {
            return;
        }
        var state = JSON.parse(item);
        for (var _i = 0, _a = Object.entries(state.inputs); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            var el = this.inputs[key];
            if (el) {
                if (el.type === "radio" || el.type === "checkbox") {
                    el.checked = val === "checked";
                }
                else {
                    el.value = val;
                }
            }
        }
        for (var _c = 0, _d = Object.entries(state.selects); _c < _d.length; _c++) {
            var _e = _d[_c], key = _e[0], val = _e[1];
            var el = this.selects[key];
            if (el) {
                el.value = val;
                // If the form was filled-in with values from a previous session
                // such as after a reload, we'll trigger a change event on select boxes.
                el.dispatchEvent(new Event("change"));
            }
        }
        // Since loading the state affects whether certain sections of the form
        // should be visible or hidden, we need to manually trigger a re-computation
        // of which parts of the form are visible or hidden.
        this.onFormStateChange();
    };
    OvaFormComponent.prototype.onFormStateChange = function () {
        // These need to be called in opposite order of how they are defined
        // since relocation changes overwrite cancellation changes which in
        // turn overwrite delivery changes.
        this.onDeliveryChange();
        this.onCancellationChange();
        this.onRelocationChange();
        this.onAlternativeBillingAddressChange();
        this.onPaymentChange();
    };
    OvaFormComponent.prototype.goToStep2 = function () {
        var _this = this;
        (function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.checkCode()];
                    case 1:
                        _a.sent();
                        this.goToStep(2);
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    OvaFormComponent.prototype.goToStep = function (step, skipValidation) {
        if (skipValidation === void 0) { skipValidation = false; }
        if (!skipValidation) {
            if (!this.validateForm()) {
                // Reset router navigation
                this.router.navigate(this.pathname, { fragment: "step-" + this.currentStep, preserveQueryParams: true });
                return;
            }
        }
        this.currentStep = step;
        // For the step 3, both the 1st and the 2nd fieldset need to be visible
        // so customers can review their inputs.
        this.fieldsets["step-1"].style.display = step === 1 || step === 3 ? "block" : "none";
        this.fieldsets["step-2"].style.display = step === 2 || step === 3 ? "block" : "none";
        this.fieldsets["step-3"].style.display = step === 3 ? "block" : "none";
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: "smooth" });
            // Timeout of a few ms is necessary here so Angular has time to re-render the fieldsets.
            // Otherwise, when clicking "previous step", scrolling may not reach the top of the screen
            // as the top part of the form won't have been rendered yet.
        }, 300);
    };
    OvaFormComponent.prototype.validateForm = function () {
        var messages = [];
        var valid = true;
        for (var _i = 0, _a = Object.values(this.selects); _i < _a.length; _i++) {
            var select = _a[_i];
            // See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
            var isVisible = select.offsetParent !== null;
            if (isVisible) {
                var validation = this.validateSelect(select);
                if (!validation.valid) {
                    messages.push(validation.message);
                    valid = false;
                }
            }
        }
        for (var _b = 0, _c = Object.values(this.inputs); _b < _c.length; _b++) {
            var input = _c[_b];
            var isVisible = input.offsetParent !== null;
            if (isVisible) {
                var validation = this.validateInput(input);
                if (!validation.valid) {
                    messages.push(validation.message);
                    valid = false;
                }
            }
        }
        this.validationMessages = messages;
        return valid;
    };
    OvaFormComponent.prototype.sanitizeDate = function (value) {
        var isGermanDateFormat = value.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/);
        if (isGermanDateFormat) {
            var _a = value.split("."), day = _a[0], month = _a[1], year = _a[2];
            return year + "-" + month.padStart(2, "0") + "-" + day.padStart(2, "0");
        }
        else {
            return value;
        }
    };
    OvaFormComponent.prototype.onOrderSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var payment, startingType, hasUnknownAddress, hasUnknownAlternativeAddress, alternativeBillingAddress, previousProviderBdewId, recommendationCode, order, res;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.validateForm()) {
                            return [2 /*return*/];
                        }
                        this.isWaiting = true;
                        payment = function () {
                            var value = _this.selects.paymentType.value;
                            // prettier-ignore
                            switch (value) {
                                case "BANK_TRANSFER": return null; // `null` represents "bank transfer" in the API
                                case "DIRECT_DEBIT": return {
                                    bankAccount: {
                                        iban: _this.inputs.iban.value,
                                        owner: _this.inputs.accountOwner.value
                                    }
                                };
                                default: throw new Error("Unknown paymentType '" + value + "'");
                            }
                        };
                        startingType = function () {
                            var RELOCATION_AT = _this.inputs.WILL_RELOCATE.checked || _this.inputs.RECENTLY_RELOCATED.checked;
                            if (RELOCATION_AT)
                                return {
                                    startingType: "RELOCATION_AT",
                                    date: _this.sanitizeDate(_this.inputs.relocationDate.value),
                                };
                            var TERMINATED_IN_PERSON = _this.inputs.ALREADY_CANCELLED.checked;
                            if (TERMINATED_IN_PERSON)
                                return {
                                    startingType: "TERMINATED_IN_PERSON",
                                    date: _this.sanitizeDate(_this.inputs.cancellationDate.value)
                                };
                            var DESIRED_DATE = _this.inputs.DELIVERY_AT_DATE.checked;
                            if (DESIRED_DATE)
                                return {
                                    startingType: "DESIRED_DATE",
                                    date: _this.sanitizeDate(_this.inputs.desiredDate.value)
                                };
                            // Be nice to the customer: Default to EARLIEST_POSSIBLE_DATE
                            // rather than throwing an error if no case matches.
                            return {
                                startingType: "EARLIEST_POSSIBLE_DATE"
                            };
                        };
                        hasUnknownAddress = this.city.streets.length === 0;
                        hasUnknownAlternativeAddress = this.alternativeCity ? this.alternativeCity.streets.length === 0 : false;
                        alternativeBillingAddress = function () {
                            if (!_this.inputs.alternativeBillingAddress.checked) {
                                return null;
                            }
                            return {
                                city: _this.selects.alternativeCity.value,
                                contact: {
                                    companyName: _this.inputs.alternativeCompanyName.value === "" ? null : _this.inputs.alternativeCompanyName.value,
                                    firstname: _this.inputs.alternativeFirstname.value,
                                    lastname: _this.inputs.alternativeLastname.value,
                                    salutation: _this.selects.alternativeSalutation.value
                                },
                                houseNumber: _this.inputs.alternativeHouseNumber.value,
                                street: hasUnknownAlternativeAddress
                                    ? _this.inputs.alternativeStreetFallback.value
                                    : _this.selects.alternativeStreet.value,
                                zipcode: _this.inputs.alternativeZipcode.value
                            };
                        };
                        previousProviderBdewId = function () {
                            var value = _this.selects.provider.value;
                            var provider = _this.providers.find(function (p) { return p.name === value; });
                            return provider.bdewId;
                        };
                        recommendationCode = function () {
                            var code = _this.inputs.recruitFriends.value.trim().toUpperCase();
                            if (code === "")
                                return null;
                            else
                                return code;
                        };
                        order = {
                            address: {
                                city: this.selects.city.value,
                                houseNumber: this.inputs.houseNumber.value,
                                street: hasUnknownAddress ? this.inputs.streetFallback.value : this.selects.street.value,
                                zipcode: this.selects.zipcode.value
                            },
                            adsMail: this.inputs.adsMail.checked,
                            adsPhone: this.inputs.adsPhone.checked,
                            adsPost: this.inputs.adsPost.checked,
                            consumption: parseInt(this.params.consumption),
                            contact: {
                                dateOfBirth: this.sanitizeDate(this.inputs.dateOfBirth.value),
                                companyName: this.inputs.companyName.value === "" ? null : this.inputs.companyName.value,
                                email: this.inputs.email.value,
                                firstname: this.inputs.firstname.value,
                                lastname: this.inputs.lastname.value,
                                phone: this.inputs.phone.value,
                                salutation: this.selects.salutation.value
                            },
                            deviceNumber: this.inputs.deviceNumber.value,
                            payment: payment(),
                            productCode: this.params.product,
                            regionId: this.region.id,
                            startingType: startingType(),
                            unknownAddress: hasUnknownAddress || hasUnknownAlternativeAddress,
                            alternativeBillingAddress: alternativeBillingAddress(),
                            previousProviderBdewId: previousProviderBdewId(),
                            referralBillboards: this.inputs.referralBillboards.checked,
                            referralFriends: this.inputs.referralFriends.checked,
                            referralInternet: this.inputs.referralInternet.checked,
                            referralNewspaper: this.inputs.referralNewspaper.checked,
                            referralOthers: this.inputs.referralOthers.checked,
                            referralOthersText: this.inputs.referralOthersText.value,
                            recommendationCode: recommendationCode(),
                            tosAccepted: this.inputs.termsAndConditions.checked,
                        };
                        return [4 /*yield*/, OrderApi.post(order)];
                    case 1:
                        res = _a.sent();
                        this.isWaiting = false;
                        switch (res) {
                            case "OK":
                                this.router.navigate(this.pathname, { fragment: "erfolgreichem-abschluss" });
                                return [2 /*return*/];
                            case "ACCEPTED":
                                this.router.navigate(this.pathname, { fragment: "erfolgreichem-abschluss-no-confirmation" });
                                return [2 /*return*/];
                            case "UNKNOWN_ERROR":
                                this.unhandledError = {
                                    title: "Es tut uns Leid, da ist etwas schief gelaufen.",
                                    description: this.formatErrorDescription()
                                };
                                return [2 /*return*/];
                            case "ORDER_CUSTOMER_EXISTING_NOT_IDENTICAL":
                                this.unhandledError = {
                                    title: "Es existiert bereits ein Kundenkonto zur angegebenen E-Mail-Adresse.",
                                    description: this.formatErrorDescriptionForSecondContract()
                                };
                                return [2 /*return*/];
                            default:
                                this.invalidFieldsFromApi = res.invalidFields;
                                this.validateForm();
                                this.invalidFieldsFromApi = [];
                                return [2 /*return*/];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    OvaFormComponent.prototype.formatErrorDescriptionForSecondContract = function () {
        var _a = CustomerService(this.currentRegion), email = _a.email, isOpen = _a.isOpen, supportNumber = _a.supportNumber;
        var phoneAvailability = isOpen()
            ? /*html*/ "unter <a href=\"tel:" + supportNumber.value + "\">" + supportNumber.displayValue + "</a> oder "
            : "";
        return /*html*/ "<p>Bitte pr\u00FCfen Sie nochmals Ihre Daten. Bei weiteren Vertr\u00E4gen m\u00FCssen Name und Geburtsdatum identisch sein.</p>\n<p>Bei weiteren Fragen hilft Ihnen unser Kundenservice sehr gerne weiter.</p>\n<p>Sie erreichen uns " + phoneAvailability + "per Mail (<a href=\"mailto:" + email + "\">" + email + "</a>).</p>";
    };
    OvaFormComponent.prototype.formatErrorDescription = function () {
        var _a = CustomerService(this.currentRegion), email = _a.email, isOpen = _a.isOpen, supportNumber = _a.supportNumber;
        var phoneAvailability = isOpen()
            ? /*html*/ "unter <a href=\"tel:" + supportNumber.value + "\">" + supportNumber.displayValue + "</a> oder "
            : "";
        return /*html*/ "<p>Bitte pr\u00FCfen Sie nochmals Ihre Daten.</p>\n<p>Bei weiteren Fragen hilft Ihnen unser Kundenservice sehr gerne weiter.</p>\n<p>Sie erreichen uns " + phoneAvailability + "per Mail (<a href=\"mailto:" + email + "\">" + email + "</a>).</p>";
    };
    OvaFormComponent.prototype.preisUndLieferbedingungen = function () {
        // if (this.currentRegion === "abensberg") {
        //   if (this.params.product === "033" /*FLEX*/)
        //	   return "./assets/editorial/abensberg/media/downloads/Ihr_Auftrag_Naturstrom_FLEX.pdf";
        //	 if (this.params.product === "028" /*FIX*/)
        //     return "./assets/editorial/abensberg/media/downloads/Ihr_Auftrag_Naturstrom_FIX.pdf";
        // }
        return "./assets/editorial/" + this.currentRegion + "/media/downloads/Preis-und-Lieferbedingungen.pdf";
    };
    return OvaFormComponent;
}());
export { OvaFormComponent };
